.page-videos {
  position: relative;
  background-color: #100f0f;
  overflow: hidden;
  border-bottom: 2px solid #212121;
}
.page-videos-img-bg {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 1800px;
  min-width: 1000px;
  width: 100%;
  z-index: 10;
}
.page-videos-img-bg img {
  display: block;
  width: 100%;
}
.page-videos-title-wrapper {

}
.page-videos-title {
  position: relative;
  margin-bottom: 50px;
  padding: 12px 15px;
  font-size: 25px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
  z-index: 20;
}
.page-videos-items {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 1640px;
  margin: 0 auto;
  padding: 20px;
  z-index: 20;
}
.page-videos-item {
  box-sizing: border-box;
  width: ~"calc((100% - 60px) / 3)";
  margin-right: 30px;
  margin-bottom: 15px;
}

@media (min-width: 1200px) {
  .page-videos-item:nth-child(3n) {
    margin-right: 0;
  }
}
.page-videos-item-video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.2%;
}
.page-videos-item-video iframe {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.page-videos-item-descr {
  padding: 7px 15px;
  background-color: #2b2a2a;
}
.page-videos-item-descr-title {
  margin-bottom: 10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
}
.page-videos-item-descr-text {
  margin-bottom: 10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: justify;
}
.page-videos-item-descr-footer {
  display: flex;
  align-items: center;
}
.page-videos-item-descr-footer-date {
  display: flex;
  align-items: center;
  margin-right: auto;
  padding-right: 20px;
}
.page-videos-item-descr-footer-date-icon {
  width: 10px;
  height: 11px;
  margin-right: 7px;
}
.page-videos-item-descr-footer-date-icon img {
  display: block;
  width: 100%;
}
.page-videos-item-descr-footer-date-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
}
.page-videos-item-descr-footer-btn-wrapper {

}
.page-videos-item-descr-footer-btn {
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  transition: color .5s;
  &:hover {
    color: #dc291e;
    text-decoration: none;
  }
  &:focus {
    color: #f4f3f3;
    text-decoration: none;
  }
}



@media @large-widths {
  .page-videos-item-descr-title {
    font-size: 15px;
  }
  .page-videos-item-descr-text {
    font-size: 13px;
  }
}



@media @medium-widths {
  .page-videos-title {
    margin-bottom: 30px;
    font-size: 22px;
  }
  .page-videos-items {
    max-width: 1050px;
  }
  .page-videos-item {
    width: ~"calc((100% - 30px) / 2)";
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .page-videos-item:nth-child(2n) {
    margin-right: 0;
  }
}




@media @small-widths {
  .page-videos-item-descr-title {
    font-size: 14px;
  }
  .page-videos-item-descr-text {
    font-size: 12px;
  }
}



@media @extraSmall-widths {
  .page-videos-items {
    max-width: 525px;
  }
  .page-videos-item {
    width: 100%;
    margin-right: 0;
  }
}



@media @exxSmall-widths {
  .page-videos-title {
    font-size: 20px;
  }
}
