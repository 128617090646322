.about-services-wrapper {
  padding-top: 30px;
  padding-bottom: 50px;
  background-color: #100f0f;
}
.about-services {
  display: flex;
  align-items: flex-start;
}
.about-services-block {
  box-sizing: border-box;
  padding: 20px 15px;
  padding-left: 45px;
}
.about-services-main {
  width: 60%;
  padding-right: 60px;
}
.about-services-additional {
  width: 40%;
  background-color: #2b2a2a;
}
.about-services-block-title {
  margin-bottom: 15px;
}
.about-services-block-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.about-services-block-item {
  position: relative;
  margin-bottom: 12px;
  text-align: justify;
  &::before {
    content: "";
    position: absolute;
    top: 6px;
    left: -28px;
    width: 8px;
    height: 8px;
    background-color: #ed1c24;
    border-radius: 50%;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.about-services-block-item-name {
  font-size: 16px;
  font-weight: bold;
  color: @color-text-hover;
  text-transform: uppercase;
}
.about-services-block-item-warning {
  margin-top: 5px;
  font-weight: bold;
  color: @color-text-hover;
  text-transform: uppercase;
}



@media @large-widths {
  .about-services-main {
    padding-right: 30px;
  }
  .about-services-block-item-name {
    font-size: 15px;
  }
}



@media @medium-widths {

}



@media @small-widths {
  .about-services {
    display: block;
  }
  .about-services-block {
    padding: 20px 15px;
    padding-left: 45px;
  }
  .about-services-main {
    width: 100%;
  }
  .about-services-additional {
    width: 100%;
  }
}



@media @extraSmall-widths {

}



@media @exxSmall-widths {
  .about-services-block {
    padding-left: 35px;
  }
  .about-services-block-item {
    &::before {
      top: 5px;
      left: -20px;
    }
  }
  .about-services-block-item-name {
    font-size: 14px;
  }
}
