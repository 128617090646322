.about-promo-wrapper {
  background-image: url("../img/about/about-promo-bg.jpg");
  background-position: center;
  background-size: cover;
}
.about-promo {
  padding-top: 20px;
  padding-bottom: 40px;
}
.about-title {
  margin-bottom: 30px;
  padding: 12px 15px;
  font-size: 25px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
}
.about-promo-content {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 20px;
}
.about-promo-img {
  flex-shrink: 0;
  width: 416px;
}
.about-promo-img img {
  display: block;
  width: 100%;
}
.about-promo-descr {
  flex-grow: 1;
}
.about-promo-descr-title {
  margin-bottom: 10px;
  font-family: "Stadium 1946", sans-serif;
  font-size: 35px;
  font-weight: normal;
  color: #dc291e;
  text-transform: uppercase;
}
.about-promo-descr-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: justify;
}



@media @medium-widths {
  .about-title {
    margin-bottom: 30px;
    font-size: 22px;
  }
  .about-promo-img {
    width: 380px;
  }
  .about-promo-descr-title {
    font-size: 30px;
  }
  .about-promo-descr-text {
    font-size: 15px;
  }
}



@media @small-widths {
  .about-promo-img {
    width: 300px;
  }
  .about-promo-descr-title {
    font-size: 28px;
  }
  .about-promo-descr-text {
    font-size: 14px;
  }
}



@media @extraSmall-widths {
  .about-promo-content {
    flex-direction: column-reverse;
  }
  .about-promo-img {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }
  .about-promo-descr {
    width: 100%;
    max-width: 550px;
    margin: 0 auto;
  }
}



@media @exxSmall-widths {
  .about-title {
    font-size: 20px;
  }
  .about-promo-descr-title {
    font-size: 24px;
  }
  .about-promo-descr-text {
    font-size: 13px;
  }
}
