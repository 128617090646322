body {
  // backface-visibility: hidden;
  //background-color: #2b2a2a;
  // font-family: "Stadium 1946", sans-serif;
  // font-family: 'Open Sans', sans-serif;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 1.3;
}
body * {
  backface-visibility: hidden;
}
