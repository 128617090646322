.arcades-promo-wrapper {
  background-image: url("../img/arcades/arcades-promo-img-bg.jpg");
  background-position: center;
  background-size: cover;
}
.arcades-promo {
  max-width: 1640px;
  margin: 0 auto;
  padding: 0 15px;
  padding-top: 20px;
}
.arcades-promo-title {
  font-size: 25px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
}
.arcades-promo-content {
  display: flex;
  align-items: center;
}
.arcades-promo-content-img {
  align-self: flex-end;
  width: 230px;
}
.arcades-promo-content-img img {
  display: block;
  width: 100%;
}
.arcades-promo-content-items-wrapper {
  box-sizing: border-box;
  width: ~"calc(100% - 460px)";
  padding: 20px 40px;
}
.arcades-promo-content-items {

}
.arcades-promo-content-item {
  padding: 0 5px;
}
.arcades-promo-content-item-img {
  position: relative;
  display: block;
}
.arcades-promo-content-item-img img {
  display: block;
  width: 100%;
}
.arcades-promo-content-item-hover {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  background-color: rgba(220, 41, 30, .9);
  opacity: 0;
  transition: opacity .5s;
}
.arcades-promo-content-item:hover .arcades-promo-content-item-hover {
  opacity: 1;
}
.arcades-promo-content-item-hover-title {
  margin-top: auto;
  margin-bottom: auto;
  padding: 5px 15px;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
}
.arcades-promo-content-item-hover-descr {
  padding: 5px 15px;
  border-top: 2px solid #100f0f;
  border-bottom: 2px solid #100f0f;
  font-size: 10px;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
  color: #100f0f;
}
.arcades-promo-content-items .owl-nav .owl-prev {
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translate(0, -50%);
  width: 24px;
  height: 32px;
  font-size: 0;
  cursor: pointer;
  z-index: 100;
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 7px;
    top: 15px;
    width: 10px;
    height: 2px;
    transform-origin: 1px 50%;
    background-color: #ffffff;
  }
  &::after {
    transform: rotate(50deg);
  }
  &::before {
    transform: rotate(-50deg);
  }
}
.arcades-promo-content-items .owl-nav .owl-next {
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translate(0px, -50%);
  width: 24px;
  height: 32px;
  font-size: 0;
  cursor: pointer;
  z-index: 100;
  &::before,
  &::after {
    content: "";
    position: absolute;
    right: 7px;
    top: 15px;
    width: 10px;
    height: 2px;
    transform-origin: 9px 50%;
    background-color: #ffffff;
  }
  &::before {
    transform: rotate(50deg);
  }
  &::after {
    transform: rotate(-50deg);
  }
}
.arcades-promo-content-info {
  width: 230px;
  font-family: "Stadium 1946", sans-serif;
  font-size: 46px;
  font-weight: normal;
  line-height: .9;
  color: #dc291e;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 4px 6px 0 #000000;
}
.arcades-promo-content-info span {
  font-size: 32px;
  color: #f4f3f3;
}



@media @large-widths {
  .arcades-promo-content-img {
    width: 200px;
  }
  .arcades-promo-content-items-wrapper {
    width: ~"calc(100% - 400px)";
  }
  .arcades-promo-content-info {
    width: 200px;
    font-size: 40px;
  }
  .arcades-promo-content-info span {
    font-size: 28px;
  }
}



@media @medium-widths {
  .arcades-promo-title {
    font-size: 24px;
  }
  .arcades-promo-content-img {
    width: 180px;
  }
  .arcades-promo-content-items-wrapper {
    width: ~"calc(100% - 360px)";
  }
  .arcades-promo-content-info {
    width: 180px;
    font-size: 36px;
  }
  .arcades-promo-content-info span {
    font-size: 26px;
  }
}



@media @small-widths {
  .arcades-promo-title {
    font-size: 22px;
  }
  .arcades-promo-content-img {
    width: 150px;
  }
  .arcades-promo-content-items-wrapper {
    width: ~"calc(100% - 300px)";
  }
  .arcades-promo-content-info {
    width: 150px;
    font-size: 32px;
  }
  .arcades-promo-content-info span {
    font-size: 24px;
  }
}



@media @extraSmall-widths {
  .arcades-promo-title {
    font-size: 20px;
  }
  .arcades-promo-content {
    flex-wrap: wrap;
    justify-content: center;
  }
  .arcades-promo-content-img {
    width: 150px;
    margin-right: 30px;
  }
  .arcades-promo-content-items-wrapper {
    width: 100%;
    padding: 20px 30px;
    order: -1;
  }
  .arcades-promo-content-info {
    width: 150px;
    font-size: 32px;
  }
  .arcades-promo-content-info span {
    font-size: 24px;
  }
}



@media @exxSmall-widths {

}



@media (max-width: 400px) {
  .arcades-promo-content-info {
    width: 100%;
    margin-bottom: 20px;
  }
  .arcades-promo-content-img {
    margin-right: 0;
    order: 10;
  }
}
