.product-promo-descr-info-price-points-descr {
  font-size: 20px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
}
.product-promo-descr-info-price-points-num {
  margin-bottom: 50px;
  font-size: 25px;
  font-weight: bold;
  color: #ed1c24;
  text-transform: uppercase;
  text-align: center;
}
.trove-product-promo-descr .product-promo-descr-info-price-btn-add-to-card-icon {
  display: block;
  width: 35px;
}



@media @large-widths {
  .product-promo-descr-info-price-points-descr {
    font-size: 18px;
  }
  .product-promo-descr-info-price-points-num {
    font-size: 22px;
  }
}



@media @medium-widths {
  .product-promo-descr-info-price-points-descr {
    font-size: 16px;
  }
  .product-promo-descr-info-price-points-num {
    font-size: 20px;
  }
}



@media @exxSmall-widths {
  .product-promo-descr-info-price-points-descr {
    font-size: 14px;
  }
  .product-promo-descr-info-price-points-num {
    font-size: 16px;
  }
}
