.btn {
  box-sizing: border-box;
  display: inline-block;
  min-width: 165px;
  padding: 9px 15px;
  background-color: transparent;
  border: 2px solid #dc291e;
  border-radius: 0;
  font-size: 10px;
  line-height: 1.3;
  font-weight: normal;
  color: #f4f3f3;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  transition: background-color .5s, border .5s, color .5s;
  &:hover {
    background-color: #dc291e;
    color: #f4f3f3;
    text-decoration: none;
  }
  &:focus {
    color: #f4f3f3;
    outline: none;
    text-decoration: none;
  }
}
