.arcade-wrapper {

}
.arcade {
  padding-top: 105px;
  padding-bottom: 40px;
  background-image: url("../img/arcade-bg.jpg");
  background-position: center;
  background-size: cover;
}
.arcade-title {
  .block-title();
  margin-bottom: 40px;
}
.arcade-filter-items {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
.arcade-filter-item {
  display: flex;
  align-items: center;
  margin-right: 45px;
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  cursor: pointer;
  transition: color .5s;
  &:hover {
    color: #dc291e;
  }
}
.arcade-filter-item:last-child {
  margin-right: 0;
}
.arcade-filter-item-icon {
  width: 30px;
  height: 30px;
  margin-right: 3px;
}
.arcade-filter-item-icon svg {
  display: block;
  width: 100%;
  height: 100%;
  fill: #ffffff;
  transition: fill .5s;
}
.arcade-filter-item:hover svg {
  fill: #dc291e;
}
.arcade-items {
  display: flex;
  flex-wrap: wrap;
}
.arcade-item {
  display: flex;
  flex-direction: column;
  width: ~"calc((100% - 150px) / 7)";
  margin-right: 25px;
  margin-bottom: 18px;
}
@media (min-width: 1750px) {
  .arcade-item:nth-child(7n) {
    margin-right: 0;
  }
}
.arcade-item-header {
  display: flex;
  align-items: center;
  padding: 2px 12px;
  background-color: rgba(220, 41, 30, .65);
  transition: background-color .5s;
}
.arcade-item:hover .arcade-item-header {
  background-color: rgba(220, 41, 30, 1);
}
.arcade-item-header-points {
  margin-right: auto;
  padding-right: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
}
.arcade-item-header-player {

}
.arcade-item-header-player-icon {
  width: 30px;
  height: 30px;
}
.arcade-item-header-player-icon svg {
  display: block;
  width: 100%;
  height: 100%;
  fill: #ffffff;
}
.arcade-item-img-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 78%;
}
.arcade-item-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-size: cover;
}
.arcade-item-img-btn-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 18px;
  background-color: rgba(220, 41, 30, .8);
  font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  color: #f4f3f3;
  text-transform: uppercase;
  opacity: 0;
  transition: opacity .5s ;
}
.arcade-item:hover .arcade-item-img-btn-wrapper {
  opacity: 1;
}
.arcade-item-img-btn {
  .btn();
  width: 100%;
  min-width: 0;
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}
.arcade-item-name {
  flex-grow: 1;
  padding: 10px 20px;
  background-color: #100f0f;
  font-size: 12px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
}
.arcade-promo {
  // flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 70px 0;
  background-image: url("../img/arcade-promo-bg.jpg");
  background-position: center;
  background-size: cover;
}
.arcade-promo-inner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.arcade-promo-phrase {
  margin-right: 50px;
  font-size: 48px;
  font-weight: bold;
  color: #f4f3f3;
  text-align: center;
  text-shadow: 5px 4px 0 #000000;
}
.arcade-promo-btn {
  .btn();
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}



@media @xxlarge-widths {
  .arcade-item {
    width: ~"calc((100% - 125px) / 6)";
    margin-right: 25px;
  }
}
@media (min-width: 1320px) and (max-width: 1749px) {
  .arcade-item:nth-child(6n) {
    margin-right: 0;
  }
}



@media @large-widths {
  .arcade-item {
    width: ~"calc((100% - 100px) / 5)";
    margin-right: 25px;
  }
}
@media (min-width: 1200px) and (max-width: 1319px) {
  .arcade-item:nth-child(5n) {
    margin-right: 0;
  }
}



@media @medium-widths {
  .arcade-item {
    width: ~"calc((100% - 75px) / 4)";
    margin-right: 25px;
  }
  .arcade-promo-phrase {
    margin-right: 40px;
    font-size: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .arcade-item:nth-child(4n) {
    margin-right: 0;
  }
}



@media @small-widths {
  .arcade-item {
    width: ~"calc((100% - 50px) / 3)";
    margin-right: 25px;
  }
  .arcade-promo-phrase {
    font-size: 30px;
  }
}
@media (min-width: 521px) and (max-width: 991px) {
  .arcade-item:nth-child(3n) {
    margin-right: 0;
  }
}



@media @extraSmall-widths {
  .arcade-item-header-points {
    font-size: 14px;
  }
}



@media (max-width: 520px) {
  .arcade-items {
    max-width: 560px;
    margin: 0 auto;
  }
  .arcade-item {
    width: ~"calc((100% - 25px) / 2)";
    margin-right: 25px;
  }
  .arcade-item:nth-child(2n) {
    margin-right: 0;
  }
  .arcade-promo {
    padding: 30px 0;
  }
  .arcade-promo-inner-wrapper {
    display: block;
    text-align: center;
  }
  .arcade-promo-phrase {
    margin-right: 0;
    margin-bottom: 20px;
    font-size: 24px;
  }
}



@media @exxSmall-widths {
  .arcade-item {
    width: ~"calc((100% - 10px) / 2)";
    margin-right: 10px;
  }
}
