.reviews {

}
.reviews-leave {
  margin-bottom: 45px;
  padding-bottom: 25px;
}
.reviews-leave-title {
  padding-left: 130px;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: normal;
  color: #ed1c24;
  text-transform: uppercase;
}
.reviews-leave-block {
  display: flex;
}
.reviews-leave-img {
  flex-shrink: 0;
  width: 110px;
  margin-right: 20px;
}
.reviews-leave-img img {
  display: block;
  width: 100%;
}
.reviews-leave-form {
  flex-grow: 1;
}
.reviews-leave-form-login {
  margin-bottom: 7px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #f4f3f3;
}
.reviews-leave-form-textarea {
  margin-bottom: 10px;
}
.reviews-leave-form-textarea textarea {
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 86px;
  padding: 5px 10px;
  background-color: #5f5f5f;
  border: 1px solid #f4f3f3;
  font-size: 14px;
  font-weight: normal;
  color: #f4f3f3;
  resize: none;
  &:focus {
    outline: none;
  }
}
.reviews-leave-form-textarea textarea::-webkit-input-placeholder {
  color: #f4f3f3;
  text-transform: uppercase;
}
.reviews-leave-form-textarea textarea::-moz-placeholder {
  color: #f4f3f3;
  text-transform: uppercase;
}
.reviews-leave-form-textarea textarea:-moz-placeholder {
  color: #f4f3f3;
  text-transform: uppercase;
}
.reviews-leave-form-textarea textarea:-ms-input-placeholder {
  color: #f4f3f3;
  text-transform: uppercase;
}
.reviews-leave-form-footer {
  display: flex;
  align-items: center;
}
.reviews-leave-form-footer-rating {
  display: flex;
  align-items: center;
  padding-right: 20px;
}
.reviews-leave-form-footer-rating-descr {
  margin-right: 20px;
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.reviews-leave-form-footer-rating-items {
  display: flex;
  align-items: center;
}
.reviews-leave-form-footer-rating-item {
  width: 30px;
  margin-right: 12px;
  cursor: pointer;
}
.reviews-leave-form-footer-rating-item:last-child {
  margin-right: 0;
}
.reviews-leave-form-footer-rating-item svg {
  display: block;
  width: 100%;
  height: 30px;
  fill: #151414;
  transition: fill .5s;
}
.reviews-leave-form-footer-rating-item--active svg {
  fill: #f9e46e;
}
.reviews-leave-form-footer-btn-wrapper {
  margin-left: auto;
}
.reviews-leave-form-footer-btn-wrapper input {
  .btn();
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}
.reviews-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.reviews-items.child {
  position: relative;
  padding-left: 170px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 135px;
    width: 20px;
    height: 20px;
    background-image: url("../img/giveaways/array-turn.png");
  }
}
.reviews-item {

}
.reviews-item-inner-wrapper {
  display: flex;
  margin-bottom: 30px;
}
.reviews-item:last-child {
  margin-bottom: 0;
}
.reviews-item-img {
  flex-shrink: 0;
  width: 110px;
  margin-right: 20px;
}
.reviews-item-img img {
  display: block;
  width: 100%;
}
.reviews-item-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.reviews-item-content-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.reviews-item-content-header-name {
  margin-right: auto;
  padding-right: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #f4f3f3;
}
.reviews-item-content-header-rating {
}
.reviews-item-content-header-rating-items {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.reviews-item-content-header-rating-item {
  margin-right: 12px;
}
.reviews-item-content-header-rating-item:last-child {
  margin-right: 0;
}
.reviews-item-content-header-rating-item svg {
  display: block;
  width: 17px;
  height: 17px;
  fill: #151414;
}
.reviews-item-content-header-rating-item--active svg {
  fill: #f9e46e;
}
.reviews-item-content-header-date {
  font-size: 12px;
  font-weight: normal;
  color: #ed1c24;
  text-transform: uppercase;
}
.reviews-item-content-text {
  font-size: 14px;
  font-weight: normal;
  color: #f4f3f3;
}
.reviews-item-content-footer {
  margin-top: auto;
  padding-top: 10px;
}
.reviews-item-content-footer-reply {
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}



@media @large-widths {
  .reviews-item-content-text {
    font-size: 13px;
  }
}



@media @medium-widths {
  .reviews-item-img {
    width: 80px;
  }
  .reviews-items.child {
    padding-left: 140px;
    &::before {
      left: 105px;
    }
  }
}



@media @small-widths {
  .reviews-item-content-text {
    font-size: 12px;
  }
}



@media @extraSmall-widths {
  .reviews-leave-title {
    padding-left: 0;
  }
  .reviews-leave-block {
    display: block;
  }
  .reviews-leave-img {
    margin-bottom: 10px;
  }
  .reviews {
    padding: 0 10px;
  }
  .reviews-item-inner-wrapper {
    display: block;
  }
  .reviews-items.child {
    padding-left: 40px;
    &::before {
      left: 10px;
    }
  }
  .reviews-item-img {
    margin-bottom: 10px;
  }
}



@media (max-width: 599px) {
  .reviews {
    padding: 0;
  }
  .reviews-leave-form-footer {
    display: block;
  }
  .reviews-leave-form-footer-rating {
    margin-bottom: 10px;
  }
  .reviews-item-content-header {
    display: block;
  }
  .reviews-item-content-header-name {
    margin-bottom: 10px;
  }
  .reviews-item-content-header-rating {
    margin-bottom: 10px;
  }
}
