.giveaways-single-sponsored-wrapper {

}
.giveaways-single-sponsored {
  padding : 45px 0;
}
.giveaways-single-sponsored-title {
  margin-bottom: 10px;
  font-family: "Stadium 1946", sans-serif;
  font-size: 45px;
  font-weight: normal;
  color: #dc291e;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 4px 6px 0 #000000;
}
.giveaways-single-sponsored-icon {
  width: 56px;
  margin: 0 auto;
  margin-bottom: 15px;
}
.giveaways-single-sponsored-icon img {
  display: block;
  width: 100%;
}
.giveaways-single-sponsored-descr {
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
}
.giveaways-single-sponsored-content {
  display: flex;
}
.giveaways-single-sponsored-content-slider-wrapper {
  width: ~"calc(100% - 625px)";
}
.giveaways-single-sponsored-content-slider {

}
.giveaways-single-sponsored-content-slide {

}
.giveaways-single-sponsored-content-slide-img-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 59%;
}
.giveaways-single-sponsored-content-slide-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-size: cover;
}
.giveaways-single-sponsored-content-descr {
  box-sizing: border-box;
  width: 625px;
  padding-left: 50px;
}
.giveaways-single-sponsored-content-descr-title {
  margin-bottom: 15px;
  font-size: 30px;
  font-weight: bold;
  color: #dc291e;
  text-align: center;
  text-transform: uppercase;
}
.giveaways-single-sponsored-content-descr-block {
  margin-bottom: 20px;
}
.giveaways-single-sponsored-content-descr-block:last-child {
  margin-bottom: 0;
}
.giveaways-single-sponsored-content-descr-block-title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: center;
  text-transform: uppercase;
}
.giveaways-single-sponsored-content-descr-block-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: justify;
}
.giveaways-single-sponsored-content-descr-block-text ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.giveaways-single-sponsored-content-descr-block-text ul li {
  position: relative;
  padding-left: 13px;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 6px;
    width: 6px;
    height: 6px;
    background-color: #dc291e;
    border-radius: 50%;
  }
}
.giveaways-single-sponsored-content-descr-block-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.giveaways-single-sponsored-content-descr-block-btn {
  .btn();
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}
.giveaways-single-sponsored-content-descr-block-btn img {
  width: 23px;
  margin-left: 14px;
}



@media @xxlarge-widths {
  .giveaways-single-sponsored-content-descr {
    padding-left: 40px;
  }
  .giveaways-single-sponsored-content-descr-block {
    margin-bottom: 15px;
  }
  .giveaways-single-sponsored-content-descr-block-title {
    margin-bottom: 15px;
  }
}



@media @xlarge-widths {
  .giveaways-single-sponsored-content-descr {
    width: 600px;
    padding-left: 30px;
  }
  .giveaways-single-sponsored-content-slider-wrapper {
    width: ~"calc(100% - 600px)";
  }
  .giveaways-single-sponsored-content-descr-title {
    margin-bottom: 10px;
    font-size: 28px;
  }
  .giveaways-single-sponsored-content-descr-block-title {
    margin-bottom: 7px;
    font-size: 18px;
  }
}



@media @large-widths {
  .giveaways-single-sponsored-title {
    font-size: 40px;
  }
  .giveaways-single-sponsored-content-descr {
    width: 560px;
    padding-left: 30px;
  }
  .giveaways-single-sponsored-content-slider-wrapper {
    width: ~"calc(100% - 560px)";
  }
  .giveaways-single-sponsored-content-descr-title {
    font-size: 26px;
  }
  .giveaways-single-sponsored-content-descr-block-title {
    margin-bottom: 5px;
    font-size: 16px;
  }
  .giveaways-single-sponsored-content-descr-block-text {
    font-size: 13px;
  }
}



@media @medium-widths {
  .giveaways-single-sponsored-content {
    display: block;
    max-width: 730px;
    margin: 0 auto;
    padding: 0 15px;
  }
  .giveaways-single-sponsored-content-slider-wrapper {
    width: 100%;
    margin-bottom: 30px;
  }
  .giveaways-single-sponsored-content-descr {
    width: 100%;
    padding-left: 0;
  }
}



@media @small-widths {
  .giveaways-single-sponsored {
    padding : 30px 0;
  }
  .giveaways-single-sponsored-title {
    font-size: 36px;
  }
  .giveaways-single-sponsored-content-descr-title {
    font-size: 24px;
  }
  .giveaways-single-sponsored-content-descr-block-title {
    font-size: 15px;
  }
  .giveaways-single-sponsored-content-descr-block-text {
    font-size: 12px;
  }
}



@media @extraSmall-widths {

}



@media @exxSmall-widths {
  .giveaways-single-sponsored {
    padding : 20px 0;
  }
  .giveaways-single-sponsored-title {
    font-size: 30px;
  }
  .giveaways-single-sponsored-icon {
    width: 48px;
  }
  .giveaways-single-sponsored-descr {
    font-size: 14px;
  }
  .giveaways-single-sponsored-content-descr-title {
    font-size: 22px;
  }
  .giveaways-single-sponsored-content-descr-block-title {
    font-size: 14px;
  }
}
