.freebies-wrapper {
  background-color: #100f0f;
}
.freebies {

}
.freebies-items {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.freebies-item {
  box-sizing: border-box;
  position: relative;
  width: ~"calc((100% - 25px) / 6)";
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: #464646;
}

@media (min-width: 1750px) {
  .freebies-item:nth-child(6n) {
    margin-right: 0;
  }
}
.freebies-item-img-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
}
.freebies-item-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
  transform: rotate(0) scale(1);
  transition: transform .5s;
}
.freebies-item:hover .freebies-item-img {
  transform: rotate(-5deg) scale(1.3);
}
.freebies-item-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background-color: rgba(220, 41, 30, .7);
  overflow-y: auto;
  opacity: 0;
  transition: opacity .5s;
}
.freebies-item:hover .freebies-item-hover {
  opacity: 1;
}
.freebies-item-hover-icon {
  display: block;
  text-align: center;
  cursor: pointer;
}
.freebies-item-hover-icon svg {
  display: inline-block;
  width: 25px;
  height: 26px;
  fill: #ffffff;
  transition: fill .5s;
  &:hover {
    fill: #000000;
  }
}



@media @xxlarge-widths {
  .freebies-item {
    width: ~"calc((100% - 20px) / 5)";
  }
}
@media (min-width: 1500px) and (max-width: 1749px) {
  .freebies-item:nth-child(5n) {
    margin-right: 0;
  }
}



@media @xlarge-widths {
  .freebies-item {
    width: ~"calc((100% - 15px) / 4)";
  }
}
@media (min-width: 992px) and (max-width: 1499px) {
  .freebies-item:nth-child(4n) {
    margin-right: 0;
  }
}



@media @small-widths {
  .freebies-item {
    width: ~"calc((100% - 10px) / 3)";
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .freebies-item:nth-child(3n) {
    margin-right: 0;
  }
}



@media @extraSmall-widths {
  .freebies-items {
    max-width: 500px;
    margin: 0 auto;
  }
  .freebies-item {
    width: ~"calc((100% - 5px) / 2)";
  }
  .freebies-item-hover-icon {
    margin-bottom: 15px;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .freebies-item:nth-child(2n) {
    margin-right: 0;
  }
}



@media @exxSmall-widths {
  .freebies-items {
    max-width: 260px;
  }
  .freebies-item {
    width: 100%;
    margin-right: 0;
  }
}
