.giveaways-page-items {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  margin-bottom: 35px;
}
.giveaways-page-item {
  box-sizing: border-box;
  width: ~"calc((100% - 36px) / 4)";
  margin-right: 12px;
  margin-bottom: 22px;
}
@media (min-width:1200px) {
  .giveaways-page-item:nth-child(4n) {
    margin-right: 0;
  }
}
.giveaways-page-item-header {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: #8a1f18;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
  transition: background-color .5s;
}
.giveaways-page-item:hover .giveaways-page-item-header {
  background-color: #b3241b;
}
.giveaways-page-item-header-period {
  display: flex;
  align-items: center;
  margin-right: auto;
  padding-right: 10px;
}
.giveaways-page-item-header-period-icon {
  width: 12px;
  height: 15px;
  margin-right: 5px;
  background-image: url("../img/icon-unlock-white.png");
  background-repeat: no-repeat;
  background-position: center;
}
.giveaways-page-item:hover .giveaways-page-item-header-period-icon {
  background-image: url("../img/icon-unlock-black.png");
}
.giveaways-page-item-img-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 45%;
}
.giveaways-page-item-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-size: cover;
}
.giveaways-page-item-img-btn-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(220, 41, 30, .85);
  opacity: 0;
  transition: opacity .5s;
}
.giveaways-page-item:hover .giveaways-page-item-img-btn-wrapper {
  opacity: 1;
}
.giveaways-page-item-img-btn-enter {
  .btn();
  margin-bottom: 7px;
  border: 2px solid #100f0f;
  color: #100f0f;
  &:hover {
    border: 2px solid #dc291e;
    color: #f4f3f3;
    background-color: #100f0f;
  }
}
.giveaways-page-item-img-btn-more {
  .btn();
  border: 2px solid #100f0f;
  color: #100f0f;
  &:hover {
    border: 2px solid #dc291e;
    color: #f4f3f3;
    background-color: #100f0f;
  }
}
.giveaways-page-item-footer {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: #100f0f;
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  transition: background-color .5s, color .5s;
}
.giveaways-page-item:hover .giveaways-page-item-footer {
  background-color: #f4f3f3;
  color: #dc291e;
}
.giveaways-page-item-footer-steam-icon {
  flex-shrink: 0;
  width: 26px;
  height: 24px;
  background-image: url("../img/icon-steam-red.png");
  background-repeat: no-repeat;
  background-position: center;
}
.giveaways-page-item:hover .giveaways-slide-footer-steam-icon {
  background-image: url("../img/icon-steam-black.png");
}
.giveaways-page-item-footer-users-icon {
  margin-right: 10px;
}
.giveaways-page-item-footer-users-icon svg {
  display: block;
  width: 29px;
  height: 29px;
  fill: #e11d24;
  transition: fill .5s;
}
.giveaways-page-item:hover .giveaways-slide-footer-users-icon svg {
  fill: #100f0f;
}
.giveaways-page-item-footer-name {

}
.giveaways-page-item-footer-comments {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: 10px;
}
.giveaways-page-item-footer-comments-icon {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  background-image: url("../img/icon-comments-red.png");
  background-repeat: no-repeat;
  background-position: center;
}
.giveaways-page-item:hover .giveaways-page-item-footer-comments-icon {
  background-image: url("../img/icon-comments-black.png");
}



@media @medium-widths {
  .giveaways-page-item {
    width: ~"calc((100% - 24px) / 3)";
  }
}
@media (min-width:992px) and (max-width:1199px) {
  .giveaways-page-item:nth-child(3n) {
    margin-right: 0;
  }
}



@media @small-widths {
  .giveaways-page-item {
    width: ~"calc((100% - 12px) / 2)";
  }
}
@media (min-width:600px) and (max-width:991px) {
  .giveaways-page-item:nth-child(2n) {
    margin-right: 0;
  }
}



@media (max-width:599px) {
  .giveaways-page-item {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}



@media @exxSmall-widths {
  .giveaways-page-item-header {
    font-size: 14px;
  }
}
