.store-filters {
  &::after {
    content: "";
    position: absolute;
  }
}
.store-filters-wrapper {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
}
.store-filters:hover .store-filters-wrapper {
  height: auto;
}
.store-filters-form {
  background-color: rgba(16, 15, 15, .88);
  border-top: 1px solid #dc291e;
  border-bottom: 1px solid #dc291e;
  z-index: 500;
  transform: translate(0, -100%);
}
.store-filters:hover .store-filters-form {
  transform: translate(0, 0);
  transition: transform .5s;
}
.store-filters-lists {
  display: flex;
  padding: 30px 25px;
  border-bottom: 1px solid #dc291e;
}
.store-filters-lists-platforms {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 17%;
  padding: 0 50px;
  border-right: 1px solid #dc291e;
}
.store-filters-lists-title {
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: normal;
  color: #dc291e;
  text-align: center;
  text-transform: uppercase;
}
.store-filters-lists-platforms-items {

}
.store-filters-lists input[type="checkbox"] {
  display: none;
}
.store-filters-lists label {
  position: relative;
  display: block;
  margin-bottom: 20px;
  padding-left: 20px;
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
  cursor: pointer;
  text-transform: uppercase;
  &::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 2px;
    left: 0;
    width: 9px;
    height: 9px;
    border: 2px solid #dc291e;
    opacity: 0;
    transition: opacity .5s;
  }
}
.store-filters-lists label:last-child {
  margin-bottom: 0;
}
.store-filters-lists input[type="checkbox"]:checked + label::before {
  opacity: 1;
}
.store-filters-lists-systems {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 17%;
  padding: 0 50px;
  border-right: 1px solid #dc291e;
}
.store-filters-lists-genres {
  box-sizing: border-box;
  width: 49%;
  padding: 0 50px;
  border-right: 1px solid #dc291e;
}
.store-filters-lists-genres-items {
  display: flex;
  flex-wrap: wrap;
}
.store-filters-lists-genres-items label {
  box-sizing: border-box;
  width: 20%;
  padding-left: 20px;
  padding-right: 20px;
  &::before {
    left: 0;
  }
}
.store-filters-lists-types {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 17%;
  padding: 0 50px;
}
.store-filters-lists-btn-block {
  display: flex;
  justify-content: center;
  padding: 25px;
}
.store-filters-lists-btn-wrapper {
  padding: 0 20px;
}
.store-filters-lists-btn-wrapper input,
.store-filters-lists-btn-wrapper a {
  .btn();
}



// @media @xlarge-widths {

// }



// @media @large-widths {
//
// }
// @media @medium-widths {
//
// }



@media @small-widths {
  .store-filters-form {
    position: static;
    background: none;
  }
  .store-filters-lists {
    display: block;
    padding: 20px;
  }
  .store-filters-lists-platforms {
    width: 100%;
    margin-bottom: 25px;
    padding: 0;
    border-right: none;
  }
  .store-filters-lists-systems {
    width: 100%;
    margin-bottom: 25px;
    padding: 0;
    border-right: none;
  }
  .store-filters-lists-genres {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 25px;
    padding: 0;
    border-right: none;
  }
  .store-filters-lists-genres-items {
    display: block;
  }
  .store-filters-lists-genres-items label {
    width: auto;
    padding-left: 0;
    padding-right: 0;
  }
  .store-filters-lists-genres-items label::before {
    left: -20px;
  }
  .store-filters-lists-types {
    width: 100%;
    padding: 0;
  }
  .store-filters-lists-title {
    margin-bottom: 10px;
    cursor: pointer;
  }
  .store-filters-lists-items {
    display: none;
  }
  .store-filters-lists label {
    margin-bottom: 10px;
    // text-align: center;
  }
  .store-filters-lists-btn-block {
    padding: 15px;
  }
}



// @media @extraSmall-widths {
//
// }
@media @exxSmall-widths {
  .store-filters-lists-btn-block {
    display: block;
  }
  .store-filters-lists-btn-wrapper {
    padding-bottom: 15px;
  }
  .store-filters-lists-btn-wrapper:last-child {
    padding-bottom: 0;
  }
}
