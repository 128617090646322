.page-header {
  //bootstrap
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #100f0f;
  border-bottom: 1px solid #292929;
  z-index: 1000;
}
.page-header--fixed {
  position: fixed;
}
.page-header__inner-wrapper {
  display: flex;
  align-items: center;
}
.page-header__nav {
  flex-grow: 1;
}
.page-header--fixed .page-header__nav {
  display: flex;
  flex-direction: row-reverse;
}



@media @xxlarge-widths {

}



@media @xlarge-widths {

}



@media @large-widths {

}



// @media @medium-widths {
//
// }



@media @small-widths {
  .page-header__inner-wrapper {
    padding: 5px 0;
  }
  .page-header__nav {
    display: flex;
    align-items: center;
    flex-grow: 0;
  }
  .page-header--fixed .page-header__nav {
    flex-direction: row;
  }
}



// @media @extraSmall-widths {
//
// }
// @media @exxSmall-widths {
//
// }
