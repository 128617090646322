.achievements-tab-pane {

}
.achievements-items {
  display: flex;
  flex-wrap: wrap;
}
.achievements-item {
  display: flex;
  flex-direction: column;
  width: ~"calc((100% - 24px) / 5)";
  margin-right: 6px;
  margin-bottom: 6px;
  padding: 25px;
  padding-top: 22px;
  background-color: #3a3a3a;
  border-bottom: 1px solid #757474;
}
@media (min-width: 1500px) {
  .achievements-item:nth-child(5n) {
    margin-right: 0;
  }
}
.achievements-item-name {
  margin-bottom: 24px;
  font-size: 15px;
  font-weight: normal;
  color: #e4e4e4;
  text-transform: uppercase;
  text-align: center;
}
.achievements-item-img {
  width: 64px;
  margin: 0 auto;
  margin-bottom: 24px;
}
.achievements-item-img img {
  display: block;
  width: 100%;
}
.achievements-item-descr {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #e4e4e4;
  text-align: justify;
}



// @media @xxlarge-widths {
//
// }



@media @xlarge-widths {
  .achievements-item {
    width: ~"calc((100% - 18px) / 4)";
  }
}
@media (min-width: 1200px) and (max-width: 1499px) {
  .achievements-item:nth-child(4n) {
    margin-right: 0;
  }
}



@media @large-widths {
  .achievements-item {
    padding: 15px;
  }
  .achievements-item-descr {
    font-size: 13px;
  }
}



@media @medium-widths {
  .achievements-item {
    width: ~"calc((100% - 12px) / 3)";
  }
}
@media (min-width: 680px) and (max-width: 1199px) {
  .achievements-item:nth-child(3n) {
    margin-right: 0;
  }
}



@media @small-widths {
  .achievements-items {
    max-width: 700px;
    margin: 0 auto;
  }
  .achievements-item-descr {
    font-size: 12px;
  }
}



// @media @extraSmall-widths {
//
// }



@media (max-width: 679px) {
  .achievements-items {
    max-width: 520px;
  }
  .achievements-item {
    width: ~"calc((100% - 6px) / 2)";
  }
}
@media (min-width: 480px) and (max-width: 679px) {
  .achievements-item:nth-child(2n) {
    margin-right: 0;
  }
}



@media @exxSmall-widths {
  .achievements-items {
    max-width: 280px;
  }
  .achievements-item {
    width: 100%;
    margin-right: 0;
  }
}
