.faq-contact-wrapper {
  background-color: #100f0f;
  border-bottom: 5px solid #212121;
}
.faq-contact {
  display: flex;
  padding-top: 15px;
  padding-bottom: 70px;
}
.faq {
  box-sizing: border-box;
  width: ~"calc((100% - 64px) / 2)";
  margin-right: 64px;
}
.faq-title {
  margin-bottom: 10px;
}
.accordion-item-body-text-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.accordion-item-body-text-btn-group-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.accordion-item-body-text-btn-wrapper a,
.accordion-item-body-text-btn-group-wrapper a {
  margin-right: 11px;
  border: 2px solid #f4f3f3;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    border: 2px solid #dc291e;
  }
}
.contact {
  box-sizing: border-box;
  width: ~"calc((100% - 64px) / 2)";
}
.contact-title {
  margin-bottom: 10px;
}
.contact-descr {
  margin-bottom: 10px;
  font-family: "Stadium 1946", sans-serif;
  font-size: 35px;
  font-weight: normal;
  text-align: center;
  color: @color-text-hover;
}
.contact-form {
  margin-bottom: 40px;
}
.contact-img {
  width: 100%;
}
.contact-img img {
  display: block;
  width: 100%;
}



@media @medium-widths {
  .faq .accordion-item-title-text {
    font-size: 16px;
  }
  .contact-descr {
    font-size: 32px;
  }
}



@media @small-widths {
  .faq-contact {
    display: block;
  }
  .faq {
    width: 100%;
    margin-bottom: 20px;
  }
  .contact {
    width: 100%;
  }
  .contact-descr {
    font-size: 30px;
  }
}



@media @extraSmall-widths {
  .contact-descr {
    font-size: 28px;
  }
}



@media @exxSmall-widths {
  .contact-descr {
    font-size: 26px;
  }
}
