.terms-promo-wrapper {
  padding-top: 20px;
  padding-bottom: 100px;
  background-image: url("../img/terms/terms-promo-bg.jpg");
  background-position: center;
  background-size: cover;
}
.terms-promo {
  max-width: 1150px;
  margin: 0 auto;
}
.terms-promo-title {
  margin-bottom: 20px;
}
.terms-promo-title span {
  color: @color-text-hover;
}
.terms-promo-descr {
  margin-bottom: 15px;
  text-align: center;
}
.terms-promo-text {
  text-align: center;
}
.terms-promo-text span {
  color: @color-text-hover;
}



@media @exxSmall-widths {
  .terms-promo-wrapper {
    padding-bottom: 30px;
  }
}
