.settings-filters-btn-wrapper {
  align-self: center;
  margin-left: 60px;
}
.settings-filters-btn {
  .btn();
  max-width: 160px;
  background-color: rgba(16, 15, 15, .7);
  border: 2px solid #f4f3f3;
  &:hover {
    background-color: rgba(16, 15, 15, 1);
    border: 2px solid #100f0f;
  }
}
.giveaways-page-settings-filters-wrapper .settings-filters-block {
  box-sizing: border-box;
  width: 40%;
}



@media @medium-widths {
  .giveaways-page-settings-filters-wrapper .settings-filters-block {
    width: 45%;
    padding: 0 15px;
  }
  .settings-filters-btn-wrapper {
    margin-left: 30px;
  }
}



@media @small-widths {
  .settings-filters-btn-wrapper {
    margin-left: 0;
    margin-top: 20px;
  }
}
