.articles-wrapper {

}
.articles {
  padding-top: 105px;
  padding-bottom: 60px;
  background-image: url("../img/articles-bg.jpg");
  background-position: center;
  background-size: cover;
}
.articles-title {
  .block-title();
  margin-bottom: 40px;
}
.articles-items {
  display: flex;
  margin-bottom: 40px;
}
.articles-item {
  display: flex;
  flex-direction: column;
  width: ~"calc((100% - 45px) / 4)";
  margin-right: 15px;
}
.articles-item:last-child {
  margin-right: 0;
}
.articles-item-img-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 60%;
  overflow: hidden;
}
.articles-item-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-size: cover;
  filter: blur(0px);
  transform: scale(1, 1) rotate(0deg);
  transition: filter .5s, transform .5s;
}
.articles-item:hover .articles-item-img {
  filter: blur(3px);
  transform: scale(1.1, 1.1) rotate(-2deg);
}
.articles-item-img-hover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  padding: 12px 18px;
  font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  color: #f4f3f3;
  text-transform: uppercase;
  opacity: 0;
  transition: opacity .5s ;
}
.articles-item:hover .articles-item-img-hover {
  opacity: 1;
}
.articles-item-img-hover-author {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: auto;
}
.articles-item-img-hover-author-icon {

}
.articles-item-img-hover-date {
  display: flex;
  align-items: center;
  align-self: flex-end;
  margin-right: auto;
}
.articles-item-img-hover-date-icon {
  margin-right: 5px;
}
.articles-item-img-hover-date-icon img {
  display: block;
  width: 100%;
}
.articles-item-img-hover-comments {
  display: flex;
  align-items: center;
  align-self: flex-end;
}
.articles-item-img-hover-comments-icon {
  margin-right: 5px;
}
.articles-item-img-hover-comments-icon img {
  display: block;
  width: 100%;
}
.articles-item-img-hover-btn {
  .btn();
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}
.articles-item-name {
  padding: 10px 20px;
  background-color: #ca281e;
  font-size: 16px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
}
.articles-item-descr {
  flex-grow: 1;
  padding: 20px 25px;
  background-color: rgba(16, 15, 15, .2);
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #f4f3f3;
  transition: background-color .5s;
}
.articles-item:hover .articles-item-descr {
  background-color: rgba(16, 15, 15, .7);
}
.articles-btn-wrapper {
  text-align: center;
}
.articles-btn {
  .btn();
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}
.articles-points {
  display: flex;
  background-image: url("../img/articles-points-bg.jpg");
  background-position: center;
  background-size: cover;
}
.articles-points > .container {
  display: flex;
  justify-content: center;
}
.articles-points-inner-wrapper {
  display: flex;
  justify-content: center;
}
.articles-points-content {
  position: relative;
  align-self: center;
  padding: 60px 0;
  z-index: 20;
}
.articles-points-content-title {
  width: 100%;
  margin-bottom: 15px;
  font-size: 48px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
  text-shadow: -5px 5px 0 #000000;
}
.articles-points-content-block {
  display: flex;
  align-items: center;
}
.articles-points-content-phrase {
  margin-right: auto;
  padding: 10px 25px;
  background-color: #dc291e;
  align-self: flex-end;
  transform: rotate(-2deg);
  box-shadow: -5px 5px 0 0 #000000;
  font-family: "Stadium 1946", sans-serif;
  font-size: 42px;
  font-weight: normal;
  line-height: 1;
  color: #f4f3f3;
  text-transform: uppercase;
}
.articles-points-content-btn {
  .btn();
  align-self: flex-end;
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}
.articles-points-img {
  position: relative;
  flex-shrink: 0;
  width: 370px;
  margin-left: -100px;
  align-self: flex-end;
  z-index: 10;
}
.articles-points-img img {
  display: block;
  width: 100%;
}



@media @large-widths {
  .articles-item-descr {
    font-size: 13px;
  }
}



@media @medium-widths {
  .articles-items {
    flex-wrap: wrap;
  }
  .articles-item {
    width: ~"calc((100% - 20px) / 2)";
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .articles-item:nth-child(2n) {
    margin-right: 0;
  }
  .articles-item:last-child {
    margin-bottom: 0;
  }
  .articles-points-content-title {
    font-size: 40px;
  }
  .articles-points-content-phrase {
    font-size: 34px;
  }
  .articles-points-img {
    width: 300px;
  }
}



@media @small-widths {
  .articles-points-content-title {
    font-size: 30px;
  }
  .articles-points-content-phrase {
    font-size: 24px;
  }
  .articles-points-img {
    width: 280px;
  }
  .articles-item-descr {
    font-size: 12px;
  }
}



@media @extraSmall-widths {
  .articles-items {
    display: block;
    margin-bottom: 85px;
  }
  .articles-item {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .articles-item:last-child,
  .articles-item:nth-child(2n) {
    margin-right: auto;
  }
  .articles-items .owl-dots {
    position: absolute;
    bottom: -45px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 0;
  }
  .articles-items .owl-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 25px;
    background-color: #ffffff;
    border-radius: 50%;
  }
  .articles-items .owl-dot:last-child {
    margin-right: 0;
  }
  .articles-items .owl-dot.active {
    background-color: #dc291e;
  }
  .articles-points-inner-wrapper {
    display: block;
  }
  .articles-points-content {
    padding: 30px 0;
  }
  .articles-points-content-title {
    font-size: 24px;
    text-align: center;
  }
  .articles-points-content-block {
    flex-direction: column;
  }
  .articles-points-content-phrase {
    align-self: center;
    margin-right: 0;
    margin-bottom: 20px;
    font-size: 20px;
  }
  .articles-points-content-btn {
    align-self: center;
  }
  .articles-points-img {
    width: 250px;
    margin: 0 auto;
    margin-top: -50px;
  }
}
