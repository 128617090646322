.page-header__notifications-wrapper {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 550px;
  transform: translate(100%, 0);
  background-color: rgba(16, 15, 15, .9);
  border-left : 1px solid #3e3e3e;
  z-index: 4000;
  overflow-x: hidden;
  overflow-y: auto;
  transition: transform .5s;
}
.page-header__notifications-wrapper--opened {
  transform: translate(0, 0);
}
.page-header__notifications {
  position: relative;
}
.page-header__notifications-btn-close {
  position: absolute;
  top: 30px;
  right: 55px;
  width: 31px;
  height: 30px;
  cursor: pointer;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 14px;
    left: 11px;
    width: 9px;
    height: 2px;
    background-color: #ffffff;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
  &:hover {
    &::before,
    &::after {
      background-color: #ed1c24;
    }
  }
}
.page-header__notifications-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  padding-top: 35px;
  padding-left: 50px;
  padding-right: 150px;
}
.page-header__notifications-title-icon {
  flex-shrink: 0;
  width: 32px;
}
.page-header__notifications-title-icon img {
  display: block;
  width: 100%;
}
.page-header__notifications-title-text {
  font-size: 15px;
  font-weight: normal;
  color: #ed1c24;
  text-transform: uppercase;
}
.page-header__notifications-items {

}
.page-header__notifications-item {
  padding: 12px 0;
  padding-left: 50px;
  padding-right: 150px;
  border-bottom: 1px solid #3e3e3e;
}
.page-header__notifications-item-header {
  display: flex;
  margin-bottom: 5px;
  font-family: 'Open Sans', sans-serif;
  font-size: 11px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.page-header__notifications-item-header-date {
  position: relative;
  padding-left: 17px;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 10px;
    height: 11px;
    background-image: url("../img/icon-calendar.png");
    background-repeat: no-repeat;
    background-position: center;
  }
}
.page-header__notifications-item-text {
  margin-bottom: 10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 11px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: justify;
}
.page-header__notifications-item-footer {

}
.page-header__notifications-item-footer-mark-read {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.page-header__notifications-item-footer-mark-read-text {
  margin-right: 10px;
  font-size: 11px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.page-header__notifications-item-footer-mark-read-icon {
  position: relative;
  width: 11px;
  height: 11px;
  background-color: #ffffff;
  border-radius: 50%;
  transition: background-color .5s;
  &::before {
    content: "";
    position: absolute;
    top: 3.5px;
    left: 3px;
    transform: rotate(-45deg);
    width: 4px;
    height: 2px;
    border-left: 1px solid #312625;
    border-bottom: 1px solid #312625;
  }
}
.page-header__notifications-item-footer-mark-read--readed .page-header__notifications-item-footer-mark-read-icon {
  background-color: #ed1c24;
}
.page-header__notifications-btn-wrapper {
  display: flex;
  padding: 25px 0;
  padding-left: 50px;
  padding-right: 150px;
}
.page-header__notifications-btn-all {
  position: relative;
  margin-right: auto;
  padding-right: 20px;
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-decoration: none;
  transition: color .5s;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    width: 8px;
    height: 8px;
    background-repeat: no-repeat;
    background-position: center;
    transition: opacity .5s;
  }
  &::before {
    background-image: url("../img/arrow.png");
    opacity: 1;
  }
  &::after {
    background-image: url("../img/arrow-red.png");
    opacity: 0;
  }
  &:hover {
    color: #ed1c24;
    &::before {
      opacity: 0;
    }
    &::after {
      opacity: 1;
    }
  }
}
.page-header__notifications-btn-clear {
  position: relative;
  padding-left: 20px;
  padding-right: 24px;
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-decoration: none;
  transition: color .5s;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    width: 15px;
    height: 14px;
    background-repeat: no-repeat;
    background-position: center;
    transition: opacity .5s;
  }
  &::before {
    background-image: url("../img/icon/icon-delete.png");
    opacity: 1;
  }
  &::after {
    background-image: url("../img/icon/icon-delete-red.png");
    opacity: 0;
  }
  &:hover {
    color: #ed1c24;
    &::before {
      opacity: 0;
    }
    &::after {
      opacity: 1;
    }
  }
}



@media (max-width: 600px) {
  .page-header__notifications-btn-close {
    top: 20px;
    right: 20px;
  }
  .page-header__notifications-title {
    padding-left: 30px;
    padding-right: 60px;
  }
  .page-header__notifications-item {
    padding-left: 30px;
    padding-right: 30px;
  }
  .page-header__notifications-btn-wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }
}
