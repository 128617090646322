.quests-wrapper {
  background-color: #2b2a2a;
}
.quests-inner-wrapper {
  display: flex;
  padding-bottom: 54px;
}
.quests-sidebar {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 265px;
  margin-right: 58px;
}
.quests-sidebar-items {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #424141;
}
.quests-sidebar-item {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  margin-bottom: 3px;
  padding: 10px;
  background-color: #100f0f;
  border: 1px solid #100f0f;
  font-size: 20px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
  transition: color .5s, border .5s;
  &::before {
    content: "";
    position: absolute;
    right: -1px;
    top: 50%;
    transform: translate(0, -50%);
    width: 11px;
    height: 41px;
    background-image: url("../img/quests/quests-sidebar-marker-active.png");
    background-position: right center;
    background-repeat: no-repeat;
    opacity: 0;
    transition: opacity .5s;
  }
  &:hover {
    border: 1px solid #ed1c24;
    text-decoration: none;
    color: #ed1c24;
  }
  &:focus {
    border: 1px solid #ed1c24;
    text-decoration: none;
    color: #ed1c24;
  }
}
.quests-sidebar-item--active {
  border: 1px solid #ed1c24;
  color: #ed1c24;
  &::before {
    opacity: 1;
  }
}
.quests-sidebar-item:last-child {
  margin-bottom: 0;
}
.quests-sidebar-item img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity .5s;
  max-width: ~"calc(100% - 30px)";
  max-height: 90%;
}
.quests-sidebar-item .img {
  opacity: 1;
}
.quests-sidebar-item .img-hover {
  opacity: 0;
}
.quests-sidebar-item:hover .img {
  opacity: 0;
}
.quests-sidebar-item:hover .img-hover {
  opacity: 1;
}
.quests-sidebar-item--active .img {
  opacity: 0;
}
.quests-sidebar-item--active .img-hover {
  opacity: 1;
}
.quests-sidebar-links {
  display: flex;
  flex-direction: column;
}
.quests-sidebar-links-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 84px;
  margin-bottom: 5px;
  background-color: #100f0f;
  font-size: 20px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
  transition: color .5s;
  &:hover {
    text-decoration: none;
    color: #ed1c24;
  }
  &:focus {
    font-size: 20px;
    color: #f4f3f3;
    text-decoration: none;
  }
}
.quests-sidebar-links-item:last-child {
  margin-bottom: 0;
}
.quests {
  flex-grow: 1;
}
.quests-block {

}
.quests-block-title {
  padding: 17px 25px;
  background-color: #100f0f;
  font-size: 20px;
  font-weight: normal;
  color: #ed1c24;
  text-transform: uppercase;
  text-align: center;
}
.quests-block-items {
  display: flex;
  align-items: center;
  padding: 45px 10px;
}
.quests-block-item {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: ~"calc((100% - 100px) / 3)";
  margin-right: 50px;
}
.quests-block-item:last-child {
  margin-right: 0;
}
.quests-block-item-img {
  flex-shrink: 0;
  width: 209px;
  margin-right: 20px;
}
.quests-block-item-img img {
  display: block;
  width: 100%;
}
.quests-block-item-descr {
  flex-direction: 1;
}
.quests-block-item-descr-name {
  font-size: 25px;
  font-weight: bold;
  line-height: 1;
  color: #f4bd21;
  text-transform: uppercase;
}
.quests-block-item-descr-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}



@media @xxlarge-widths {
  .quests-sidebar-item {
    height: 100px;
  }
  .quests-block-item {
    width: ~"calc((100% - 70px) / 3)";
    margin-right: 35px;
  }
  .quests-block-item-img {
    width: 160px;
    margin-right: 15px;
  }
  .quests-block-item-descr-name {
    font-size: 24px;
  }
}



@media @xlarge-widths {
  .quests-block-item {
    width: ~"calc((100% - 60px) / 3)";
    margin-right: 30px;
  }
  .quests-block-item-img {
    width: 140px;
  }
  .quests-block-item-descr-name {
    font-size: 22px;
  }
  .quests-block-item-descr-text {
    font-size: 12px;
  }
}



@media @large-widths {
  .quests-block-items {
    align-items: stretch;
  }
  .quests-block-item {
    display: block;
  }
  .quests-block-item-img {
    width: 140px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .quests-block-item-descr-name {
    text-align: center;
  }
  .quests-block-item-descr-text {
    text-align: center;
  }
}



@media @medium-widths {
  .quests-sidebar {
    width: 220px;
    margin-right: 40px;
  }
  .quests-sidebar-item {
    height: 90px;
    font-size: 18px;
  }
  .quests-sidebar-links-item {
    font-size: 18px;
  }
  .quests-block-item-descr-name {
    font-size: 20px;
  }
}



@media @small-widths {
  .quests-sidebar {
    width: 190px;
    margin-right: 20px;
  }
  .quests-sidebar-item {
    font-size: 16px;
  }
  .quests-block-item-img {
    width: 120px;
  }
  .quests-sidebar-links-item {
    height: 70px;
    font-size: 16px;
  }
  .quests-block-item-descr-name {
    font-size: 18px;
  }
}



@media @extraSmall-widths {
  .quests-inner-wrapper {
    display: block;
  }
  .quests-sidebar {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .quests-sidebar-items {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  .quests-sidebar-item {
    width: ~"calc((100% - 10px) / 3)";
    height: 60px;
    margin-right: 5px;
    margin-bottom: 5px;
    padding-right: 15px;
  }
  .quests-sidebar-item:last-child {
    margin-right: 0;
  }
  .quests-sidebar-links {
    flex-direction: row;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #424141;
  }
  .quests-sidebar-links-item {
    width: ~"calc((100% - 5px) / 2)";
    height: 60px;
    margin-right: 5px;
    margin-bottom: 0;
  }
  .quests-sidebar-links-item:nth-child(2n) {
    margin-right: 0;
  }
}



@media (min-width: 580px) and(max-width: 767px) {
  .quests-sidebar-item:nth-child(3n) {
    margin-right: 0;
  }
}



@media (max-width: 579px) {
  .quests-sidebar-item {
    width: ~"calc((100% - 5px) / 2)";
  }
  .quests-sidebar-item:nth-child(2n) {
    margin-right: 0;
  }
  .quests-block-items {
    display: block;
    max-width: 350px;
    margin: 0 auto;
    padding: 25px 10px;
  }
  .quests-block-item {
    display: flex;
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
  .quests-block-item:last-child {
    margin-bottom: 0;
  }
  .quests-block-item-img {
    margin-right: 20px;
    margin-bottom: 0;
  }
  .quests-block-title {
    font-size: 18px;
  }
}



@media @exxSmall-widths {
  .quests-sidebar-item {
    height: 50px;
    font-size: 14px;
  }
  .quests-sidebar-links-item {
    height: 50px;
    font-size: 14px;
  }
  .quests-block-title {
    font-size: 16px;
  }
}
