.product-nav-tabs-wrapper {
  background-color: #100f0f;
}
.product-nav-tabs-wrap {
  display: flex;
  max-width: 1700px;
  margin: 0 auto;
  padding: 0 25px;
}
.product-nav-tabs {
  flex-grow: 1;
  border-bottom: none;
}
.product-nav-tabs li {
  width: ~"calc((100% - 18px) / 4)";
  margin-right: 6px;
  margin-bottom: 0;
}
.product-nav-tabs li:last-child {
  margin-right: 0;
}
.nav-tabs.product-nav-tabs li a {
  margin-right: 0;
  margin-bottom: 0;
  padding: 17px 15px;
  background-color: #1e1d1d;
  border: none;
  border-radius: 0;
  font-size: 18px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: center;
  text-transform: uppercase;
  transition: color .5s, background-color .5s;
  &:hover {
    margin-bottom: 0;
    background-color: #2b2a2a;
    border: none;
  }
  &:focus {
    border: none;
    outline: none;
    outline-offset: 0;
    background-color: #2b2a2a;
  }
}
.nav-tabs.product-nav-tabs li.active a {
  background-color: #2b2a2a;
  color: #ed1c24;
}
.product-nav-tabs-social {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 400px;
  margin-left: 80px;
}
.product-nav-tabs-social-title {
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.product-nav-tabs-social-items {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.product-nav-tabs-social-item {
  position: relative;
  display: flex;
  align-items: center;
  width: 14px;
  height: 16px;
  margin-left: 18px;
}
.product-nav-tabs-social-item-tw {
  width: 18px;
  height: 16px;
}
.product-nav-tabs-social-item-d {
  width: 22px;
}
.product-nav-tabs-social-item .img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: opacity .5s;
}
.product-nav-tabs-social-item .img-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity .5s;
}
.product-nav-tabs-social-item:hover .img {
  opacity: 0;
}
.product-nav-tabs-social-item:hover .img-hover {
  opacity: 1;
}
.product-block-wrapper {
  background-color: #2b2a2a;
}
.product-block {
  display: flex;
  max-width: 1700px;
  margin: 0 auto;
  padding: 0 25px;
}
.product-tab-content-wrapper {
  box-sizing: border-box;
  flex-grow: 1;
  max-width: 1190px;
  margin: 0 auto;
}
.product-tab-content {
  padding: 35px 0;
}



// @media @xxlarge-widths {
//
// }



@media @xlarge-widths {
  .nav-tabs.product-nav-tabs li a {
    font-size: 16px;
  }
  .product-nav-tabs-social {
    width: 380px;
    margin-left: 40px;
  }
}



@media @large-widths {
  .nav-tabs.product-nav-tabs li a {
    font-size: 15px;
  }
  .product-nav-tabs-social {
    width: 350px;
    margin-left: 30px;
  }
}



@media @medium-widths {
  .nav-tabs.product-nav-tabs li a {
    padding: 10px 7px;
    font-size: 12px;
  }
  .product-nav-tabs-social {
    width: 300px;
    margin-left: 20px;
  }
}



@media @small-widths {
  .product-nav-tabs-wrap {
    flex-direction: column-reverse;
  }
  .product-nav-tabs-social {
    width: auto;
    justify-content: flex-start;
    margin-left: 0;
    margin-bottom: 10px;
  }
  .product-block {
    display: block;
  }
}



@media @extraSmall-widths {
  .product-nav-tabs li {
    width: auto;
  }
}



@media @exxSmall-widths {

}
