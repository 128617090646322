.promo {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 175px;
  background-color: #212121;
}
.promo__small-slider-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 15px 0;
}
.promo__small-slide {
  position: relative;
  max-width: 340px;
  margin: 0 auto;
  border-bottom: 3px solid transparent;
  transition: border-bottom .5s;
  &:hover {
    border-bottom: 3px solid #dc291e;
  }
}
.promo__small-slide-img {
  display: block;
  width: 100%;
  height: 145px;
  background-position: center;
  background-size: cover;
}
.promo__small-slide-hover-wrapper {
  box-sizing: border-box;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  display: none;
  padding-top: 8px;
  overflow: hidden;
  transition: height .5s;
}
.promo__small-slide-hover {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 580px;
  min-height: 325px;
  border: 3px solid #dc291e;
  background-position: center;
  background-size: cover;
  &::before {
    content: "";
    position: absolute;
    top: -3px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 41px;
    height: 11px;
    background-image: url("../img/promo-small-slider-corner.png");
    background-position: center;
  }
}
.promo__small-slide-hover-content {
  flex-grow: 1;
  display: flex;
}
.promo__small-slide-hover-info {
  box-sizing: border-box;
  width: 200px;
  padding: 8px 0;
  background-color: rgba(16, 15, 15, .8);
  font-size: 14px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: center;
  text-transform: uppercase;
}
.promo__small-slide-hover-info-price  {
  margin-bottom: 10px;
  padding: 0 20px;
}
.promo__small-slide-hover-info-price-new span {
  font-size: 20px;
  font-weight: bold;
  color: #dc291e;
}
.promo__small-slide-hover-info-price-old {
  text-decoration: line-through;
}
.promo__small-slide-hover-info-discount {
  padding: 9px 20px;
  background-color: #ed1c24;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.promo__small-slide-hover-info-points {
  margin-bottom: 12px;
  padding: 8px 20px;
  border-top: 1px solid #191616;
  border-bottom: 1px solid #191616;
}
.promo__small-slide-hover-info-points span {
  font-size: 20px;
  font-weight: bold;
  color: #dc291e;
}
.promo__small-slide-hover-info-logo {
  width: 30px;
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 0 20px;
}
.promo__small-slide-hover-info-logo img {
  display: block;
  width: 100%;
}
.promo__small-slide-hover-info-btn-add-to-cart {
  .btn();
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: ~"calc(100% - 40px)";
  height: 35px;
  margin: 0 auto;
  margin-bottom: 4px;
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}
.promo__small-slide-hover-info-btn-more {
  .btn();
  display: block;
  max-width: ~"calc(100% - 40px)";
  margin: 0 auto;
  color: #dc291e;
  &:hover {
    color: #f4f3f3;
  }
}
.promo__small-slide-hover-post-info {
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-top: auto;
  padding: 10px 17px ;
}
.promo__small-slide-hover-release {
  margin-right: auto;
  padding-right: 10px;
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.promo__small-slide-hover-watch {
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.promo__small-slide-hover-watch img {
  margin-right: 5px;
}
.promo__small-slide-hover-like {
  margin-left: 20px;
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.promo__small-slide-hover-like img {
  margin-right: 5px;
}
.promo__small-slide-hover-footer {
  display: flex;
  align-items: center;
  padding: 2px 17px;
  background-color: rgba(220, 41, 30, .8);
}
.promo__small-slide-hover-footer-name {
  flex-grow: 1;
  display: flex;
  align-items: center;
  min-height: 38px;
  padding-right: 20px;
  border-right: 1px solid #dc291e;
  font-size: 14px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.promo__small-slide-hover-footer-add {
  padding-left: 20px;
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-decoration: none;
  &:hover {
    color: #f4f3f3;
    text-decoration: none;
  }
  &:focus {
    color: #f4f3f3;
    text-decoration: none;
  }
}
.promo-inner-wrapper {
  position: relative;
  flex-grow: 1;
  padding-left: 320px;
  padding-right: 15px;
}
.promo__top-games {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 320px;
  padding: 0 15px;
  padding-bottom: 15px;
  z-index: 500;
}
.promo__top-games-title {
  background-image: url("../img/promo-top-games-title-bg.jpg");
  background-position: center;
  background-size: cover;
  font-family: "Stadium 1946", sans-serif;
  font-size: 30px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: center;
}
.promo__top-games-items {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.promo__top-games-item {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  text-decoration: none;
  &:hover {
    color: #f4f3f3;
    text-decoration: none;
  }
  &:focus {
    color: #f4f3f3;
    text-decoration: none;
  }
}
.promo__top-games-item-img {
  box-sizing: border-box;
  position: relative;
  min-height: 100px;
  flex-grow: 1;
  background-position: center;
  background-size: cover;
  box-shadow: 0 0 0 0 #dc291e;
  transition: box-shadow .5s;
  &::before {
    content: "";
    position: absolute;
    right: -3px;
    top: 50%;
    transform: translate(0, -50%);
    width: 15px;
    height: 41px;
    background-image: url("../img/promo-top-games-corner.png");
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;
    transition: opacity .5s;
  }
  &:hover {
    box-shadow: 0 0 0 2px #dc291e;
  }
}
.promo__top-games-item--active .promo__top-games-item-img {
  box-shadow: 0 0 0 1px #dc291e;
  &::before {
    opacity: 1;
  }
}
.promo__top-games-item-descr {
  flex-shrink: 0;
  padding-top: 10px;
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-decoration: none;
}
.promo__container {
  position: relative;
  z-index: 500;
}
.promo__big-slider-wrapper {
  padding-bottom: 15px;
  width: 100%;
}
.promo__big-slider {

}
.promo__big-slide {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 50px 60px;
  background-position: center;
  background-size: cover;
}
.promo__big-slide-img {
  align-self: flex-end;
  flex-shrink: 0;
  width: 350px;
}
.promo__big-slide-img img {
  display: block;
  width: 100%;
}
.promo__big-slide-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.promo__big-slide-title {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.promo__big-slide-title-img {
  width: 155px;
}
.promo__big-slide-title-img img {
  display: block;
  width: 100%;
}
.promo__big-slide-title-text {
  font-family: "Stadium 1946", sans-serif;
  font-size: 130px;
  line-height: .9;
  color: #dc291e;
  text-transform: uppercase;
  text-align: center;
  text-shadow: -5px 5px 0 #000000;
}
.promo__big-slide-descr {
  margin-bottom: 5px;
  font-size: 70px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
  text-shadow: -5px 5px 0 #000000;
}
.promo__big-slide-phrase {
  margin-bottom: 35px;
  padding: 0 15px;
  background-color: #dc291e;
  font-family: "Stadium 1946", sans-serif;
  font-size: 70px;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
  box-shadow: -5px 5px 0 0 #000000;
}
.promo__big-slide-btn-wrapper {
  text-align: center;
}
.promo__big-slide-btn {
  display: inline-block;
  padding: 9px 40px;
  border: 2px solid #f4f3f3;
  font-size: 10px;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  transition: background-color .5s, border .5s;
  &:hover {
    background-color: #dc291e;
    border: 2px solid #dc291e;
    color: #f4f3f3;
    text-decoration: none;
  }
}
.promo__big-slider .owl-nav .owl-prev {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translate(0, -50%);
  width: 31px;
  height: 30px;
  font-size: 0;
  cursor: pointer;
  z-index: 100;
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 12px;
    top: 14px;
    width: 14px;
    height: 2px;
    transform-origin: 1px 50%;
    background-color: #ffffff;
  }
  &::after {
    transform: rotate(50deg);
  }
  &::before {
    transform: rotate(-50deg);
  }
}
.promo__big-slider .owl-nav .owl-next {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0px, -50%);
  width: 31px;
  height: 30px;
  font-size: 0;
  cursor: pointer;
  z-index: 100;
  &::before,
  &::after {
    content: "";
    position: absolute;
    right: 12px;
    top: 14px;
    width: 14px;
    height: 2px;
    transform-origin: 13px 50%;
    background-color: #ffffff;
  }
  &::before {
    transform: rotate(50deg);
  }
  &::after {
    transform: rotate(-50deg);
  }
}
.promo__big-slider .owl-dots {
  position: absolute;
  bottom: 45px;
  left: 50px;
  font-size: 0;
}
.promo__big-slider .owl-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 25px;
  background-color: #ffffff;
  border-radius: 50%;
}
.promo__big-slider .owl-dot:last-child {
  margin-right: 0;
}
.promo__big-slider .owl-dot.active {
  background-color: #dc291e;
}
.promo__top-games-block {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 15px;
  background-position: center;
  background-size: cover;
  z-index: 200;
}
.promo__top-games-block-inner-wrapper {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.promo__top-games-block-sale {
  box-sizing: border-box;
  width: 200px;
  margin-top: auto;
  margin-left: 50px;
  margin-bottom: 50px;
  padding: 8px 0;
  background-color: rgba(16, 15, 15, .7);
  font-size: 14px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: center;
  text-transform: uppercase;
}
.promo__top-games-block-sale-price {
  margin-bottom: 10px;
  padding: 0 20px;
}
.promo__top-games-block-sale-price-new {

}
.promo__top-games-block-sale-price-new span {
  font-size: 20px;
  color: #dc291e;
}
.promo__top-games-block-sale-price-old {
  text-decoration: line-through;
}
.promo__top-games-block-sale-discount {
  padding: 9px 20px;
  background-color: #ed1c24;
  font-size: 20px;
  text-align: center;
}
.promo__top-games-block-sale-points {
  margin-bottom: 12px;
  padding: 8px 20px;
  border-top: 1px solid #191616;
  border-bottom: 1px solid #191616;
}
.promo__top-games-block-sale-points span {
  font-size: 20px;
  color: #dc291e;
}
.promo__top-games-block-sale-logo {
  width: 30px;
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 0 20px;
}
.promo__top-games-block-sale-logo img {
  display: block;
  width: 100%;
}
.promo__top-games-block-sale-btn-add-to-cart {
  .btn();
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: ~"calc(100% - 40px)";
  height: 35px;
  margin: 0 auto;
  margin-bottom: 4px;
}
.promo__top-games-block-sale-btn-add-to-cart-text {

}
.promo__top-games-block-sale-btn-add-to-cart-icon {

}
.promo__top-games-block-sale-btn-add-to-cart-icon svg {

}
.promo__top-games-block-sale-btn-add-to-wishlist {
  .btn();
  display: block;
  max-width: ~"calc(100% - 40px)";
  margin: 0 auto;
}
.promo__top-games-block-descr {
  box-sizing: border-box;
  width: 560px;
  margin-top: auto;
  margin-right: 50px;
  margin-bottom: 50px;
  margin-left: auto;
  padding-bottom: 20px;
  background-color: rgba(16, 15, 15, .7);
}
.promo__top-games-block-descr-header {
  display: flex;
  align-items: center;
  padding: 10px 20px;
}
.promo__top-games-block-descr-header-date {
  margin-right: auto;
  font-size: 10px;
  font-weight: normal;
  color: #dc291e;
  text-transform: uppercase;
}
.promo__top-games-block-descr-header-watch {
  display: flex;
  align-items: center;
  margin-right: 25px;
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
}
.promo__top-games-block-descr-header-watch img {
  margin-right: 5px;
}
.promo__top-games-block-descr-header-like {
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
  cursor: pointer;
  transition: color .5s;
  &:hover {
    color: #dc291e;
  }
}
.promo__top-games-block-descr-header-like-icon {
  position: relative;
  width: 15px;
  height: 14px;
  margin-right: 5px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../img/icon-like.png");
    background-repeat: no-repeat;
    background-position: center;
    transition: opacity .5s;
  }
  &::before {
    background-image: url("../img/icon-like.png");
    opacity: 1;
  }
  &::after {
    background-image: url("../img/icon-like-red.png");
    opacity: 0;
  }
}
.promo__top-games-block-descr-header-like:hover .promo__top-games-block-descr-header-like-icon {
  &::before {
    opacity: 0;
  }
  &::after {
    opacity: 1;
  }
}
.promo__top-games-block-descr-title {
  padding: 5px 20px;
  background-color: #d91c23;
  font-size: 20px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
}
.promo__top-games-block-descr-text {
  margin-bottom: 20px;
  padding: 5px 20px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #f4f3f3;
  text-align: justify;
}
.promo__top-games-block-descr-text p {
  margin: 3px 0;
}
.promo__top-games-block-descr-footer {
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.promo__top-games-block-descr-footer-btn {
  .btn();
  margin-right: auto;
}
.promo__top-games-block-descr-footer-social {
  display: flex;
  align-items: center;
}
.promo__top-games-block-descr-footer-social-title {
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.promo__top-games-block-descr-footer-social-items {
  display: flex;
  align-items: center;
}
.promo__top-games-block-descr-footer-social-item {
  position: relative;
  display: flex;
  align-items: center;
  width: 14px;
  height: 16px;
  margin-left: 18px;
}
.promo__top-games-block-descr-footer-social-item-tw {
  width: 18px;
  height: 16px;
}
.promo__top-games-block-descr-footer-social-item .img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: opacity .5s;
}
.promo__top-games-block-descr-footer-social-item .img-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity .5s;
}
.promo__top-games-block-descr-footer-social-item:hover .img {
  opacity: 0;
}
.promo__top-games-block-descr-footer-social-item:hover .img-hover {
  opacity: 1;
}



@media @xxlarge-widths {
  .promo__big-slide-img {
    width: 320px;
  }
  .promo__big-slide-title-img {
    width: 140px;
  }
  .promo__big-slide-title-text {
    font-size: 120px;
  }
  .promo__big-slide-descr {
    font-size: 60px;
  }
  .promo__big-slide-phrase {
    font-size: 60px;
  }
}



@media @xlarge-widths {
  .promo__big-slide-img {
    width: 280px;
  }
  .promo__big-slide-title-img {
    width: 120px;
  }
  .promo__big-slide-title-text {
    font-size: 100px;
    text-shadow: -4px 4px 0 #000000;
  }
  .promo__big-slide-descr {
    font-size: 50px;
    text-shadow: -4px 4px 0 #000000;
  }
  .promo__big-slide-phrase {
    font-size: 50px;
    box-shadow: -4px 4px 0 0 #000000;
  }
}



@media @large-widths {
  .promo-inner-wrapper {
    padding-left: 300px;
  }
  .promo__top-games {
    width: 300px;
  }
  .promo__big-slide-img {
    width: 260px;
  }
  .promo__big-slide-title-img {
    width: 110px;
  }
  .promo__big-slide-title-text {
    font-size: 90px;
    text-shadow: -4px 4px 0 #000000;
  }
  .promo__big-slide-descr {
    font-size: 42px;
    text-shadow: -4px 4px 0 #000000;
  }
  .promo__big-slide-phrase {
    font-size: 42px;
    box-shadow: -4px 4px 0 0 #000000;
  }
  .promo__top-games-block-descr {
    width: 500px;
  }
  .promo__top-games-block-descr-text {
    font-size: 13px;
  }
}



@media @medium-widths {
  .promo-inner-wrapper {
    display: flex;
    flex-direction: column-reverse;
    padding-left: 15px;
  }
  .promo__top-games {
    position: static;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .promo__top-games-items {
    flex-direction: row;
  }
  .promo__top-games-item {
    width: ~"calc((100% - 20px) / 3)";
    margin-right: 10px;
  }
  .promo__top-games-item:last-child {
    margin-right: 0;
  }
  .promo__top-games-item-img {
    min-height: 165px;
  }
  .promo__big-slider .owl-dots {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 0;
  }
  .promo__big-slider .owl-dot {
    margin-right: 20px;
  }
}



@media @small-widths {
  .promo__big-slide-img {
    width: 220px;
  }
  .promo__big-slide-title-text {
    font-size: 75px;
  }

  .promo__big-slide-title-img {
    width: 90px;
  }
  .promo__big-slide-descr {
    font-size: 34px;
  }
  .promo__big-slide-phrase {
    margin-bottom: 25px;
    font-size: 34px;
  }
  .promo__top-games-items {
    flex-wrap: wrap;
    justify-content: center;
  }
  .promo__top-games-item {
    flex-grow: 0;
    width: 300px;
    margin-right: 0;
    margin-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;
  }
}



@media @extraSmall-widths {
  .promo__big-slide {
    flex-direction: column-reverse;
    align-items: center;
    padding: 30px 20px;
  }
  .promo__big-slide-img {
    align-self: center;
    margin-top: 10px;
  }
  .promo__big-slider .owl-nav .owl-prev {
    left: 0px;
  }
  .promo__big-slider .owl-nav .owl-next {
    right: 0px;
  }
}



@media @exxSmall-widths {
  .promo__big-slide-img {
    width: 180px;
  }
  .promo__big-slide-title-img {
    width: 75px;
  }
  .promo__big-slide-title-text {
    font-size: 60px;
  }
  .promo__big-slide-descr {
    font-size: 26px;
  }
  .promo__big-slide-phrase {
    font-size: 26px;
  }
  .promo__top-games-item {
    padding-right: 0;
    padding-left: 0;
  }
}
