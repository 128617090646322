.page-header__logo {
  width: 220px;
  flex-shrink: 0;
}
.page-header--fixed .page-header__logo {
  width: 60px;
  margin-left: 17px;
}
.page-header__logo-img {
  display: block;
  width: 100%;
}
.page-header--fixed .page-header__logo-img {
  display: none;
}
.page-header__logo-img--fixed {
  display: none;
  width: 100%;
}
.page-header--fixed .page-header__logo-img--fixed {
  display: block;
}



@media @xxlarge-widths {
  .page-header__logo {
    width: 220px;
  }
}



@media @xlarge-widths {

}



@media @large-widths {
  .page-header__logo {
    width: 200px;
  }
}



@media @medium-widths {
  .page-header__logo {
    width: 80px;
  }
  .page-header__logo-img {
    display: none;
  }
  .page-header__logo-img--fixed {
    display: block;
  }
}



@media @small-widths {
  .page-header__logo {
    margin-left: 15px;
    margin-right: auto;
    order: -1;
  }
}



@media @extraSmall-widths {
  .page-header__logo {
    width: 60px;
  }
}



@media @exxSmall-widths {
  .page-header__logo {
    width: 50px;
  }
  .page-header--fixed .page-header__logo {
    width: 60px;
    margin-left: 17px;
  }
}
