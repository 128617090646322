.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: 0;
}
.pagination-items {
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
}
.pagination-items li {
  padding: 10px 15px;
}
.pagination-items li a {
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  transition: color .5s;
  &:hover {
    color: #ed1c24;
    text-decoration: none;
  }
}
.pagination-btn-prev {
  position: relative;
  flex-shrink: 0;
  width: 31px;
  height: 30px;
  font-size: 0;
  cursor: pointer;
  z-index: 100;
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 10px;
    top: 14px;
    width: 5px;
    height: 1px;
    transform-origin: .5px 50%;
    background-color: #ffffff;
    transition: background-color .5s, box-shadow .5s;
  }
  &::after {
    transform: rotate(50deg);
    box-shadow: 4px -4.5px 0 0 #ffffff;
  }
  &::before {
    transform: rotate(-50deg);
    box-shadow: 4px 4.5px 0 0 #ffffff;
  }
  &:hover {
    &::before,
    &::after {
      background-color: #ed1c24;
    }
    &::after {
      box-shadow: 4px -4.5px 0 0 #ed1c24;
    }
    &::before {
      box-shadow: 4px 4.5px 0 0 #ed1c24;
    }
  }
}
.pagination-btn-next {
  position: relative;
  flex-shrink: 0;
  width: 31px;
  height: 30px;
  font-size: 0;
  cursor: pointer;
  z-index: 100;
  &::before,
  &::after {
    content: "";
    position: absolute;
    right: 10px;
    top: 14px;
    width: 5px;
    height: 1px;
    transform-origin: 4.5px 50%;
    background-color: #ffffff;
    transition: background-color .5s, box-shadow .5s;
  }
  &::before {
    transform: rotate(50deg);
    box-shadow: -4px 4.5px 0 0 #ffffff;
  }
  &::after {
    transform: rotate(-50deg);
    box-shadow: -4px -4.5px 0 0 #ffffff;
  }
  &:hover {
    &::before,
    &::after {
      background-color: #ed1c24;
    }
    &::before {
      box-shadow: -4px 4.5px 0 0 #ed1c24;
    }
    &::after {
      box-shadow: -4px -4.5px 0 0 #ed1c24;
    }
  }
}



@media @extraSmall-widths {
  .pagination-items li {
    padding: 10px;
  }
}



@media @exxSmall-widths {
  .pagination-items {
    margin: 0 5px;
  }
  .pagination-items li {
    padding: 10px 8px;
  }
}
