.store-trove-wrapper {
  background-image: url("../img/store/store-trove-img-bg.jpg");
  background-position: center;
  background-size: cover;
}
.store-trove {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  max-width: 1500px;
  margin: 0 auto;
  padding: 15px 30px;
}
.store-trove-img {
  flex-shrink: 0;
  width: 550px;
}
.store-trove-img img {
  display: block;
  width: 100%;
}
.store-trove-content {
  box-sizing: border-box;
  max-width: 830px;
  padding-left: 30px;
  margin-left: auto;
}
.store-trove-content-descr {
  margin-bottom: 10px;
  font-size: 48px;
  font-weight: bold;
  color: #ed1c24;
  text-transform: uppercase;
}
.store-trove-content-phrase {
  margin-bottom: 25px;
  font-size: 60px;
  font-weight: bold;
  line-height: 1;
  color: #f4f3f3;
  text-transform: uppercase;
}
.store-trove-content-btn-wrapper {

}
.store-trove-content-btn {
  .btn();
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}



@media @xlarge-widths {
  .store-trove-img {
    width: 500px;
  }
  .store-trove-content {
    max-width: 700px;
  }
  .store-trove-content-descr {
    font-size: 40px;
  }
  .store-trove-content-phrase {
    font-size: 50px;
  }
}



@media @large-widths {
  .store-trove-img {
    width: 400px;
  }
}



@media @medium-widths {
  .store-trove-img {
    width: 350px;
  }
  .store-trove-content {
    max-width: 560px;
  }
  .store-trove-content-descr {
    font-size: 32px;
  }
  .store-trove-content-phrase {
    font-size: 40px;
  }
}



@media @small-widths {
  .store-trove {
    flex-direction: column-reverse;
    padding-bottom: 5px;
  }
  .store-trove-content {
    margin-left: 0;
    margin-bottom: 20px;
    padding-left: 0;
    text-align: center;
  }
}



@media @extraSmall-widths {
  .store-trove-img {
    width: 300px;
  }
  .store-trove-content {
    max-width: 420px;
  }
  .store-trove-content-descr {
    font-size: 26px;
  }
  .store-trove-content-phrase {
    font-size: 30px;
  }
}



@media @exxSmall-widths {
  .store-trove-img {
    width: 260px;
  }
  .store-trove-content {
    max-width: 360px;
  }
  .store-trove-content-descr {
    font-size: 22px;
  }
  .store-trove-content-phrase {
    font-size: 26px;
  }
}
