.giveaways-single {
  background-color: #100f0f;
  border-bottom: 10px solid #212121;
  padding-bottom: 55px;
}
.giveaways-single-promo-wrapper {
  background-image: url("../img/giveaways/giveaways-single-promo-img-bg.jpg");
  background-position: center;
  background-size: cover;
}
.giveaways-single-promo {
  padding-top: 25px;
  padding-bottom: 50px;
}
.giveaways-single-promo-header {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}
.giveaways-single-promo-header-name {
  display: flex;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
}
.giveaways-single-promo-header-name-icon {
  width: 32px;
  margin-right: 25px;
}
.giveaways-single-promo-header-name-icon img {
  display: block;
  width: 100%;
}
.giveaways-single-promo-header-name-num {
  margin-right: 20px;
  color: #dc291e;
}
.giveaways-single-promo-header-name-text {

}
.giveaways-single-promo-header-btn-wrapper {
  margin-left: auto;
}
.giveaways-single-promo-header-btn {
  .btn();
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}
.giveaways-single-promo-inner-wrapper {
  display: flex;
}
.giveaways-single-promo-img {
  flex-shrink: 0;
  width: 655px;
}
.giveaways-single-promo-img-wrapper {

}
.giveaways-single-promo-img-wrapper img {
  display: block;
  width: 100%;
}
.giveaways-single-promo-img-footer {
  display: flex;
  margin-top: 22px;
}
.giveaways-single-promo-img-footer-socials {
  display: flex;
  align-items: center;
}
.giveaways-single-promo-img-footer-socials-descr {
  margin-right: 25px;
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.giveaways-single-promo-img-footer-socials-items {
  display: flex;
  align-items: center;
}
.giveaways-single-promo-img-footer-socials-item {
  position: relative;
  display: flex;
  align-items: center;
  width: 14px;
  height: 16px;
  margin-right: 18px;
}
.giveaways-single-promo-img-footer-socials-item:last-child {
  margin-right: 0;
}
.giveaways-single-promo-img-footer-socials-item .img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: opacity .5s;
}
.giveaways-single-promo-img-footer-socials-item .img-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity .5s;
}
.giveaways-single-promo-img-footer-socials-item:hover .img {
  opacity: 0;
}
.giveaways-single-promo-img-footer-socials-item:hover .img-hover {
  opacity: 1;
}
.giveaways-single-promo-img-footer-comments {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.giveaways-single-promo-img-footer-comments-icon {
  width: 12px;
  margin-right: 7px;
}
.giveaways-single-promo-img-footer-comments-icon img {
  display: block;
  width: 100%;
}
.giveaways-single-promo-img-footer-comments-num {

}
.giveaways-single-promo-content {
  box-sizing: border-box;
  width: ~"calc(100% - 655px)";
  padding-left: 110px;
}
.giveaways-single-promo-content-items {
  display: flex;
  margin-bottom: auto;
  padding-bottom: 30px;
  border-bottom: 1px solid #4d4d4d;
}
.giveaways-single-promo-content-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  margin-right: auto;
}
.giveaways-single-promo-content-item:last-child {
  margin-right: 0;
}
.giveaways-single-promo-content-item-title {
  padding-bottom: 30px;
  font-size: 16px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
}
.giveaways-single-promo-content-item-icon {
  margin-top: auto;
  margin-bottom: 30px;
  font-family: "Stadium 1946", sans-serif;
  font-size: 50px;
  font-weight: normal;
  color: #f4f3f3;
}
.giveaways-single-promo-content-item-icon img {
  display: block;
  width: 100%;
}
.giveaways-single-promo-content-item-descr {
  font-size: 16px;
  font-weight: bold;
  color: #dc291e;
  text-transform: uppercase;
  text-align: center;
}
.giveaways-single-promo-content-items .owl-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 0;
}
.giveaways-single-promo-content-items .owl-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 25px;
  background-color: #ffffff;
  border-radius: 50%;
}
.giveaways-single-promo-content-items .owl-dot:last-child {
  margin-right: 0;
}
.giveaways-single-promo-content-items .owl-dot.active {
  background-color: #dc291e;
}
.store-header-info {
  flex-shrink: 0;
  max-width: 200px;
  font-family: "Stadium 1946", sans-serif;
  font-size: 24px;
  font-weight: normal;
  line-height: 1;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 4px 5px 0 #000000;
}
.giveaways-single-promo-content-info {
  display: flex;
  align-items: center;
  padding-top: 30px;
}
.giveaways-single-promo-content-info-time-left {
  padding-right: 30px;
}
.giveaways-single-promo-content-info-time-left-title {
  font-size: 12px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
}
.giveaways-single-promo-content-info-time-left-inner-block {
  display: flex;
  align-items: baseline;
}
.giveaways-single-promo-content-info-time-left-weeks {
  margin-right: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
}
.giveaways-single-promo-content-info-time-left-weeks span {
  margin-right: 3px;
  font-family: "Stadium 1946", sans-serif;
  font-size: 50px;
  font-weight: normal;
  color: #dc291e;
}
.giveaways-single-promo-content-info-time-left-days {
  margin-right: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
}
.giveaways-single-promo-content-info-time-left-days span {
  margin-right: 3px;
  font-family: "Stadium 1946", sans-serif;
  font-size: 50px;
  font-weight: normal;
  color: #dc291e;
}
.giveaways-single-promo-content-info-time-left-time {
  font-size: 35px;
  font-weight: bold;
  color: #f4f3f3;
}
.giveaways-single-promo-content-info-points {
  margin: 0 auto;
}
.giveaways-single-promo-content-info-points-descr {
  margin-bottom: 15px;
  font-size: 12px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
}
.giveaways-single-promo-content-info-points-btn-wrapper {
  text-align: center;
}
.giveaways-single-promo-content-info-points-btn {
  .btn();
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}
.giveaways-single-promo-content-info-phrase {
  width: 140px;
  padding-left: 30px;
  font-family: "Stadium 1946", sans-serif;
  font-size: 45px;
  font-weight: normal;
  color: #dc291e;
  text-align: center;
  text-shadow: 4px 6px 0 #000000;
}



@media @xxlarge-widths {
  .giveaways-single-promo-img {
    width: 600px;
  }
  .giveaways-single-promo-content {
    width: ~"calc(100% - 600px)";
    padding-left: 50px;
  }
  .giveaways-single-promo-content-item-title {
    padding-bottom: 25px;
  }
  .giveaways-single-promo-content-item-icon {
    margin-bottom: 25px;
  }
  .giveaways-single-promo-content-items {
    padding-bottom: 25px;
  }
  .giveaways-single-promo-content-info {
    padding-top: 25px;
  }
}



@media @xlarge-widths {
  .giveaways-single-promo-img {
    width: 520px;
  }
  .giveaways-single-promo-content {
    width: ~"calc(100% - 520px)";
  }
  .giveaways-single-promo-content-items {
    padding-bottom: 40px;
  }
  .giveaways-single-promo-content-item {
    width: auto;
    min-height: 170px;
  }
  .giveaways-single-promo-content-item:last-child {
    margin-right: auto;
    margin-left: auto;
  }
  .giveaways-single-promo-content-info-time-left {
    width: 240px;
  }
  .giveaways-single-promo-content-info-time-left-inner-block {
    flex-wrap: wrap;
  }
}



@media @large-widths {
  .giveaways-single-promo-header-name {
    font-size: 28px;
  }
  .giveaways-single-promo-content-item {
    min-height: 160px;
  }
  .giveaways-single-promo-content-item-title {
    padding-bottom: 20px;
    font-size: 15px;
  }
  .giveaways-single-promo-content-item-icon {
    margin-bottom: 20px;
    font-size: 42px;
  }
  .giveaways-single-promo-content-item-descr {
    font-size: 15px;
  }
  .giveaways-single-promo-content-info-time-left-weeks,
  .giveaways-single-promo-content-info-time-left-days {
    font-size: 15px;
  }
  .giveaways-single-promo-content-info-time-left-weeks span,
  .giveaways-single-promo-content-info-time-left-days span {
    font-size: 42px;
  }
  .giveaways-single-promo-content-info-time-left-time {
    font-size: 30px;
  }
  .giveaways-single-promo-content-info-phrase {
    font-size: 40px;
  }
}



@media @medium-widths {
  .giveaways-single-promo-inner-wrapper {
    display: block;
    max-width: 700px;
    margin: 0 auto;
  }
  .giveaways-single-promo-img {
    width: 100%;
    margin-bottom: 30px;
  }
  .giveaways-single-promo-content {
    width: 100%;
    padding-left: 0;
  }
}



@media @small-widths {
  .giveaways-single-promo-header-name {
    font-size: 26px;
  }
  .giveaways-single-promo-content-item-title {
    font-size: 14px;
  }
  .giveaways-single-promo-content-item-icon {
    font-size: 36px;
  }
  .giveaways-single-promo-content-item-descr {
    font-size: 14px;
  }
  .giveaways-single-promo-content-info-time-left {
    width: 200px;
  }
  .giveaways-single-promo-content-info-time-left-weeks,
  .giveaways-single-promo-content-info-time-left-days {
    font-size: 14px;
  }
  .giveaways-single-promo-content-info-time-left-weeks span,
  .giveaways-single-promo-content-info-time-left-days span {
    font-size: 36px;
  }
  .giveaways-single-promo-content-info-time-left-time {
    font-size: 28px;
  }
  .giveaways-single-promo-content-info-phrase {
    font-size: 36px;
  }
}



@media @extraSmall-widths {
  .giveaways-single-promo-header {
    display: block;
  }
  .giveaways-single-promo-header-name {
    justify-content: center;
    margin-bottom: 15px;
    font-size: 22px;
  }
  .giveaways-single-promo-header-btn-wrapper {
    text-align: center;
  }
}



@media (max-width: 580px) {
  .giveaways-single-promo-content-info {
    display: block;
    padding-left: 15px;
    padding-right: 15px;
  }
  .giveaways-single-promo-content-info-time-left {
    width: 100%;
    margin-bottom: 15px;
    padding-right: 0;
  }
  .giveaways-single-promo-content-info-time-left-title {
    text-align: center;
  }
  .giveaways-single-promo-content-info-time-left-inner-block {
    justify-content: center;
  }
  .giveaways-single-promo-content-info-points {
    margin-bottom: 10px;
  }
  .giveaways-single-promo-content-info-phrase {
    width: 100%;
    padding-left: 0;
    text-align: center;
  }
}



// @media @exxSmall-widths {
//
// }
