.form {

}
.form-input {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 5px;
}
.form-input input,
.form-textarea textarea {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 12px 28px;
  background: none;
  border: 1px solid rgba(244, 243, 243, .5);
  font-size: 14px;
  font-weight: normal;
  color: #f4f3f3;
  &:focus {
    outline: none;
  }
}
.form-textarea textarea {
  height: 154px;
  resize: none;
}
.form-input input::-webkit-input-placeholder,
.form-textarea textarea::-webkit-input-placeholder {
  color: rgba(244, 243, 243, .5);
  text-transform: uppercase;
}
.form-input input::-moz-placeholder,
.form-textarea textarea::-moz-placeholder {
  color: rgba(244, 243, 243, .5);
  text-transform: uppercase;
}
.form-input input:-moz-placeholder,
.form-textarea textarea:-moz-placeholder {
  color: rgba(244, 243, 243, .5);
  text-transform: uppercase;
}
.form-input input:-ms-input-placeholder,
.form-textarea textarea:-ms-input-placeholder {
  color: rgba(244, 243, 243, .5);
  text-transform: uppercase;
}
.input-select {
  position: relative;
  cursor: pointer;
}
.input-select input {
  padding-right: 52px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.input-select-toggle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 52px;
  font-size: 0;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 17px;
    right: 25px;
    height: 9px;
    width: 2px;
    background-color: #f4f3f3;
    transform-origin: 50% 8px;
    transition: top .5s, transform .5s;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}
.input-select--open .input-select-toggle {
  &::before,
  &::after {
    top: 10px;
  }
  &::before {
    transform: rotate(135deg);
  }
  &::after {
    transform: rotate(-135deg);
  }
}
.input-select-items-wrapper {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 0;
  overflow: hidden;
  z-index: 200;
}
.input-select--open .input-select-items-wrapper {
  height: auto;
}
.input-select-items {
  margin: 0;
  padding: 10px 0;
  list-style: none;
  background-color: @color-text-hover;
  transform: translate(0, -100%);
  transition: transform .5s;
  font-size: 14px;
  font-weight: normal;
  color: @color-text;
  text-transform: uppercase;
}
.input-select--open .input-select-items {
  transform: translate(0, 0);
}
.input-select-item {
  padding: 10px 15px;
  transition: background-color .3s;
  &:hover {
    background-color: rgb(204, 137, 122);
  }
}
.form-input-file-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0;
}
.form-input-file {
  position: relative;
  flex-shrink: 0;
  width: 21px;
  height: 18px;
  margin-right: 14px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity .5s;
    z-index: 10;
  }
  &::before {
    background-image: url("../img/faq&contact/icon-load.png");
    opacity: 1;
  }
  &::after {
    background-image: url("../img/faq&contact/icon-load-hover.png");
    opacity: 0;
  }
  &:hover {
    &::before {
      opacity: 0;
    }
    &::after {
      opacity: 1;
    }
  }
}
.form-input-file input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 20;
}
.form-captcha {
  box-sizing: border-box;
  width: 302px;
  margin: 0 auto;
  margin-bottom: 25px;
}
.form-captcha img {
  display: block;
  width: 100%;
}
.form-btn {
  text-align: center;
}
.form-btn input {
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}




//@media @medium-widths {
//  .form-input input,
//  .form-input textarea {
//    padding: 15px 15px;
//  }
//  .input-select-toggle {
//    &::before,
//    &::after {
//      top: 17px;
//    }
//  }
//  .input-select--open .input-select-toggle {
//    &::before,
//    &::after {
//      top: 6px;
//    }
//  }
//}
//
//
//
//@media @small-widths {
//  .form-input input,
//  .form-input textarea {
//    padding: 12px 15px;
//  }
//  .input-select-toggle {
//    &::before,
//    &::after {
//      top: 14px;
//    }
//  }
//  .input-select--open .input-select-toggle {
//    &::before,
//    &::after {
//      top: 4px;
//    }
//  }
//}
//
//@media (max-width: 619px) {
//  .form-block {
//    display: block;
//  }
//  .form-block > .form-input,
//  .form-block > .form-input-wrapper {
//    width: 100%;
//    margin-right: 0;
//  }
//  .form-block .form-input-btn input,
//  .form-input-btn input {
//    width: 200px;
//    min-width: 200px;
//  }
//  .calculator-form-finance .form-input-btn {
//    text-align: left;
//  }
//}
//
//
//
//@media @exxSmall-widths {
//  .form-input input,
//  .form-input textarea {
//    font-size: 13px;
//  }
//}
//
//
//
//@media (max-width: 419px) {
//  .calculator-form-invest .form-input-btn {
//    text-align: left;
//  }
//}
