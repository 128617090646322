.corner-box {
  position: relative;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: ~"calc((100% - 17px) / 2)";
    height: 2px;
    background-color: #dc291e;
  }
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
}
.corner {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 35px;
  height: 11px;
  background-image: url("../img/corner-down.png");
  background-repeat: no-repeat;
  background-position: center;
}
