.privacy-wrapper {
  background-color: #2b2a2a;
  border-bottom: 5px solid #212121;
}
.privacy {
  padding: 20px 0;
}
.privacy-block {
  padding: 25px;
  &:nth-child(2n) {
    background-color: #3a3a3a;
    border-bottom: 2px solid #686868;
  }
}
.privacy-block-title {
  margin-bottom: 30px;
}
.privacy-block-text {
  text-align: justify;

  p {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.privacy-block-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.privacy-block-list li {
  position: relative;
  margin-bottom: 5px;
  padding-left: 24px;
  text-align: justify;
  &:last-child {
    margin-bottom: 0;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 4px;
    width: 8px;
    height: 8px;
    background-color: @color-text-hover;
    border-radius: 50%;
  }
}
