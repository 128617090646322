.main-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: @color-text;
}
@media @large-widths {
  .main-text {
    font-size: 13px;
  }
}
@media @small-widths {
  .main-text {
    font-size: 12px;
  }
}



.title-block {
  padding: 12px 15px;
  font-size: 25px;
  font-weight: bold;
  color: @color-text;
  text-transform: uppercase;
  text-align: center;
}
@media @medium-widths {
  .title-block {
    font-size: 22px;
  }
}
@media @exxSmall-widths {
  .title-block {
    font-size: 20px;
  }
}



.title-small {
  font-size: 20px;
  font-weight: normal;
  color: @color-text;
  text-transform: uppercase;
}
@media @medium-widths {
  .title-small {
    font-size: 18px;
  }
}
@media @small-widths {
  .title-small {
    font-size: 16px;
  }
}
@media @extraSmall-widths {
  .title-small {
    font-size: 14px;
  }
}
@media @exxSmall-widths {
  .title-small {
    font-size: 13px;
  }
}
