.block-title() {
  font-size: 40px;
  font-weight: bold;
  color: #f4f3f3;
  text-align: center;
  text-transform: uppercase;

  @media @small-widths {
    font-size: 35px;
  }
  @media @extraSmall-widths {
    font-size: 30px;
  }
}



.btn() {
  box-sizing: border-box;
  display: inline-block;
  min-width: 165px;
  padding: 9px 15px;
  background-color: transparent;
  border: 2px solid #dc291e;
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  transition: background-color .5s, border .5s, color .5s;
  &:hover {
    background-color: #dc291e;
    color: #f4f3f3;
    text-decoration: none;
  }
  &:focus {
    color: #f4f3f3;
    outline: none;
    text-decoration: none;
  }

  // @media @medium-widths {
  //   min-width: 200px;
  //   padding: 5px 10px;
  //   font-size: 18px;
  //   line-height: 24px;
  // }
  // @media @small-widths {
  //   min-width: 180px;
  //   padding: 4px 10px;
  //   font-size: 16px;
  //   line-height: 22px;
  // }
}



// .btn-hover() {
//   box-sizing: border-box;
//   width: 75%;
//   padding: 7px 20px;
//   border: 3px solid #1c1f23;
//   font-size: 20px;
//   line-height: 26px;
//   font-weight: bold;
//   text-align: center;
//   text-transform: uppercase;
//   color: #1c1f23;
//   text-decoration: none;
//   transition: all .3s;
//   &:focus {
//     color: #1c1f23;
//     text-decoration: none;
//     outline: none;
//   }
//   &:hover,
//   &:active {
//     background-color: #1c1f23;
//     color: #ebce7b;
//     text-decoration: none;
//   }

//   @media @medium-widths {
//     padding: 5px 15px;
//     font-size: 18px;
//     line-height: 24px;
//   }
//   @media @small-widths {
//     padding: 4px 10px;
//     font-size: 16px;
//     line-height: 22px;
//   }
// }
