.container {
  box-sizing: border-box;
  width: 1670px;
  padding: 0 15px;
  margin: 0 auto;
}
.container-left {
  box-sizing: border-box;
  width: 640px;
  padding-left: 15px;
  padding-right: 55px;
  margin-left: auto;
}

@media @xxlarge-widths {
  .container {
    width: 1440px;
  }
}
@media @xlarge-widths {
  .container {
    width: 1270px;
  }
}
@media @large-widths {
  .container {
    width: 1170px;
  }
  .container-left {
    width: 585px;
    padding-right: 40px;
  }
}
@media @medium-widths {
  .container {
    width: 970px;
  }
  .container-left {
    width: 485px;
    padding-right: 30px;
  }
}
@media @small-widths {
  .container {
    width: 750px;
  }
  .container-left {
    width: 375px;
    padding-right: 25px;
  }
}
@media @extraSmall-widths {
  .container {
    width: 100%;
  }
  .container-left {
    width: 100%;
    padding-right: 15px;
  }
}
