.privacy-promo-wrapper {
  padding-top: 20px;
  padding-bottom: 35px;
  background-image: url("../img/privacy/privacy-promo-bg.jpg");
  background-position: center;
  background-size: cover;
}
.privacy-promo {
  max-width: 1110px;
  margin: 0 auto;
}
.privacy-promo-title {
  margin-bottom: 20px;
}
.privacy-promo-title span {
  color: @color-text-hover;
}
.privacy-promo-descr {
  margin-bottom: 15px;
  text-align: center;
}
.privacy-promo-text {
  text-align: center;
}
.privacy-promo-text span {
  color: @color-text-hover;
}
