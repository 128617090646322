.articles-page-wrapper {
  background-color: #212121;
}
.articles-page {
  padding-top: 35px;
}
.articles-page-title {
  margin-bottom: 25px;
  font-size: 30px;
  font-weight: bold;
  color: #dc291e;
  text-transform: uppercase;
  text-align: center;
}
.articles-page-inner-wrapper {
  box-sizing: border-box;
  display: flex;
  max-width: 1700px;
  margin: 0 auto;
  padding: 0 30px;
}
.articles-page-items-wrapper {
  flex-grow: 1 ;
}
.articles-page-items {

}
.articles-page-item {
  display: flex;
  margin-bottom: 16px;
  padding: 9px 12px;
  background-color: #2b2a2a;
}
.articles-page-item:last-child {
  margin-bottom: 0;
}
.articles-page-item-img {
  box-sizing: border-box;
  width: 48%;
}
.articles-page-item-img img {
  display: block;
  width: 100%;
}
.articles-page-item-content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 52%;
  padding-left: 45px;
  padding-right: 33px;
  padding-bottom: 15px;
}
.articles-page-item-content-title {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  transition: color .5s;
  &:hover {
    color: #dc291e;
    text-decoration: none;
  }
}
.articles-page-item-content-text {
  padding-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: justify;
}
.articles-page-item-content-text p {
  margin: 0;
}
.articles-page-item-content-footer {
  display: flex;
  align-items: center;
  margin-top: auto;
}
.articles-page-item-content-footer-info {

}
.articles-page-item-content-footer-info-author {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.articles-page-item-content-footer-info-author img {
  width: 12px;
  margin-right: 7px;
}
.articles-page-item-content-footer-info-author span {

}
.articles-page-item-content-footer-info-block {
  display: flex;
  align-items: center;
}
.articles-page-item-content-footer-info-date {
  display: flex;
  align-items: center;
  margin-right: 15px;
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.articles-page-item-content-footer-info-date img {
  width: 10px;
  margin-right: 7px;
}
.articles-page-item-content-footer-info-comments {
  display: flex;
  align-items: center;
  margin-right: 15px;
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.articles-page-item-content-footer-info-comments img {
  width: 12px;
  margin-right: 7px;
}
.articles-page-item-content-footer-info-like {
  display: flex;
  align-items: center;
  margin-right: 7px;
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  cursor: pointer;
  transition: color .5s;
  &:hover {
    color: #dc291e;
  }
}
.articles-page-item-content-footer-info-like-icon {
  position: relative;
  width: 15px;
  height: 14px;
  margin-right: 7px;
}
.articles-page-item-content-footer-info-like-icon img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity .5s;
}
.articles-page-item-content-footer-info-like-icon .img {
  opacity: 1;
}
.articles-page-item-content-footer-info-like-icon .img-hover {
  opacity: 0;
}
.articles-page-item-content-footer-info-like:hover .img {
  opacity: 0;
}
.articles-page-item-content-footer-info-like:hover .img-hover {
  opacity: 1;
}
.articles-page-item-content-footer-btn-wrapper {
  margin-left: auto;
}
.articles-page-item-content-footer-btn {
  .btn();
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}
.articles-page-sidebar {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 420px;
  padding: 25px 0;
  padding-left: 45px;
}
.articles-page-sidebar-block {
  margin-bottom: 50px;
}
.articles-page-sidebar-block:last-child {
  margin-bottom: 0;
}
.articles-page-sidebar-categories {

}
.articles-page-sidebar-block-title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.articles-page-sidebar-category-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.articles-page-sidebar-category-item {
  position: relative;
  margin-bottom: 7px;
  padding: 7px;
  border-bottom: 1px solid #3e3e3e;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 15px;
    width: 3px;
    height: 3px;
    background-color: #f4f3f3;
    border-radius: 50%;
    transition: background-color .5s;
  }
  &:hover {
    &::before {
      background-color: #dc291e;
    }
  }
}
.articles-page-sidebar-category-item a {
  font-size: 15px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  transition: color .5s;
  &:hover {
    color: #dc291e;
    text-decoration: none;
  }
  &:focus{
    color: #f4f3f3;
    text-decoration: none;
  }
}
.articles-page-sidebar-popular {

}
.articles-page-sidebar-popular-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.articles-page-sidebar-popular-item {
  border-bottom: 1px solid #3e3e3e;
  padding: 12px 0;
}
.articles-page-sidebar-popular-item-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.articles-page-sidebar-popular-item-header-date {
  display: flex;
  align-items: center;
  margin-right: 12px;
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.articles-page-sidebar-popular-item-header-date img {
  width: 10px;
  margin-right: 7px;
}
.articles-page-sidebar-popular-item-header-comments {
  display: flex;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.articles-page-sidebar-popular-item-header-comments img {
  width: 12px;
  margin-right: 7px;
}
.articles-page-sidebar-popular-item-name {

}
.articles-page-sidebar-popular-item-name a {
  font-size: 15px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
  &:hover {
    color: #dc291e;
    text-decoration: none;
  }
  &:focus{
    color: #f4f3f3;
    text-decoration: none;
  }
}
.articles-page-sidebar-comments {

}
.articles-page-sidebar-comments-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.articles-page-sidebar-comments-item {
  padding: 12px 0;
  border-bottom: 1px solid #3e3e3e;
}
.articles-page-sidebar-comments-item:last-child {
  margin-bottom: 0;
}
.articles-page-sidebar-comments-item-nick {
  margin-bottom: 7px;
  font-size: 16px;
  font-weight: bold;
  color: #f4f3f3;
}
.articles-page-sidebar-comments-item-text {
  margin-bottom: 7px;
  font-size: 14px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: justify;
}
.articles-page-sidebar-comments-item-date {
  font-size: 12px;
  font-weight: normal;
  color: #dc291e;
  text-transform: uppercase;
}



@media @xlarge-widths {
  .articles-page-item-img {
    width: 45%;
  }
  .articles-page-item-content {
    width: 55%;
    padding-left: 30px;
    padding-right: 20px;
  }
  .articles-page-sidebar {
    width: 350px;
    padding-left: 30px;
  }
}



@media @large-widths {
  .articles-page-sidebar {
    width: 300px;
  }
  .articles-page-item-content-text,
  .articles-page-sidebar-comments-item-text {
    font-size: 13px;
  }
}



@media @medium-widths {
  .articles-page-item {
    flex-direction: column;
  }
  .articles-page-item-img {
    width: 360px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .articles-page-item-content {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
  .articles-page-item-content-title {
    text-align: center;
  }
}



@media @small-widths {
  .articles-page-title {
    font-size: 28px;
  }
  .articles-page-inner-wrapper {
    display: block;
  }
  .articles-page-item-content-title {
    font-size: 18px;
  }
  .articles-page-sidebar {
    width: 100%;
    padding: 25px;
  }
  .articles-page-sidebar-block-title {
    font-size: 18px;
  }
  .articles-page-sidebar-category-item a,
  .articles-page-sidebar-popular-item-name a {
    font-size: 14px;
  }
  .articles-page-sidebar-categories {
    max-width: 400px;
  }
  .articles-page-sidebar-popular {
    max-width: 400px;
  }
  .articles-page-item-content-text,
  .articles-page-sidebar-comments-item-text {
    font-size: 12px;
  }
  .product-sidebar-categiries.articles-page-sidebar-tags {
    width: 100%;
    padding-left: 0;
  }
}



@media @extraSmall-widths {
  .articles-page-title {
    font-size: 26px;
  }
  .articles-page-inner-wrapper {
    padding: 0 15px;
  }
  .articles-page-item-content {
    padding-left: 15px;
    padding-right: 15px;
  }
  .articles-page-item-img {
    width: 300px;
  }
  .articles-page-item-content-title {
    font-size: 16px;
  }
  .articles-page-sidebar-block-title {
    font-size: 16px;
  }
  .articles-page-sidebar-category-item a,
  .articles-page-sidebar-popular-item-name a {
    font-size: 13px;
  }
}



@media @exxSmall-widths {
  .articles-page-title {
    font-size: 24px;
  }
  .articles-page-item-img {
    width: 220px;
  }
  .articles-page-item-content {
    padding-left: 5px;
    padding-right: 5px;
  }
  .articles-page-item-content-footer {
    flex-direction: column;
    align-items: center;
  }
  .articles-page-item-content-footer-info {
    margin-bottom: 15px;
  }
  .articles-page-item-content-footer-info-author {
    justify-content: center;
  }
  .articles-page-item-content-footer-info-block {
    justify-content: center;
  }
  .articles-page-item-content-footer-btn-wrapper {
    margin-left: 0;
  }
  .articles-page-sidebar {
    padding: 15px;
  }
}
