.about-game-tab-pane {

}
.about-game {

}
.about-game-descr {
  margin-bottom: 30px;
}
.about-game-descr-title-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.about-game-descr-title {
  font-size: 20px;
  font-weight: normal;
  color: #e4e4e4;
  text-transform: uppercase;
}
.about-game-rating {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: 20px;
}
.about-game-rating-descr {
  margin-right: 20px;
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.about-game-rating-items {
  display: flex;
  align-items: center;
}
.about-game-rating-item {
  margin-right: 12px;
}
.about-game-rating-item:last-child {
  margin-right: 0;
}
.about-game-rating-item svg {
  display: block;
  width: 17px;
  height: 17px;
  fill: #151414;
}
.about-game-rating-item--active svg {
  fill: #f9e46e;
}
.about-game-descr-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #e4e4e4;
  text-align: justify;
}
.about-game-descr-text p {

}
.about-game-features {
  position: relative;
  margin-bottom: 30px;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 80px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 15px;
    background-color: #151414;
  }
}
.about-game-features-title {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: normal;
  color: #e4e4e4;
  text-transform: uppercase;
}
.about-game-features-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.about-game-features-item {
  position: relative;
  margin-bottom: 12px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #e4e4e4;
  text-align: justify;
  &::before {
    content: "";
    position: absolute;
    left: -30px;
    top: 4px;
    width: 8px;
    height: 8px;
    background-color: #ed1c24;
    border-radius: 50%;
  }
}
.about-game-features-item:last-child {
  margin-bottom: 0;
}
.about-game-system {
  margin-bottom: 40px;
}
.about-game-system-title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: normal;
  color: #e4e4e4;
  text-transform: uppercase;
}
.about-game-system-items {
  display: flex;
}
.about-game-system-item {
  max-width: 360px;
  margin-right: auto;
}
.about-game-system-item-min {
  padding-right: 20px;
}
.about-game-system-item-title {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: normal;
  color: #e4e4e4;
  text-transform: uppercase;
}
.about-game-system-item-elem {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: normal;
  color: #e4e4e4;
  text-align: justify;
}
.about-game-system-item-elem-os {
  color: #ed1c24;
}
.about-game-system-item-elem-name {

}
.about-game-system-item-elem-val {

}
.about-game-img-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 38%;
}
.about-game-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../img/product/about-game-img.jpg");
  background-position: center;
  background-size: cover;
  opacity: 0.2;
}
.about-game-btn-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-game-btn-add-to-card {
  .btn();
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 35px;
  margin-right: 100px;
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}
.about-game-btn-add-to-card-text {
  margin-right: 5px;
}
.about-game-btn-add-to-card-icon {
  display: block;
  width: 35px;
}
.about-game-btn-add-to-card-icon img {
  display: block;
  width: 100%;
}
.about-game-btn-add-to-wishlist {
  .btn();
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}
.about-game-btn-add-to-wishlist-text {
  margin-right: 8px;
}
.about-game-btn-add-to-wishlist-icon {
  position: relative;
  width: 8px;
  height: 8px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background-color: #f4f3f3;
  }
  &::before {
    left: 0;
    top: 3px;
    width: 100%;
    height: 2px;
  }
  &::after {
    left: 3px;
    top: 0;
    width: 2px;
    height: 100%;
  }
}



@media @large-widths {
  .about-game-descr-text,
  .about-game-features-item{
    font-size: 13px;
  }
}



@media @small-widths {
  .about-game-descr-text,
  .about-game-features-item{
    font-size: 12px;
  }
}



@media @extraSmall-widths {
  .about-game-descr-title-wrapper {
    display: block;
  }
  .about-game-descr-title {
    margin-bottom: 5px;
  }
  .about-game-rating {
    margin-left: 0;
    padding-left: 0;
  }

  .about-game-btn-add-to-card {
    margin-right: 40px;
  }
}



@media @exxSmall-widths {
  .about-game-rating-item {
    margin-right: 10px;
  }
  .about-game-features {
    position: relative;
    padding-left: 40px;
    &::before {
      width: 10px;
    }
  }
  .about-game-features-item {
    &::before {
      left: -20px;
    }
  }
  .about-game-system-items {
    display: block;
  }
  .about-game-img-wrapper {
    padding-bottom: 50%;
  }
  .about-game-btn-wrapper {
    flex-direction: column;
  }
  .about-game-btn-add-to-card {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
