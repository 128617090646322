.giveaways-single-video-wrapper {
  padding: 45px 0;
}
.giveaways-single-video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.2%;
}
.giveaways-single-video iframe {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}



@media @xxlarge-widths {

}



@media @xlarge-widths {

}



@media @large-widths {

}



@media @medium-widths {

}



@media @small-widths {
  .giveaways-single-video-wrapper {
    padding: 30px 0;
  }
}



@media @extraSmall-widths {

}



@media @exxSmall-widths {
  .giveaways-single-video-wrapper {
    padding: 20px 0;
  }
}
