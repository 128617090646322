.news-wrapper {
  position: relative;
  background-color: #100f0f;
}
.news {
  display: flex;
}
.group-news {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 66.67%;
  padding-top: 100px;
  padding-bottom: 70px;
  padding-right: 120px;
}
.news-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 58%;
  background-image: url("../img/group-news-bg.jpg");
  background-position: center;
  background-size: cover;
  z-index: 10;
}
.videos-wrapper {
  box-sizing: border-box;
  position: relative;
  flex-shrink: 0;
  width: 33.33%;
  padding-top: 100px;
  padding-bottom: 40px;
  padding-left: 120px;
  z-index: 20;
}
.group-news-title {
  .block-title();
  position: relative;
  margin-bottom: 40px;
  z-index: 20;
}
.group-news-items {
  position: relative;
  z-index: 20;
}
.group-news-item {
  padding: 12px;
  background-color: rgba(16, 15, 15, 0);
  transition: background-color .5s;
  &:hover {
    background-color: rgba(16, 15, 15, .6);
  }
}
.group-news-item-header {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  padding: 7px 17px;
  background-color: rgba(16, 15, 15, .9);
  transition: background-color .5s;
}
.group-news-item:hover .group-news-item-header {
  background-color: rgba(220, 41, 30, .9);
}
.group-news-item-header-logo {
  flex-shrink: 0;
  width: 30px;
  height: 26px;
  margin-right: 30px;
  background-image: url("../img/group-news-logo-red.png");
  background-position: center;
  background-size: cover;
}
.group-news-item:hover .group-news-item-header-logo {
  width: 30px;
  height: 26px;
  background-image: url("../img/group-news-logo-black.png");
  background-position: center;
  background-size: cover;
}
.group-news-item-header-name {
  margin-right: auto;
  font-size: 16px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
}
.group-news-item-header-social {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.group-news-item-header-social-descr {
  margin-right: 23px;
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.group-news-item-header-social-items {
  display: flex;
  align-items: center;
}
.group-news-item-header-social-item {
  margin-right: 18px;
}
.group-news-item-header-social-item:last-child {
  margin-right: 0;
}
.group-news-item-header-social-item img {
  display: block;
  width: 100%;
}
.group-news-item-content {
  display: flex;
}
.group-news-item-content-date {
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 62px;
  height: 62px;
  border: 1px solid #f4f3f3;
  font-size: 14px;
  font-weight: bold;
  color: #f4f3f3;
  transition: border .5s, background-color .5s;
}
.group-news-item:hover .group-news-item-content-date {
  border: 1px solid #dc291e;
  background-color: #dc291e;
}
.group-news-item-content-descr {
  padding: 0 20px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #f4f3f3;
}
.group-news-item-content-descr-title {
  margin-bottom: 15px;
}
.group-news-item-content-descr-title span {
  color: #c8261d;
}
.group-news-item-content-descr-text {
  text-align: justify;
}
.group-news-item-content-more {

}
.group-news-item-content-more-title {
  margin-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: center;
}
.group-news-item-content-more-title span {
  color: #c8261d;
}
.group-news-item-content-more-btn {
  .btn();
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}
.videos-title {
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 20px;
  background-image: url("../img/promo-top-games-title-bg.jpg");
  font-family: "Stadium 1946", sans-serif;
  font-size: 30px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: center;
  text-transform: uppercase;
}
.videos-items {

}
.videos-item {
  margin-bottom: 25px;
}
.videos-item-video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.2%;
}
.videos-item-video iframe {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.videos-item-name {
  padding: 10px 20px;
  background-color: #c8261d;
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: center;
  text-transform: uppercase;
}
.videos-btn-wrapper {
  margin-top: 20px;
  text-align: center;
}
.videos-btn {
  .btn();
}

@media (max-width: 2800px) {
  .news-bg {
    width: 60%;
  }
}



@media (max-width: 2200px) {
  .news-bg {
    width: 63%;
  }
}


@media (max-width: 1920px) {
  .news-bg {
    width: 66.67%;
  }
}



@media @xxlarge-widths {
  .group-news {
    padding-right: 100px;
  }
  .videos-wrapper {
    padding-left: 100px;
  }
}



@media @large-widths {
  .group-news {
    padding-right: 70px;
  }
  .videos-wrapper {
    padding-left: 70px;
  }
  .group-news-item-content-descr {
    font-size: 13px;
  }
}



@media @medium-widths {
  .group-news {
    padding-right: 30px;
  }
  .videos-wrapper {
    padding-left: 60px;
  }
}



@media @small-widths {
  .news-wrapper {
    overflow: hidden;
  }
  .news {
    display: block;
  }
  .group-news {
    position: relative;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .news-bg {
    width: auto;
    left: -140px;
    right: -140px;
  }
  .videos-wrapper {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    padding: 20px;
  }
  .group-news-item-content-descr {
    font-size: 12px;
  }
}



@media @extraSmall-widths {
  .news-bg {
    left: -20px;
    right: -20px;
    background-image: url("../img/group-news-bg-small.jpg");
    background-position: center top;
  }
  .group-news-item-content {
    display: block;
  }
  .group-news-item-content-date {
    margin-bottom: 15px;
  }
  .group-news-item-content-descr {
    padding: 0;
    margin-bottom: 20px;
  }
  .group-news-item-content-descr-title {
    margin-bottom: 5px;
  }
  .group-news-item-content-more-title {
    text-align: left;
    margin-bottom: 15px;
  }
}



@media @exxSmall-widths {
  .videos-wrapper {
    padding: 20px 0;
  }
  .group-news {
    padding-left: 0;
    padding-right: 0;
  }
  .group-news-item-header {
    flex-wrap: wrap;
  }
  .group-news-item-header-logo {
    order: 1;
    margin-right: auto;
    margin-bottom: 10px;
  }
  .group-news-item-header-name {
    order: 3;
    width: 100%;
  }
  .group-news-item-header-social {
    order: 2;
    margin-bottom: 10px;
  }
}
