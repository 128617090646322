.trove-wrapper {
  // display: flex;
  // flex-direction: column;
  // min-height: 100vh;
}
.trove {
  box-sizing: border-box;
  // flex-grow: 1;
  // display: flex;
  // align-items: center;
  width: 100%;
  padding-top: 105px;
  padding-bottom: 35px;
  background-image: url("../img/trove-bg.jpg");
  background-position: center;
  background-size: cover;
}
.trove-title {
  .block-title();
  margin-bottom: 40px;
}
.trove-descr {
  margin-bottom: 40px;
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: center;
  text-transform: uppercase;
}
.trove-items {
  display: flex;
  flex-wrap: wrap;
}
.trove-item {
  box-sizing: border-box;
  width: ~"calc((100% - 36px) / 4)";
  margin-right: 12px;
  margin-bottom: 12px;
}
@media (min-width: 1320px) {
  .trove-item:nth-child(4n) {
    margin-right: 0;
  }
}
.trove-item-header {
  display: flex;
  align-items: center;
  padding: 7px 12px;
  background-color: rgba(220, 41, 30, .6);
  transition: background-color .5s;
}
.trove-item:hover .trove-item-header {
  background-color: rgba(220, 41, 30, .8);
}
.trove-item-header-steam-icon {
  width: 26px;
  height: 24px;
  background-image: url("../img/icon-steam-white.png");
  background-repeat: no-repeat;
  background-position: center;
}
.trove-item:hover .trove-item-header-steam-icon {
  background-image: url("../img/icon-steam-black.png");
}
.trove-item-header-points {
  margin-left: auto;
  padding-left: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
}
.trove-item-img-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 45%;
}
.trove-item-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-size: cover;
}
.trove-item-img-btn-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: rgba(220, 41, 30, .85);
  opacity: 0;
  transition: opacity .5s;
}
.trove-item:hover .trove-item-img-btn-wrapper {
  opacity: 1;
}
.trove-item-img-btn-view {
  .btn();
  width: 165px;
  min-width: 0;
  margin-right: 12px;
  border: 2px solid #f4f3f3;
  color: #f4f3f3;
  &:hover {
    border: 2px solid #100f0f;
    color: #f4f3f3;
    background-color: #100f0f;
  }
}
.trove-item-img-btn-buy {
  .btn();
  display: flex;
  align-items: center;
  justify-content: center;
  width: 165px;
  min-width: 0;
  padding: 7px 15px;
  padding-bottom: 6px;
  border: 2px solid #f4f3f3;
  color: #f4f3f3;
  &:hover {
    border: 2px solid #100f0f;
    color: #f4f3f3;
    background-color: #100f0f;
  }
}
.trove-item-img-btn-buy-icon {
  width: 20px;
  height: 18px;
  margin-left: 7px;
  background-image: url("../img/icon-pig.png");
  background-repeat: no-repeat;
  background-position: center;
}
.trove-item-footer {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 12px;
  background-color: #100f0f;
  border-left: 1px solid #571d1a;
  border-right: 1px solid #571d1a;
  border-bottom: 1px solid #571d1a;
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  transition: background-color .5s, color .5s;
}
.trove-item:hover .trove-item-footer {
  background-color: #f4f3f3;
  color: #dc291e;
}
.trove-item-footer-name {
  margin-right: auto;
}
.trove-item-footer-comments {
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.trove-item-footer-comments-icon {
  position: relative;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: center;
    transition: opacity .5s;
  }
  &::before {
    background-image: url("../img/icon-comments-red.png");
    opacity: 1;
  }
  &::after {
    background-image: url("../img/icon-comments-black.png");
    opacity: 0;
  }
}
.trove-item:hover .trove-item-footer-comments-icon {
  &::before {
    opacity: 0;
  }
  &::after {
    opacity: 1;
  }
}
.trove-join-wrapper {
  // flex-grow: 1;
  display: flex;
  align-items: center;
  background-image: url("../img/trove-join-bg.jpg");
  background-position: center;
  background-size: cover;
}
.trove-join {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}
.trove-join-img {
  flex-shrink: 0;
  width: 145px;
}
.trove-join-img img {
  display: block;
  width: 100%;
}
.trove-join-content {
  box-sizing: border-box;
  max-width: 535px;
  padding-left: 35px;
}
.trove-join-content-title {
  margin-bottom: 15px;
  font-size: 48px;
  font-weight: bold;
  line-height: 1;
  color: #f4f3f3;
  text-transform: uppercase;
  text-shadow: 5px 4px 0 #000000;
}
.trove-join-content-btn-wrapper {

}
.trove-join-content-btn {
  .btn();
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
    background-color: #dc291e;
  }
}



@media @large-widths {
  .trove-item {
    width: ~"calc((100% - 24px) / 3)";
  }
}
@media (min-width: 992px) and (max-width: 1319px) {
  .trove-item:nth-child(3n) {
    margin-right: 0;
  }
}



@media @medium-widths {
  .trove-join-content-title {
    max-width: 450px;
    font-size: 40px;
  }
}



@media @small-widths {
  .trove-item {
    width: ~"calc((100% - 12px) / 2)";
  }
  .trove-join-content-title {
    max-width: 330px;
    font-size: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .trove-item:nth-child(2n) {
    margin-right: 0;
  }
}



@media @extraSmall-widths {
  .trove-items {
    margin-bottom: 70px;
  }
  .trove-item {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: 0px;
  }
  .trove-items .owl-dots {
    position: absolute;
    bottom: -45px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 0;
  }
  .trove-items .owl-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 25px;
    background-color: #ffffff;
    border-radius: 50%;
  }
  .trove-items .owl-dot:last-child {
    margin-right: 0;
  }
  .trove-items .owl-dot.active {
    background-color: #dc291e;
  }
  .trove-join-wrapper {
    background-position: 5%;
  }
  .trove-join {
    flex-direction: column-reverse;
    padding: 30px 0;
  }
  .trove-join-content {
    margin-bottom: 20px;
    padding-left: 0;
  }
  .trove-join-content-title {
    text-align: center;
  }
  .trove-join-content-btn-wrapper {
    text-align: center;
  }
}



@media @exxSmall-widths {
  .trove-item-header-points {
    font-size: 14px;
  }
  .trove-join-content {
    font-size: 24px;
  }
}
