.quests-hints-promo-wrapper {
  background-image: url("../img/quests/quests-faq-promo-img-bg.jpg");
  background-position: center;
  background-size: cover;
}
.quests-hints-promo {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 270px;
}
.quests-hints-promo-inner-wrapper {
  margin-right: 40px;
  padding: 20px 0;
}
.quests-hints-promo-title {
  margin-bottom: 15px;
  font-size: 48px;
  font-weight: bold;
  line-height: .9;
  color: #f4f3f3;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 4px 6px 0 #000000;
}
.quests-hints-promo-title span {
  font-size: 64px;
}
.quests-hints-promo-btn-wrapper {
  text-align: center;
}
.quests-hints-promo-btn {
  .btn();
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}
.quests-hints-promo-img {
  width: 400px;
  align-self: flex-end;
}
.quests-hints-promo-img img {
  display: block;
  width: 100%;
}



@media @medium-widths {
  .quests-hints-promo {
    min-height: 240px;
  }
  .quests-hints-promo-title {
    font-size: 40px;
  }
  .quests-hints-promo-title span {
    font-size: 54px;
  }
  .quests-hints-promo-img {
    width: 350px;
  }
}



@media @small-widths {
  .quests-hints-promo {
    min-height: 210px;
  }
  .quests-hints-promo-title {
    font-size: 36px;
  }
  .quests-hints-promo-title span {
    font-size: 48px;
  }
  .quests-hints-promo-img {
    width: 300px;
  }
}



@media @extraSmall-widths {
  .quests-hints-promo {
    display: block;
    min-height: 0;
  }
  .quests-hints-promo-inner-wrapper {
    margin-right: 0;
  }
  .quests-hints-promo-img {
    margin: 0 auto;
  }
  .quests-hints-promo-img {
    width: 260px;
  }
}



@media @exxSmall-widths {
  .quests-hints-promo-title {
    font-size: 30px;
  }
  .quests-hints-promo-title span {
    font-size: 40px;
  }
  .quests-hints-promo-img {
    width: 220px;
  }
}
