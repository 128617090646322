.giveaways-faq-wrapper {
  background-color: #100f0f;
  border-bottom: 5px solid #212121;
  padding-bottom: 55px;
}
.giveaways-faq {

}
.giveaways-faq-title {
  padding: 12px 15px;
  font-size: 25px;
  font-weight: bold;
  color: @color-text;
  text-transform: uppercase;
  text-align: center;
}
.giveaways-faq-block {

}
.giveaways-faq-block-title {
  padding: 20px 15px;
  font-size: 16px;
  font-weight: bold;
  color: @color-text-hover;
  text-transform: uppercase;
  text-align: center;
}
.giveaways-faq-accordion-body-items {
  margin-top: 30px;
}
.giveaways-faq-accordion-body-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.giveaways-faq-accordion-body-item:last-child {
  margin-bottom: 0;
}
.giveaways-faq-accordion-body-item-icon {
  flex-shrink: 0;
  width: 40px;
}
.giveaways-faq-accordion-body-item-icon img {
  display: block;
  width: 100%;
}
.giveaways-faq-accordion-body-item-content {
  padding-left: 55px;
  font-family: 'Open Sans', sans-serif;
}
.giveaways-faq-accordion-body-item-content-title {
  font-size: 16px;
  font-weight: bold;
  color: @color-text-hover;
  text-transform: uppercase;
}
.giveaways-faq-accordion-body-item-content-descr {
  font-size: 13px;
  font-weight: normal;
  color: @color-text;
}



@media @medium-widths {
  .giveaways-faq-title {
    font-size: 22px;
  }
  .giveaways-faq-accordion-body-item-content {
    padding-left: 40px;
  }
}



@media @extraSmall-widths {
  .giveaways-faq-accordion-body-items {
    margin-top: 20px;
  }
  .giveaways-faq-accordion-body-item {
    margin-bottom: 12px;
  }
  .giveaways-faq-accordion-body-item-icon {
    width: 30px;
  }
  .giveaways-faq-accordion-body-item-content {
    padding-left: 20px;
  }
  .giveaways-faq-accordion-body-item-content-title {
    font-size: 15px;
  }
}



@media @exxSmall-widths {
  .giveaways-faq-title {
    font-size: 20px;
  }
}
