.quests-hints-wrapper {
  background-color: #100f0f;
}
.quests-hints {
  padding: 17px 0;
}
.quests-hints-block {
  margin-bottom: 40px;
}
.quests-hints-block-title {
  margin-bottom: 25px;
  font-size: 25px;
  font-weight: bold;
  color: #dc291e;
  text-transform: uppercase;
  text-align: center;
}
.quests-hints-block-descr {
  max-width: 1140px;
  margin: 0 auto;
  font-size: 20px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
}
.quests-hints-block-text {
  max-width: 1200px;
  margin: 0 auto;
  font-size: 14px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: justify;
}
.quests-hints-block-items {
  margin: 35px 0;
}
.quests-hints-block-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding:  22px 110px;
  background-color: #2b2a2a;
  cursor: pointer;
}
.quests-hints-block-item:last-child {
  margin-bottom: 0;
}
.quests-hints-block-item-num {
  flex-shrink: 0;
  width: 40px;
  margin-right: 40px;
  font-family: "Stadium 1946", sans-serif;
  font-size: 70px;
  font-weight: normal;
  color: #dc291e;
  text-shadow: 4px 6px 0 #000000;
  text-align: center;
}
.quests-hints-block-item-content {
  flex-grow: 1;
}
.quests-hints-block-item-content-title {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.quests-hints-block-item-content-descr {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: justify;
}
.quests-hints-block-item-icon {
  position: relative;
  flex-shrink: 0;
  width: 42px;
  height: 42px;
  margin-left: 80px;
}
.quests-hints-block-item-icon-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: opacity .5s;
}
.quests-hints-block-item-icon-img img {
  display: block;
  width: 100%;
}
.quests-hints-block-item-icon-img-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity .5s;
}
.quests-hints-block-item-icon-img-hover img {
  display: block;
  width: 100%;
}
.quests-hints-block-item:hover .quests-hints-block-item-icon-img {
  opacity: 0;
}
.quests-hints-block-item:hover .quests-hints-block-item-icon-img-hover {
  opacity: 1;
}
.quests-hints-block-item-inner-items {
  padding: 20px 0;
}
.quests-hints-block-item-inner-item {
  position: relative;
  margin-bottom: 25px;
  padding-bottom: 25px;
  padding-left: 80px;
  padding-right: 122px;
  border-bottom: 1px solid #464545;
  box-shadow: 0 1px 0 0 #000000;
}
.quests-hints-block-item-inner-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
  box-shadow: none;
}
.quests-hints-block-item-inner-item-num {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  font-family: "Stadium 1946", sans-serif;
  font-size: 70px;
  font-weight: normal;
  line-height: .9;
  color: #dc291e;
  text-shadow: 4px 6px 0 #000000;
  text-align: center;
}
.quests-hints-block-item-inner-item-content {

}
.quests-hints-block-item-inner-item-content-title {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.quests-hints-block-item-inner-item-content-descr {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: justify;
  transition: color .5s;
}
.quests-hints-block-item-inner-item-content-descr a {
  color: inherit;
  &:focus {
    text-decoration: none;
  }
}
.quests-hints-block-item-inner-item-icon {
  position: absolute;
  top: 12px;
  right: 0;
  width: 42px;
  height: 42px;
}
.quests-hints-block-item-inner-item-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: opacity .5s;
}
.quests-hints-block-item-inner-item-img img {
  display: block;
  width: 100%;
}
.quests-hints-block-item-inner-item-icon-img-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity .5s;
}
.quests-hints-block-item-inner-item-icon-img-hover img {
  display: block;
  width: 100%;
}
.quests-hints-block-item:hover .quests-hints-block-item-inner-item-img {
  opacity: 0;
}
.quests-hints-block-item:hover .quests-hints-block-item-inner-item-icon-img-hover {
  opacity: 1;
}
.quests-hints-block-item:hover .hover-yellow {
  color: #fcf358;
}
.quests-hints-block-faq .quests-hints-block-item {
  display: block;
  padding: 0;
}
.quests-hints-block-item-question {
  position: relative;
  padding: 21px 190px;
  border-bottom: 1px solid #a9a8a8;
  cursor: pointer;
}
.quests-hints-block-item-question-text {
  margin: 0 auto;
  font-size: 20px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
  transition: color .5s;
}
.quests-hints-block-item-question:hover .quests-hints-block-item-question-text,
.quests-hints-block-item--opened .quests-hints-block-item-question-text {
  color: #dc291e;
}
.quests-hints-block-item-question-icon {
  position: absolute;
  right: 28px;
  top: 50%;
  transform: translate(0, -50%);
  width: 30px;
  height: 30px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 14px;
    left: 8px;
    width: 14px;
    height: 2px;
    background-color: #f4f3f3;
    transition: transform .5s;
  }
  &::before {
    transform: rotate(0deg);
  }
  &::after  {
    transform: rotate(90deg);
  }
}
.quests-hints-block-item--opened .quests-hints-block-item-question-icon {
  &::before {
    transform: rotate(135deg);
  }
  &::after  {
    transform: rotate(-135deg);
  }
}
.quests-hints-block-item-answer {
  height: 0;
  overflow: hidden;
  transition: height .5s;
}
.quests-hints-block-item-answer-inner-wrapper {
  padding: 28px 190px;
  border-bottom: 1px solid #a9a8a8;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: justify;
}



@media @xxlarge-widths {

}



@media @xlarge-widths {
  .quests-hints-block-item {
    padding: 20px 70px;
  }
  .quests-hints-block-item-question,
  .quests-hints-block-item-answer-inner-wrapper {
    padding: 20px 150px;
  }
}



@media @large-widths {
  .quests-hints-block-item {
    padding: 15px 50px;
  }
  .quests-hints-block-item-question,
  .quests-hints-block-item-answer-inner-wrapper {
    padding: 17px 130px;
  }
  .quests-hints-block-item-content-descr,
  .quests-hints-block-text,
  .quests-hints-block-item-inner-item-content-descr,
  .quests-hints-block-item-answer-inner-wrapper {
    font-size: 13px;
  }
}



@media @medium-widths {
  .quests-hints-block-title {
    font-size: 22px;
  }
  .quests-hints-block-descr {
    font-size: 18px;
  }
  .quests-hints-block-item-content-title {
    font-size: 18px;
  }
  .quests-hints-block-item-num {
    font-size: 60px;
  }
}



@media @small-widths {
  .quests-hints-block-item-num {
    margin-right: 30px;
  }
  .quests-hints-block-item-icon {
    margin-left: 50px;
  }
  .quests-hints-block-title {
    font-size: 20px;
  }
  .quests-hints-block-descr {
    font-size: 16px;
  }
  .quests-hints-block-item-content-title {
    font-size: 16px;
  }
  .quests-hints-block-item-inner-item-num,
  .quests-hints-block-item-num {
    font-size: 50px;
  }
  .quests-hints-block-item-inner-item {
    padding-left: 70px;
    padding-right: 92px;
  }
  .quests-hints-block-item-question,
  .quests-hints-block-item-answer-inner-wrapper {
    padding: 17px 120px;
  }
  .quests-hints-block-item-content-descr,
  .quests-hints-block-text,
  .quests-hints-block-item-inner-item-content-descr,
  .quests-hints-block-item-answer-inner-wrapper {
    font-size: 12px;
  }
}



@media @extraSmall-widths {
  .quests-hints-block-item {
    padding: 15px;
  }
  .quests-hints-block-item-num {
    margin-right: 15px;
  }
  .quests-hints-block-item-icon {
    width: 35px;
    height: 35px;
    margin-left: 20px;
  }
  .quests-hints-block-title {
    font-size: 18px;
  }
  .quests-hints-block-descr {
    font-size: 15px;
  }
  .quests-hints-block-item-content-title {
    font-size: 15px;
  }
  .quests-hints-block-item-inner-item {
    padding-left: 55px;
    padding-right: 55px;
  }
  .quests-hints-block-item-inner-item-icon {
    width: 35px;
    height: 35px;
  }
  .quests-hints-block-item-question,
  .quests-hints-block-item-answer-inner-wrapper {
    padding: 15px 80px;
  }
}



@media @exxSmall-widths {
  .quests-hints-block-title {
    font-size: 16px;
  }
  .quests-hints-block-descr {
    font-size: 14px;
  }
  .quests-hints-block-item-content-title {
    font-size: 14px;
  }
  .quests-hints-block-item-question,
  .quests-hints-block-item-answer-inner-wrapper {
    padding: 12px 20px;
    padding-right: 50px;
  }
  .quests-hints-block-item-question-icon {
    right: 10px;
  }
  .quests-hints-block-item-answer-inner-wrapper {
    font-size: 12px;
  }
}
