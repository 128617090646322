.page-header__nav-func {
  display: flex;
  align-items: center;
  height: 65px;
  padding-left: 80px;
  padding-right: 25px;
  border-bottom: 1px solid #292929;
}
.page-header--fixed .page-header__nav-func {
  padding-left: 0;
  border-bottom: none;
}
.page-header__nav-func-search {
  display: flex;
  align-items: center;
  margin-right: auto;
}
.page-header__nav-func-search-toggle {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 29px;
  height: 29px;
  transition: width .5s, opacity .5s;
  overflow: hidden;
  cursor: pointer;
}
.page-header__nav-func-search-toggle svg {
  display: block;
  width: 100%;
  height: 100%;
  fill: #f4f3f3;
  transition: fill .5s;
}
.page-header__nav-func-search-toggle--opened svg,
.page-header__nav-func-search-toggle:hover svg {
  fill: #dc291e;
}
.page-header__nav-func-search-form {
  position: relative;
}
.page-header__nav-func-search-form-wrapper {
  position: relative;
  width: 0;
  opacity: 0;
  overflow: hidden;
  transition: width .5s, opacity .3s;
}
.page-header__nav-func-search-form-input {
  box-sizing: border-box;
  width: 100%;
}
.page-header__nav-func-search-form-input input {
  box-sizing: border-box;
  width: 100%;
  padding: 5px 12px;
  background: none;
  border: 1px solid #f4f3f3;
  font-size: 14px;
  font-weight: normal;
  color: #f4f3f3;
  &:focus {
    outline: none;
  }
}
.page-header__nav-func-search-form-btn {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}
.page-header__nav-func-search-form-btn svg {
  display: block;
  width: 29px;
  height: 29px;
  fill: #f4f3f3;
  transition: fill .5s;
}
.page-header__nav-func-search-form-btn:hover svg {
  fill: #dc291e;
}
.page-header__nav-func-search-form-btn input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  &:focus {
    outline: none;
  }
}
.page-header__nav-func-user-block {

}
.page-header__nav-func-user-block-inner-wrapper {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 70px;
}
.page-header__nav-func-user-block-toggle {
  display: none;
}
.page-header__nav-func-login {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: normal;
  color: #dc291e;
  text-transform: uppercase;
  text-decoration: none;
  &:hover {
    color: #dc291e;
    text-decoration: none;
  }
  &:focus {
    color: #dc291e;
    text-decoration: none;
  }
}
.page-header--fixed .page-header__nav-func-login {
  top: -3px;
  padding-left: 0;
  font-size: 0;
}
.page-header__nav-func-login-icon {
  position: relative;
  top: -2px;
  display: block;
  width: 30px;
  height: 30px;
}
.page-header__nav-func-login-icon svg {
  display: block;
  width: 100%;
  height: 100%;
  fill: #f4f3f3;
  transition: fill .5s;
}
.page-header__nav-func-login:hover .page-header__nav-func-login-icon svg {
  fill: #dc291e;
}
.page-header__nav-func-new-user {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 30px;
  font-size: 12px;
  font-weight: normal;
  color: #dc291e;
  text-transform: uppercase;
  text-decoration: none;
  &:hover {
    color: #dc291e;
    text-decoration: none;
  }
  &:focus {
    color: #dc291e;
    text-decoration: none;
  }
}
.page-header__nav-func-new-user-icon {
  position: relative;
  top: 1px;
  display: block;
  width: 30px;
  height: 30px;
}
.page-header__nav-func-new-user-icon svg {
  display: block;
  width: 100%;
  height: 100%;
  fill: #f4f3f3;
  transition: fill .5s;
}
.page-header__nav-func-new-user:hover .page-header__nav-func-new-user-icon svg {
  fill: #dc291e;
}
.page-header--fixed .page-header__nav-func-new-user {
  font-size: 0;
  margin-left: 0;
}
.page-header__nav-func-user-wrapper {
  position: relative;
  z-index: 3000;
}
.page-header__nav-func-user {
  position: relative;
  padding-right: 15px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: normal;
  color: #dc291e;
  text-transform: uppercase;
  text-decoration: none;
  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 0;
    height: 0;
    border-top: 5px solid #f4f3f3;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    transition: border-top .5s;
  }
  &:hover {
    color: #dc291e;
    text-decoration: none;
    &::after {
      border-top: 5px solid #dc291e;
    }
  }
  &:focus {
    color: #dc291e;
    text-decoration: none;
  }
}
.page-header--fixed .page-header__nav-func-user {
  top: -3px;
  padding-left: 0;
  font-size: 0;
}
.page-header__nav-func-user-icon {
  position: relative;
  top: -2px;
  display: flex;
  align-items: center;
}
.page-header__nav-func-user-icon svg {
  fill: #f4f3f3;
  transition: fill .5s;
}
.page-header__nav-func-user:hover .page-header__nav-func-user-icon svg {
  fill: #dc291e;
}
.page-header__nav-func-user-nav {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 0;
  overflow: hidden;
  transition: height .5s;
}
.page-header__nav-func-user-nav--opened {
  height: auto;
}
.page-header__nav-func-user-nav-items {
  margin: 0;
  padding: 0;
  list-style: none;

  transform: translate(0, -100%);
  padding: 5px 0;
  background-color: rgba(16, 15, 15, 0.88);
  border: 1px solid #dc291e;
  transition: transform .5s;
}
.page-header__nav-func-user-nav--opened .page-header__nav-func-user-nav-items {
  transform: translate(0, 0);
}
.page-header__nav-func-user-nav-items li {
  padding: 5px 12px;
}
.page-header__nav-func-user-nav-items li a {
  display: flex;
  align-items: baseline;
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  transition: color .5s;
  &:hover {
    color: #dc291e;
    text-decoration: none;
    &::after {
      border-top: 5px solid #dc291e;
    }
  }
  &:focus {
    color: #dc291e;
    text-decoration: none;
  }
}
.page-header__nav-func-user-nav-items li a span {
  display: flex;
  align-items: baseline;
  justify-content: center;
  min-width: 10px;
  margin-left: 5px;
  padding: 4px;
  padding-top: 5px;
  font-size: 10px;
  color: #ffffff;
  line-height: 1;
  text-align: center;
  background-color: #dc291e;
  border-radius: 10px;
}
.page-header__nav-func-points {
  display: flex;
  align-items: center;
  margin-left: 30px;
  font-size: 12px;
  font-weight: normal;
  color: #dc291e;
  text-transform: uppercase;
  text-decoration: none;
  &:hover {
    color: #dc291e;
    text-decoration: none;
  }
  &:focus {
    color: #dc291e;
    text-decoration: none;
  }
}
.page-header--fixed .page-header__nav-func-points {
  top: -3px;
  padding-left: 0;
  font-size: 0;
}
.page-header__nav-func-points-icon {
  position: relative;
  top: -2px;
  display: flex;
  align-items: center;
}
.page-header__nav-func-points-icon svg {
  fill: #f4f3f3;
  transition: fill .5s;
}
.page-header__nav-func-points:hover .page-header__nav-func-points-icon svg {
  fill: #dc291e;
}
.page-header__nav-func-messages-toggle {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 30px;
  cursor: pointer;
}
.page-header__nav-func-messages-toggle-num {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 14px;
  height: 14px;
  padding: 5px;
  border-radius: 50%;
  background-color: #dc291e;
  font-size: 8px;
  font-weight: normal;
  color: #ffffff;
}
.page-header__nav-func-messages-toggle svg {
  fill: #ffffff;
  transition: fill .5s;
}
.page-header__nav-func-messages-toggle--opened svg,
.page-header__nav-func-messages-toggle:hover svg {
  fill: #dc291e;
}
.page-header__nav-func-notifications-toggle {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 30px;
  cursor: pointer;
}
.page-header__nav-func-notifications-toggle-num {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 14px;
  height: 14px;
  padding: 5px;
  border-radius: 50%;
  background-color: #dc291e;
  font-size: 8px;
  font-weight: normal;
  color: #ffffff;
}
.page-header__nav-func-notifications-toggle svg {
  fill: #ffffff;
  transition: fill .5s;
}
.page-header__nav-func-notifications-toggle--opened svg,
.page-header__nav-func-notifications-toggle:hover svg {
  fill: #dc291e;
}
.page-header__nav-func-cart-block-wrapper {
  position: relative;
  padding-left: 70px;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 1px;
    height: 36px;
    background-color: #3e3e3e;
  }
}
.page-header__nav-func-cart-block-toggle {
  display: none;
}
.page-header__nav-func-cart-block {

}
.page-header__nav-func-cart-block-inner-wrapper {
  display: flex;
  align-items: center;
}
.page-header__nav-func-currency {
  position: relative;
  min-width: 25px;
}
.page-header__nav-func-currency-current {
  position: relative;
  padding: 5px 0;
  padding-right: 8px;
  font-size: 20px;
  font-weight: normal;
  color: #dc291e;
  cursor: pointer;
  &::before,
  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform-origin: 3.5px 50%;
    width: 4px;
    height: 1px;
    background-color: #ffffff;
  }
  &::before {
    transform: translate(0, 50%) rotate(45deg);
  }
  &::after {
    transform: translate(0, 50%) rotate(-45deg);
  }
}
.page-header__nav-func-currency-items-wrapper {
  position: absolute;
  top: 100%;
  left: 33%;
  transform: translate(-50%, 0);
  height: 0;
  overflow: hidden;
}
.page-header__nav-func-currency:hover .page-header__nav-func-currency-items-wrapper {
  height: auto;
}
.page-header__nav-func-currency-items {
  padding: 3px 5px;
  background-color: #100f0f;
  border: 1px solid #3e3e3e;
  border-radius: 2px;
  transform: translate(0, -100%);
}
.page-header__nav-func-currency:hover .page-header__nav-func-currency-items {
  transform: translate(0, 0);
  transition: transform .5s;
}
.page-header__nav-func-currency-item {
  padding: 3px 5px;
  font-size: 20px;
  font-weight: normal;
  color: #f4f3f3;
  cursor: pointer;
  transition: color .5s;
  &:hover {
    color: #dc291e;
  }
}
.page-header__nav-func-currency-item--current {
  color: #dc291e;
}
.page-header__nav-func-cart {
  position: relative;
  top: 3px;
  margin-left: 20px;
}
.page-header__nav-func-cart-num {
  position: absolute;
  top: 27%;
  left: 56%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  background-color: #dc291e;
  border-radius: 50%;
  font-size: 8px;
  font-weight: normal;
  color: #ffffff;
}
.page-header__nav-func-cart svg {
  display: block;
  width: 35px;
  height: 35px;
  fill: #f4f3f3;
}



@media @xxlarge-widths {
  .page-header__nav-func {
    padding-left: 50px;
  }
}



@media @xlarge-widths {
  .page-header__nav-func-user-block-inner-wrapper {
    padding-right: 30px;
  }
  .page-header--fixed .page-header__nav-func-user-block {

  }
  .page-header__nav-func {
    padding-left: 30px;
  }
  .page-header__nav-func-points {
    margin-left: 15px;
  }
  .page-header__nav-func-messages-toggle {
    margin-left: 15px;
  }
  .page-header__nav-func-notifications-toggle {
    margin-left: 15px;
  }
  .page-header__nav-func-cart-block-wrapper {
    padding-left: 20px;
  }
}



@media @large-widths {
  .page-header__nav-func {
    padding-left: 20px;
  }
  .page-header__nav-func-cart {
    margin-left: 17px;
  }
}



@media @medium-widths {
  .page-header--fixed .page-header__nav-func-search-form-wrapper {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    right: 0;
    padding: 5px 10px;
    background-color: #100f0f;
  }
  .page-header--fixed .page-header__nav-func-search-form {
    max-width: 400px;
    margin: 0 auto;
  }
  .page-header__nav-func-user {
    font-size: 10px;
  }
  .page-header__nav-func-points {
    font-size: 10px;
  }
}



@media @small-widths {
  .page-header__nav-func {
    padding: 0 5px;
    border-bottom: none;
  }
  .page-header__nav-func-search-form-wrapper {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    right: 0;
    width: auto;
    padding: 5px 25px;
    background-color: #100f0f;
    opacity: 1;
  }
  .page-header__nav-func-search-form {
    max-width: 400px;
    margin: 0 auto;
  }
  .page-header__nav-func-user-block-wrapper {
    padding: 0 5px;
  }
  .page-header__nav-func-user-block-toggle {
    display: block;
    cursor: pointer;
  }
  .page-header__nav-func-user-block-toggle svg {
    display: block;
    width: 30px;
    height: 30px;
    fill: #ffffff;
    transition: fill .5s;
  }
  .page-header__nav-func-user-block-toggle--opened svg,
  .page-header__nav-func-user-block-toggle:hover svg {
    fill: #dc291e;
  }
  .page-header__nav-func-user-block {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    right: 0;
    background-color: #100f0f;
  }
  .page-header__nav-func-user-block-inner-wrapper {
    justify-content: center;
  }
  .page-header--fixed .page-header__nav-func-login {
    font-size: 12px;
  }
  .page-header--fixed .page-header__nav-func-new-user {
    font-size: 12px;
  }
  .page-header__nav-func-cart-block-wrapper {
    position: static;
    padding-left: 5px;
    &::before {
      display: none;
    }
  }
  .page-header__nav-func-cart-block-toggle {
    display: block;
    cursor: pointer;
  }
  .page-header__nav-func-cart-block-toggle svg {
    display: block;
    width: 35px;
    height: 35px;
    fill: #ffffff;
    transition: fill .5s;
  }
  .page-header__nav-func-cart-block-toggle--opened svg,
  .page-header__nav-func-cart-block-toggle:hover svg {
    fill: #dc291e;
  }
  .page-header__nav-func-cart-block {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    right: 0;
    background-color: #100f0f;
  }
  .page-header__nav-func-cart-block-inner-wrapper {
    justify-content: center;
  }
}



// @media @extraSmall-widths {
//
// }



@media @exxSmall-widths {
  .page-header__nav-func-user-block-inner-wrapper {
    flex-direction: column;
  }
  .page-header__nav-func-new-user {
    margin-left: 0;
  }
  .page-header__nav-func-points {
    margin-left: 0;
  }
  .page-header__nav-func-messages-toggle {
    margin-left: 0;
  }
  .page-header__nav-func-notifications-toggle {
    margin-left: 0;
  }
}
