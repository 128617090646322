.store-header-wrapper {
  background-image: url("../img/store/store-header-img-bg.jpg");
  background-position: center;
  background-size: cover;
}
.store-header {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  max-width: 1640px;
  margin: 0 auto;
  padding: 30px 15px;
}
.store-header-welcome {
  flex-shrink: 0;
  max-width: 215px;
  font-family: "Stadium 1946", sans-serif;
  font-size: 34px;
  font-weight: normal;
  line-height: 1;
  color: #f4f3f3;
  text-shadow: 4px  5px 0 #000000;
}
.store-header-welcome span {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 65px;
  color: #dc291e;
}
.store-header-welcome img {
  width: 64px;
}
.store-header-filters {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 20px;
}
.store-header-filters-items {
  display: flex;
  justify-content: center;
}
.store-header-filters-item {
  margin-right: 30px;
  cursor: pointer;
}
.store-header-filters-item:last-child {
  margin-right: 0;
}
.store-header-filters-item-img-wrapper {
  position: relative;
  width: 57px;
  height: 57px;
  margin: 0 auto;
  margin-bottom: 10px;
}
.store-header-filters-item-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: opacity .5s;
}
.store-header-filters-item:hover .store-header-filters-item-img {
  opacity: 0;
}
.store-header-filters-item-img-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity .5s;
}
.store-header-filters-item:hover .store-header-filters-item-img-hover {
  opacity: 1;
}
.store-header-filters-item-name {
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
}
.store-header-filters-items .owl-dots {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 0;
}
.store-header-filters-items .owl-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 25px;
  background-color: #ffffff;
  border-radius: 50%;
}
.store-header-filters-items .owl-dot:last-child {
  margin-right: 0;
}
.store-header-filters-items .owl-dot.active {
  background-color: #dc291e;
}
.store-header-info {
  flex-shrink: 0;
  max-width: 200px;
  font-family: "Stadium 1946", sans-serif;
  font-size: 24px;
  font-weight: normal;
  line-height: 1;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 4px 5px 0 #000000;
}
.store-header-info-big {
  font-size: 46px;
  color: #dc291e;
}
.store-header-info-small {
  display: inline-block;
  padding: 4px 5px;
  background-color: #100f0f;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-shadow: none;
}



@media @xxlarge-widths {
  .store-header-filters-item {
    margin-right: 25px;
  }
}



@media @xlarge-widths {
  .store-header-filters {
    width: 850px;
    padding-bottom: 20px;
  }
}



@media @large-widths {
  .store-header-filters {
    width: 750px;
  }
}



@media @medium-widths {
  .store-header-filters {
    width: 550px;
  }
}



@media @small-widths {
  .store-header-filters {
    width: 320px;
  }
}



@media @extraSmall-widths {
  .store-header {
    display: block;
  }
  .store-header-welcome {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: none;
    margin-bottom: 20px;
  }
  .store-header-welcome span {
    margin-left: 10px;
    font-size: 34px;
  }
  .store-header-welcome img {
    width: 40px;
  }
  .store-header-filters {
    width: 100%;
    margin-bottom: 45px;
  }
  .store-header-info {
    margin: 0 auto;
  }
}



@media @exxSmall-widths {
  .store-header-welcome {
    flex-direction: column;
  }
  .store-header-welcome span {
    margin-top: 10px;
  }
}
