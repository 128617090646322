.giveaways-wrapper {

}
.giveaways {
  box-sizing: border-box;
  width: 100%;
  padding-top: 105px;
  padding-bottom: 40px;
  background-image: url("../img/giveaways-bg.jpg");
  background-position: center;
  background-size: cover;
}
.giveaways-title {
  .block-title();
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
.giveaways-title-icon {
  width: 45px;
  margin-right: 10px;
}
.giveaways-title-icon img {
  display: block;
  width: 100%;
}
.giveaways-slider-wrapper {
  padding: 0 15px;
}
.giveaways-slider {
  padding-bottom: 45px;
}
.giveaways-slide {
  box-sizing: border-box;
  max-width: 410px;
  margin: 0 auto;
  padding: 0 5px;
}
.giveaways-slide-header {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: #8a1f18;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
  transition: background-color .5s;
}
.giveaways-slide:hover .giveaways-slide-header {
  background-color: #b3241b;
}
.giveaways-slide-header-period {
  display: flex;
  align-items: center;
  margin-right: auto;
  padding-right: 10px;
}
.giveaways-slide-header-period-icon {
  width: 12px;
  height: 15px;
  margin-right: 5px;
  background-image: url("../img/icon-unlock-white.png");
  background-repeat: no-repeat;
  background-position: center;
}
.giveaways-slide:hover .giveaways-slide-header-period-icon {
  background-image: url("../img/icon-unlock-black.png");
}
.giveaways-slide-header-points {

}
.giveaways-slide-img-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 45%;
}
.giveaways-slide-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-size: cover;
}
.giveaways-slide-img-btn-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(220, 41, 30, .85);
  opacity: 0;
  transition: opacity .5s;
}
.giveaways-slide:hover .giveaways-slide-img-btn-wrapper {
  opacity: 1;
}
.giveaways-slide-img-btn-enter {
  .btn();
  margin-bottom: 7px;
  border: 2px solid #100f0f;
  color: #100f0f;
  &:hover {
    border: 2px solid #dc291e;
    color: #f4f3f3;
    background-color: #100f0f;
  }
}
.giveaways-slide-img-btn-more {
  .btn();
  border: 2px solid #100f0f;
  color: #100f0f;
  &:hover {
    border: 2px solid #dc291e;
    color: #f4f3f3;
    background-color: #100f0f;
  }
}
.giveaways-slide-footer {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: #100f0f;
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  transition: background-color .5s, color .5s;
}
.giveaways-slide:hover .giveaways-slide-footer {
  background-color: #f4f3f3;
  color: #dc291e;
}
.giveaways-slide-footer-steam-icon {
  flex-shrink: 0;
  width: 26px;
  height: 24px;
  background-image: url("../img/icon-steam-red.png");
  background-repeat: no-repeat;
  background-position: center;
}
.giveaways-slide:hover .giveaways-slide-footer-steam-icon {
  background-image: url("../img/icon-steam-black.png");
}
.giveaways-slide-footer-users-icon {
  margin-right: 10px;
  fill: #e11d24;
  transition: fill .5s;
}
.giveaways-slide:hover .giveaways-slide-footer-users-icon {
  fill: #100f0f;
}
.giveaways-slide-footer-name {

}
.giveaways-slide-footer-comments {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: 10px;
}
.giveaways-slide-footer-comments-icon {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  background-image: url("../img/icon-comments-red.png");
  background-repeat: no-repeat;
  background-position: center;
}
.giveaways-slide:hover .giveaways-slide-footer-comments-icon {
  background-image: url("../img/icon-comments-black.png");
}
.giveaways-slider .owl-nav .owl-prev {
  position: absolute;
  left: -40px;
  top: ~"calc(50% - 23px)";
  transform: translate(0, -50%);
  width: 31px;
  height: 30px;
  font-size: 0;
  cursor: pointer;
  z-index: 100;
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 12px;
    top: 14px;
    width: 14px;
    height: 2px;
    transform-origin: 1px 50%;
    background-color: #ffffff;
  }
  &::after {
    transform: rotate(50deg);
  }
  &::before {
    transform: rotate(-50deg);
  }
}
.giveaways-slider .owl-nav .owl-next {
  position: absolute;
  right: -40px;
  top: ~"calc(50% - 23px)";
  transform: translate(0px, -50%);
  width: 31px;
  height: 30px;
  font-size: 0;
  cursor: pointer;
  z-index: 100;
  &::before,
  &::after {
    content: "";
    position: absolute;
    right: 12px;
    top: 14px;
    width: 14px;
    height: 2px;
    transform-origin: 13px 50%;
    background-color: #ffffff;
  }
  &::before {
    transform: rotate(50deg);
  }
  &::after {
    transform: rotate(-50deg);
  }
}
.giveaways-slider .owl-dots {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 0;
}
.giveaways-slider .owl-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 25px;
  background-color: #ffffff;
  border-radius: 50%;
}
.giveaways-slider .owl-dot:last-child {
  margin-right: 0;
}
.giveaways-slider .owl-dot.active {
  background-color: #dc291e;
}
.giveaways-btn-wrapper {
  margin-top: 40px;
  text-align: center;
}
.giveaways-btn {
  .btn();
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}
.giveaways-join {
  display: flex;
  align-items: center;
  padding: 70px 0;
  background-image: url("../img/giveaways-join-bg.jpg");
  background-position: center;
  background-size: cover;
}
.giveaways-join-title {
  margin-bottom: 20px;
  font-size: 48px;
  font-weight: bold;
  color: #f4f3f3;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 5px 4px 0 #000000;
}
.giveaways-join-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.giveaways-join-img1 {

}
.giveaways-join-img1 img {
  display: block;
  width: 100%;
}
.giveaways-join-img2 {
  margin: 0 18px;
}
.giveaways-join-img2 img {
  display: block;
  width: 100%;
}
.giveaways-join-img3 {

}
.giveaways-join-img3 img {
  display: block;
  width: 100%;
}



@media @large-widths {
  .giveaways-slider-wrapper {
    padding: 0 20px;
  }
}



@media @medium-widths {
  .giveaways-slider-wrapper {
    padding: 0 25px;
  }
  .giveaways-slider .owl-nav .owl-prev {
    left: -30px;
  }
  .giveaways-slider .owl-nav .owl-next {
    right: -30px;
  }
  .giveaways-join-title {
    font-size: 40px;
  }
}



@media @small-widths {
  .giveaways-join {
    padding: 30px 0;
  }
  .giveaways-join-title {
    font-size: 30px;
  }
  .giveaways-join-content {
    display: block;
    max-width: 300px;
    margin: 0 auto;
  }
  .giveaways-join-img1,
  .giveaways-join-img2 {
    margin-bottom: 10px;
  }
}



@media @exxSmall-widths {
  .giveaways-slider-wrapper {
    padding: 0;
  }
  .giveaways-slide-header {
    font-size: 14px;
  }
  .giveaways-join-title {
    font-size: 24px;
  }
}
