.accordion {

}
.accordion-item {
  margin-bottom: 7px;
  background-color: #2b2a2a;
}
.accordion-item-title {
  display: flex;
  align-items: center;
  padding: 5px 40px;
  border-bottom: 1px solid #a9a8a8;
  cursor: pointer;
}
.accordion-item-title-let {
  flex-shrink: 0;
  width: 30px;
  font-family: "Stadium 1946", sans-serif;
  font-size: 45px;
  font-weight: normal;
  color: @color-text-hover;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 4px 6px 0 #000000;
  transition: color .5s;
}
.accordion-item--opened .accordion-item-title-let {
  color: @color-text;
}
.accordion-item-title-text {
  margin: 0 auto;
  padding: 0 40px;
  text-align: center;
  transition: color .5s;
}
.accordion-item-title:hover .accordion-item-title-text,
.giveaways-faq-block-item--opened .giveaways-faq-block-item-question-text {
  color: @color-text-hover;
}
.accordion-item-title-icon {
  position: relative;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 14px;
    left: 8px;
    width: 14px;
    height: 2px;
    background-color: @color-text;
    transition: transform .5s;
  }
  &::before {
    transform: rotate(0deg);
  }
  &::after  {
    transform: rotate(90deg);
  }
}
.accordion-item--opened .accordion-item-title-icon {
  &::before {
    transform: rotate(135deg);
  }
  &::after  {
    transform: rotate(-135deg);
  }
}
.accordion-item-body {
  height: 0;
  overflow: hidden;
  transition: height .5s;
}
.accordion-item-body-inner-wrapper {
  display: flex;
  padding: 5px 40px;
  padding-right: 70px;
  border-bottom: 1px solid #a9a8a8;
}
.accordion-item-body-let {
  flex-shrink: 0;
  width: 30px;
  font-family: "Stadium 1946", sans-serif;
  font-size: 45px;
  font-weight: normal;
  color: #dc291e;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 4px 6px 0 #000000;
}
.accordion-item-body-text {
  margin: 0 auto;
  padding: 15px 40px;
  text-align: justify;
}



@media @extraSmall-widths {
  .accordion-item-title {
    padding: 5px 15px;
  }
  .accordion-item-title-text {
    padding: 0 25px;
  }
  .accordion-item-body-inner-wrapper {
    padding: 5px 15px;
    padding-right: 45px;
  }
  .accordion-item-body-text {
    padding: 10px 25px;
  }
}



@media @exxSmall-widths {
  .giveaways-faq-title {
    font-size: 20px;
  }
  .accordion-item-title {
    padding: 5px 10px;
  }
  .accordion-item-title-let {
    width: 20px;
    font-size: 35px;
  }
  .accordion-item-title-text {
    padding: 0 15px;
  }
  .accordion-item-body-inner-wrapper {
    padding: 5px 10px;
  }
  .accordion-item-body-let {
    width: 20px;
    font-size: 35px;
  }
  .accordion-item-body-text {
    padding: 10px 15px;
  }
}
