.product-sidebar {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 400px;
  margin-left: 80px;
  padding-top: 70px;
  padding-bottom: 30px;
}
.product-sidebar-categiries {
  margin-bottom: 20px;
}
.product-sidebar-categiries-title {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.product-sidebar-categiries-items {
  display: flex;
  flex-wrap: wrap;
}
.product-sidebar-categiries-item {
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 9px 20px;
  background-color: #151414;
  border-bottom: 1px solid #393838;
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: center;
  text-transform: uppercase;
  &:hover {
    color: #f4f3f3;
    text-decoration: none;
  }
}
.product-sidebar-categiries-item--active {
  background-color: #ed1c24;
}
.product-sidebar-redeem {
  position: relative;
  margin-bottom: 20 px;
}
.product-sidebar-redeem-img {
  width: 100%;
}
.product-sidebar-redeem-img img {
  display: block;
  width: 100%;
}
.product-sidebar-redeem-btn-wrapper {
  position: absolute;
  left: 60px;
  bottom: 35px;
}
.product-sidebar-redeem-btn {
  position: relative;
  display: inline-block;
  padding-right: 24px;
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 8px;
    height: 8px;
    background-image: url("../img/arrow.png");
    background-repeat: no-repeat;
    background-position: center;
  }
  &:hover {
    color: #ed1c24;
    text-decoration: none;
    &::before {
      background-image: url("../img/arrow-red.png");
    }
  }
}




.product-sidebar-recommnded {

}
.product-sidebar-recommnded-title {
  margin-bottom: 15px;
  background-image: url("../img/promo-top-games-title-bg.jpg");
  background-position: center;
  background-size: cover;
  font-family: "Stadium 1946", sans-serif;
  font-size: 30px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: center;
}
.product-sidebar-recommnded-items {
  display: block;
}
.product-sidebar-recommnded-item {
  margin-bottom: 15px;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
}
.product-sidebar-recommnded-item:last-child {
  margin-bottom: 0;
}
.product-sidebar-recommnded-item-name {
  position: relative;
  padding: 10px 0;
  padding-left: 31px;
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-decoration: none;
  transition: color .5s;
  &::before {
    content: "";
    position: absolute;
    left: 3px;
    top: 50%;
    transform: translate(0, -50%);
    width: 16px;
    height: 16px;
    background-image: url("../img/icon/icon-steam.png");
    background-repeat: no-repeat;
    background-position: center;
  }
}
.product-sidebar-recommnded-item:hover .product-sidebar-recommnded-item-name {
  color: #ed1c24;
}
.product-sidebar-recommnded-item-img-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 51%;
}
.product-sidebar-recommnded-item-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-size: cover;
}
.product-sidebar-recommnded-item-img-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: rgba(25, 24, 24, .6);
  border: 1px solid #ed1c24;
  opacity: 0;
  transition: opacity .5s;
}
.product-sidebar-recommnded-item:hover .product-sidebar-recommnded-item-img-hover {
  opacity: 1;
}
.product-sidebar-recommnded-item-img-hover-our-price {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: bold;
  color: #ed1c24;
  text-transform: uppercase;
}
.product-sidebar-recommnded-item-img-hover-rrp {
  font-size: 20px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-decoration: line-through;
}



// @media @xxlarge-widths {
//
// }



@media @xlarge-widths {
  .product-sidebar {
    width: 380px;
    margin-left: 40px;
  }
}



@media @large-widths {
  .product-sidebar {
    width: 350px;
    margin-left: 30px;
  }
}



@media @medium-widths {
  .product-sidebar {
    width: 300px;
    margin-left: 20px;
  }
  .product-sidebar-categiries-item {
    padding: 7px 15px;
  }
}



@media @small-widths {
  .product-sidebar {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-left: 0;
    padding: 25px;
  }
  .product-sidebar-categiries {
    width: 50%;
    padding-left: 20px;
  }
  .product-sidebar-redeem {
    flex-shrink: 0;
    width: 350px;
    margin-left: auto;
  }
  .product-sidebar-recommnded {
    width: 100%;
  }
  .product-sidebar-recommnded-items {
    display: flex;
    flex-wrap: wrap;
  }
  .product-sidebar-recommnded-item {
    box-sizing: border-box;
    width: 320px;
    padding: 0 10px;
    margin-left: auto;
    margin-right: auto;
  }
}



@media (max-width: 840px) {
  .product-sidebar-categiries {
    width: 100%;
  }
  .product-sidebar-redeem {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }
}



@media @extraSmall-widths {
  .product-sidebar-recommnded-item {
    box-sizing: border-box;
    width: 280px;
    margin-left: auto;
    margin-right: auto;
  }
}



@media @exxSmall-widths {
  .product-sidebar {
    padding-left: 0;
    padding-right: 0;
  }
  .product-sidebar-categiries-item {
    padding: 5px 12px;
    font-size: 11px;
  }
  .product-sidebar-redeem {
    width: 100%;
    max-width: 350px;
    margin-left: auto;
  }
}
