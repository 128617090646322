.page-photos {
  position: relative;
  background-color: #100f0f;
  overflow: hidden;
  border-bottom: 2px solid #212121;
}
.page-photos-img-bg {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 1800px;
  min-width: 1000px;
  width: 100%;
  z-index: 10;
}
.page-photos-img-bg img {
  display: block;
  width: 100%;
}
.page-photos-title {
  position: relative;
  margin-bottom: 50px;
  padding: 12px 15px;
  font-size: 25px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
  z-index: 20;
}
.page-photos-blocks {
  position: relative;
  max-width: 1640px;
  margin: 0 auto;
  padding: 20px;
  z-index: 20;
}
.page-photos-block {
  margin-bottom: 15px;
  padding: 15px;
  padding-bottom: 22px;
  background-color: #2b2a2a;
}
.page-photos-block-title {
  margin-bottom: 10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #f4f3f3;
  text-align: center;
  text-transform: uppercase;
}
.page-photos-block-descr {
  margin-bottom: 15px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: center;
}
.page-photos-block-items {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.page-photos-block-item {
  width: ~"calc((100% - 21px) / 4)";
  margin-right: 7px;
  margin-bottom: 10px;
}
@media (min-width: 1320px) {
  .page-photos-block-item:nth-child(4n) {
    margin-right: 0;
  }
}
.page-photos-block-item img {
  display: block;
  width: 100%;
}
.page-photos-block-footer {
  display: flex;
  align-items: center;
}
.page-photos-block-footer-date {
  display: flex;
  align-items: center;
  min-width: 100px;
}
.page-photos-block-footer-date-icon {
  width: 10px;
  height: 11px;
  margin-right: 7px;
}
.page-photos-block-footer-date-icon img {
  display: block;
  width: 100%;
}
.page-photos-block-footer-date-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
}
.page-photos-block-footer-btn-wrapper {
  margin: 0 auto;
  padding: 0 20px;
}
.page-photos-block-footer-num {
  min-width: 100px;
  font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: right;
}



@media @large-widths {
  .page-photos-title {
    margin-bottom: 30px;
  }
  .page-photos-block-title {
    font-size: 15px;
  }
  .page-photos-block-descr {
    font-size: 13px;
  }
  .page-photos-block-item {
    width: ~"calc((100% - 14px) / 3)";
  }
}
@media (min-width: 992px) and (max-width: 1319px) {
  .page-photos-block-item:nth-child(3n) {
    margin-right: 0;
  }
}



@media @medium-widths {
  .page-photos-title {
    margin-bottom: 10px;
    font-size: 22px;
  }
}



@media @small-widths {
  .page-photos-block-title {
    font-size: 14px;
  }
  .page-photos-block-descr {
    font-size: 12px;
  }
  .page-photos-block-items {
    max-width: 780px;
    margin: auto;
    margin-bottom: 15px;
  }
  .page-photos-block-item {
    width: ~"calc((100% - 7px) / 2)";
  }
}
@media (min-width: 640px) and (max-width: 991px) {
  .page-photos-block-item:nth-child(2n) {
    margin-right: 0;
  }
}



@media (max-width: 639px) {
  .page-photos-block-items {
    max-width: 350px;
  }
  .page-photos-block-item {
    width: 100%;
    margin-right: 0;
  }
  .page-photos-block-footer {
    flex-wrap: wrap;
  }
  .page-photos-block-footer-date {
    margin-right: auto;
  }
  .page-photos-block-footer-btn-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
    order: 10;
  }
}



@media @exxSmall-widths {
  .page-photos-title {
    font-size: 20px;
  }
}
