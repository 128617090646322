.settings-filters-wrapper-outer-wrapper {
  padding-top: 40px;
}
.settings-filters-wrapper {
  box-sizing: border-box;
  max-width: 1650px;
  margin: 0 auto;
  padding: 0 20px;
  background-image: url("../img/store/store-settings-filters-img-bg.jpg");
  background-position: center;
  background-size: cover;
}
.settings-filters {
  display: flex;
}
.settings-filters-isfilter {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin-right: auto;
  padding: 5px 0;
  font-size: 16px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.settings-filters-isfilter-icon {
  margin-right: 10px;
}
.settings-filters-isfilter-icon img {
  display: block;
  width: 100%;
}
.settings-filters-hide-block {
  flex-grow: 1;
}
.settings-filters-blocks-wrapper {
  display: flex;
  padding-left: 30px;
}
.settings-filters-blocks-toggle {
  display: none;
}
.settings-filters-blocks {
  display: flex;
  width: 100%;
}
.settings-filters-block {
  position: relative;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  width: 25%;
  padding: 0 25px;
}
.settings-filters-block-current {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 0;
  font-size: 16px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 2px 2px 0 #000000;
  cursor: pointer;
}
.settings-filters-block-icon {
  position: relative;
  align-self: center;
  width: 20px;
  height: 14px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    right: 3px;
    top: 8px;
    width: 2px;
    height: 5px;
    transform-origin: 50% .5px;
    background-color: #100f0f;
    transition: top .5s, transform .5s;
  }
  &::before {
    transform: rotate(135deg);
  }
  &::after {
    transform: rotate(-135deg);
  }
}
.settings-filters-block--opened .settings-filters-block-icon {
  &::before,
  &::after {
    top: 5px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}
.settings-filters-block-items-wrapper {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 0;
  overflow: hidden;
  z-index: 100;
  transition: height .5s;
}
.settings-filters-block-items {
  background-color: #100f0f;
  border: 1px solid #3e3e3e;
  border-radius: 2px;
}
.settings-filters-block-genres .settings-filters-block-items {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  width: 800px;
}
.settings-filters-block-item {
  display: block;
  min-width: 180px;
  padding: 7px 20px;
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  white-space: nowrap;
  transition: color .5s;
  &:hover {
    color: #ed1c24;
    text-decoration: none;
  }
  &:focus {
    color: #f4f3f3;
    text-decoration: none;
  }
}
.settings-filters-block-genres .settings-filters-block-item {
  box-sizing: border-box;
  width: 25%;
}
.settings-filters-reset-filter {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: 20px;
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.settings-filters-reset-filter-icon {
  margin-right: 10px;
  transform: rotate(180deg);
}
.settings-filters-reset-filter-icon img {
  display: block;
  width: 100%;
}



@media @xlarge-widths {
  .settings-filters-isfilter {
    font-size: 15px;
  }
  .settings-filters-block-current {
    font-size: 15px;
  }
}



@media @medium-widths {
  .settings-filters-isfilter {
    font-size: 14px;
  }
  .settings-filters-block-current {
    font-size: 14px;
  }
}



@media @small-widths {
  .settings-filters-wrapper {
    position: relative;
  }
  .settings-filters-blocks-toggle {
    position: relative;
    display: block;
    font-size: 0;
    width: 50px;
    height: 40px;
    cursor: pointer;
    z-index: 300;
    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 10px;
      top: 19px;
      width: 30px;
      height: 2px;
      background-color: #f4f3f3;
      transition: transform .5s, box-shadow .5s;
    }
    &::before {
      transform: translate(0, -8px);
      box-shadow: 0 8px 0 0 #f4f3f3;
    }
    &::after {
      transform: translate(0, 8px);
    }
  }
  .settings-filters-blocks-toggle--opened {
    &::before {
      transform: rotate(45deg);
      box-shadow: none;
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
  .settings-filters-hide-block {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 0;
    overflow: hidden;
    transition: height .5s;
    z-index: 100;
    background-color: #100f0f;
  }
  .settings-filters-blocks-wrapper {
    // display: block;
    flex-direction: column;
    align-items: center;
    padding: 10px 15px;
  }
  .settings-filters-blocks {
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 10px 15px;
    background-color: #100f0f;
  }
  .settings-filters-block {
    display: block;
    width: auto;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .settings-filters-block-icon {
    &::before,
    &::after {
      background-color: #f4f3f3;
    }
  }
  .settings-filters-block-items-wrapper {
    position: static;
    transform: translate(0, 0);
  }
  .settings-filters-block-items {
    border: none;
  }
  .settings-filters-block-item {
    text-align: center;
  }
  .settings-filters-block-genres .settings-filters-block-items {
    width: 600px;
  }
   .settings-filters-block-genres .settings-filters-block-item {
    width: 33%;
  }
  .settings-filters-reset-filter {
    margin-left: 0;
    padding-left: 0;
  }
  .settings-filters-reset-filter-icon {
    margin-left: auto;
  }
  .settings-filters-reset-filter span {
    margin-right: auto;
  }
}



@media @extraSmall-widths {
  .settings-filters-block-genres .settings-filters-block-items {
    width: 400px;
  }
   .settings-filters-block-genres .settings-filters-block-item {
    width: 50%;
  }
}



@media @exxSmall-widths {
  .settings-filters-block-genres .settings-filters-block-items {
    width: auto;
  }
   .settings-filters-block-genres .settings-filters-block-item {
    width: 100%;
  }
}
