.media-wrapper {
  padding-top: 30px;
  padding-bottom: 50px;
  background-color: #100f0f;
  border-bottom: 10px solid #212121;
}
.media {

}
.media-items {
  display: flex;
  flex-wrap: wrap;
}
.media-item {
  box-sizing: border-box;
  display: flex;
  width: ~"calc((100% - 20px) / 2)";
  min-height: 426px;
  margin-right: 20px;
  margin-bottom: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.media-item:nth-child(2n) {
  margin-right: 0;
}
.media-item-contact {
  background-size: contain;
}
.media-item-inner-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  padding: 50px 30px;
}
.media-item-contact-inner-wrapper {
  position: relative;
}
.media-item-title {
  font-size: 30px;
  font-weight: bold;
  color: #f4f3f3;
  text-align: center;
  text-transform: uppercase;
}
.media-item-text {
  min-height: 85px;
  padding: 15px 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: justify;
}
.media-item-btn-wrapper {

}
.media-item-btn {
  .btn();
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}
.media-item-contact-btn-wrapper {
  position: absolute;
  left: 0;
  top: 60%;
}
.media-item-contact-btn {
  position: relative;
  display: inline-block;
  padding-right: 24px;
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    width: 8px;
    height: 8px;
    background-image: url(../img/arrow.png);
    background-repeat: no-repeat;
    background-position: center;
  }
  &:hover {
    color: #ed1c24;
    text-decoration: none;
    &::before {
      background-image: url(../img/arrow-red.png);
    }
  }
}
.media-item .product-nav-tabs-social {
  width: auto;
  margin-left: 0;
  padding-top: 10px;
}



@media @xlarge-widths {
  .media-item {
    min-height: 360px;
  }
}



@media @large-widths {
  .media-item-text {
    font-size: 13px;
  }
}



@media @medium-widths {
  .media-item {
    min-height: 280px;
  }
  .media-item-title {
    font-size: 28px;
  }
  .media-item-text {
    min-height: 80px;
  }
}



@media @small-widths {
  .media-item-inner-wrapper {
    padding: 25px;
  }
  .media-item {
    min-height: 240px;
  }
  .media-item-title {
    font-size: 26px;
  }
  .media-item-text {
    font-size: 12px;
    min-height: 70px;
  }
}



@media @extraSmall-widths {
  .media-items {
    display: block;
    max-width: 500px;
    margin: 0 auto;
  }
  .media-item {
    width: 100%;
    margin-right: 0;
  }
  .media-item-title {
    font-size: 24px;
  }
}



@media @exxSmall-widths {
  .media-item-title {
    font-size: 22px;
  }
}
