.freebies-promo-wrapper {
  padding-top: 20px;
  padding-bottom: 50px;
  background-image: url("../img/freebies/freebies-img-bg.png");
  background-position: center;
  background-size: cover;
  background-color: #100f0f;
}
.freebies-promo {

}
.freebies-promo-title {
  margin-bottom: 15px;
}
.freebies-promo-descr {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: @color-text;
  text-align: center;
  text-transform: uppercase;
}



@media @medium-widths {
  .freebies-promo-descr {
    font-size: 15px;
  }
}



@media @extraSmall-widths {
  .freebies-promo-descr {
    font-size: 14px;
  }
}
