.faq-feedback {
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
  padding-top: 50px;
}
.faq-feedback-title {
  margin: 7px;
  font-size: 20px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
}
.faq-feedback-descr {
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: normal;
  color: #dc291e;
  text-transform: uppercase;
  text-align: center;
}
.faq-feedback-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.faq-feedback-btn {
  .btn();
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}
.faq-feedback-btn:nth-child(2) {
  margin-left: 10px;
}



@media @medium-widths {
  .faq-feedback-title {
    font-size: 18px;
  }
  .faq-feedback-descr {
    font-size: 15px;
  }
}



@media @extraSmall-widths {
  .faq-feedback-title {
    font-size: 16px;
  }
  .faq-feedback-descr {
    font-size: 14px;
  }
  .faq-feedback-form-content-textarea textarea {
    font-size: 14px;
  }
}



@media @exxSmall-widths {
  .faq-feedback-form-inner-wrapper {
    display: block;
  }
  .faq-feedback-form-img {
    margin: 0 auto;
  }
  .faq-feedback-form-content-textarea textarea {
    height: 60px;
  }
  .faq-feedback-btn-wrapper {
    flex-direction: column;
  }
  .faq-feedback-btn:nth-child(2) {
    margin-left: 0;
    margin-top: 10px;
  }
}
