.page-header__messages-wrapper {
  height: 0;
  overflow: hidden;
  transition: height .5s;
}
.page-header__messages {
  display: flex;
  border-bottom: 1px solid #3e3e3e;
}
.page-header__messages-title-block {
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 175px;
  padding: 20px 25px;
  background-color: #212121;
}
.page-header__messages-title {
  display: flex;
  align-items: center;
  margin-left: -7px;
  margin-bottom: 40px;
  font-size: 15px;
  font-weight: normal;
  color: #ed1c24;
  text-transform: uppercase;
}
.page-header__messages-title svg {
  position: relative;
  top: -3px;
}
.page-header__messages-title-btn {
  position: relative;
  padding-right: 25px;
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-decoration: none;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    width: 8px;
    height: 8px;
    background-image: url("../img/arrow.png");
    background-repeat: no-repeat;
    background-position: center;
  }
}
.page-header__messages-items {
  box-sizing: border-box;
  display: flex;
  width: ~"calc(100% - 165px)";
  padding: 20px;
}
.page-header__messages-item {
  position: relative;
  padding: 5px 35px;
  padding-right: 45px;
  border-right: 1px solid #3e3e3e;
}
.page-header__messages-item-btn-close {
  position: absolute;
  top: 3px;
  right: 10px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 7px;
    left: 3px;
    width: 9px;
    height: 1px;
    background-color: #f4f3f3;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}
.page-header__messages-item-header {
  display: flex;
  margin-bottom: 5px;
  font-family: 'Open Sans', sans-serif;
  font-size: 11px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.page-header__messages-item-header-by {
  display: flex;
  align-items: center;
  margin-right: auto;
}
.page-header__messages-item-header-by span {
  position: relative;
  margin-left: 3px;
  padding-left: 16px;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 12px;
    height: 14px;
    background-image: url("../img/icon-login-red.png");
    background-repeat: no-repeat;
    background-position: center;
  }
}
.page-header__messages-item-header-date {
  position: relative;
  padding-left: 17px;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 10px;
    height: 11px;
    background-image: url("../img/icon-calendar.png");
    background-repeat: no-repeat;
    background-position: center;
  }
}
.page-header__messages-item-text {
  margin-bottom: 5px;
  font-family: 'Open Sans', sans-serif;
  font-size: 11px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: justify;
}
.page-header__messages-item-footer {
  display: flex;
}
.page-header__messages-item-footer-btn-reply {
  position: relative;
  margin-left: auto;
  padding-right: 12px;
  font-size: 11px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-decoration: none;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    width: 8px;
    height: 8px;
    background-image: url("../img/arrow.png");
    background-repeat: no-repeat;
    background-position: center;
  }
}
.page-header__messages-item-footer-btn-more {
  position: relative;
  margin-left: 20px;
  padding-right: 12px;
  font-size: 11px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-decoration: none;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    width: 8px;
    height: 8px;
    background-image: url("../img/arrow.png");
    background-repeat: no-repeat;
    background-position: center;
  }
}



@media @extraSmall-widths {
  .page-header__messages-items {
    padding: 10px 0;
  }
  .page-header__messages-item {
    padding: 5px 25px;
    padding-right: 35px;
  }
}



@media @exxSmall-widths {
  .page-header__messages-title-block {
    width: 140px;
    padding: 20px 15px;
  }
  .page-header__messages-title {
    font-size: 12px;
  }
  .page-header__messages-title-btn {
    padding-right: 20px;
    font-size: 11px;
  }
  .page-header__messages-items {
    width: ~"calc(100% - 140px)";
  }
  .page-header__messages-item {
    padding: 25px;
  }
  .page-header__messages-item-header {
    display: block;
  }
  .page-header__messages-item-header-by {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 5px;
  }
  .page-header__messages-item-header-by span {
    margin-left: 0;
  }
}
