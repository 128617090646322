.page-header__podcast-wrapper {
  display: none;
  padding: 15px;
  border-bottom: 1px solid #292929;
}
.page-header__podcast {
  display: flex;
  align-items: center;
}
.page-header__podcast-name {
  padding-right: 50px;
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.page-header__podcast-player {
  flex-grow: 1;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin: 0 auto;
}
.page-header__podcast-player-btn-play {
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    top: 6px;
    left: 9px;
    width: 0;
    height: 0;
    border-left: 12px solid #f4f3f3;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
  }
}
.page-header__podcast-player-btn-pause {
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 7px;
    width: 2px;
    height: 16px;
    background-color: #f4f3f3;
  }
  &::before {
    left: 12px;
  }
  &::after {
    left: 16px;
  }
}
.page-header__podcast-player-track {
  position: relative;
  flex-grow: 1;
  height: 4px;
  background-color: #f4f3f3;
}
.page-header__podcast-player-track-current {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background-color: #ed1c24;
}
.page-header__podcast-other {
  position: relative;
  padding-right: 20px;
  padding-left: 60px;
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-decoration: none;
  transition: color .5s;
  &:hover {
    color: #dc291e;
    text-decoration: none;
  }
  &:focus {
    color: #dc291e;
    text-decoration: none;
  }
  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 8px;
    height: 8px;
    background-image: url("../img/arrow.png");
    background-repeat: no-repeat;
    background-position: center;
  }
}
.page-header__podcast-btn-close {
  position: relative;
  width: 30px;
  height: 30px;
  margin-left: 40px;
  cursor: pointer;
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 9px;
    top: 14px;
    width: 12px;
    height: 2px;
    background-color: #f4f3f3;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}



@media @extraSmall-widths {
  .page-header__podcast {
    flex-wrap: wrap;
  }
  .page-header__podcast-player {
    width: 100%;
    margin-top: 10px;
    order: 1;
  }
  .page-header__podcast-name {
    box-sizing: border-box;
    width: 40%;
    padding-right: 0;
  }
  .page-header__podcast-other {
    box-sizing: border-box;
    margin-left: auto;
    padding-left: 20px;
  }
}



@media @exxSmall-widths {
  .page-header__podcast {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .page-header__podcast-btn-close {
    order: -1;
    margin-left: auto;
  }
  .page-header__podcast-name {
    width: 100%;
  }
  .page-header__podcast-other {
    width: auto;
    margin-left: auto;
    padding-left: 0;
    order: 2;
  }
  .page-header__podcast-player {
    margin-bottom: 10px;
  }
}
