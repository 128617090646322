.product-promo-wrapper {
  background-color: #100f0f;
}
.product-promo {
  display: flex;
  max-width: 1700px;
  margin: 0 auto;
  padding: 15px 25px;
  padding-bottom: 55px;
}
.product-promo-gallery {
  width: ~"calc(100% - 625px)";
}
.product-promo-gallery-slider {

}
.product-promo-gallery-slide {

}
.product-promo-gallery-slide-img-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 59%;
}
.product-promo-gallery-slide-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-size: cover;
}
.product-promo-gallery-slide iframe {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.product-promo-descr {
  box-sizing: border-box;
  width: 625px;
  padding-left: 50px;
}
.product-promo-descr-name {
  font-size: 30px;
  font-weight: bold;
  color: #ed1c24;
  text-transform: uppercase;
}
.product-promo-descr-img-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 8px;
  padding-bottom: 47.5%;
}
.product-promo-descr-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-size: cover;
}
.product-promo-descr-presentation {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  padding: 24px 30px;
  background-color: #2b2a2a;
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.product-promo-descr-presentation-release {
  margin-right: auto;
  padding-right: 15px;
}
.product-promo-descr-presentation-watch {
  display: flex;
  align-items: center;
  margin-right: 16px;
  cursor: pointer;
  transition: color .5s;
  &:hover {
    color: #ed1c24;
  }
}
.product-promo-descr-presentation-watch-icon {
  position: relative;
  width: 20px;
  height: 14px;
  margin-right: 7px;
}
.product-promo-descr-presentation-watch-icon .img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: opacity .5s;
}
.product-promo-descr-presentation-watch-icon .img-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity .5s;
}
.product-promo-descr-presentation-watch:hover .img {
  opacity: 0;
}
.product-promo-descr-presentation-watch:hover .img-hover {
  opacity: 1;
}
.product-promo-descr-presentation-like {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color .5s;
  &:hover {
    color: #ed1c24;
  }
}
.product-promo-descr-presentation-like-icon {
  position: relative;
  width: 15px;
  height: 14px;
  margin-right: 7px;
}
.product-promo-descr-presentation-like-icon .img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: opacity .5s;
}
.product-promo-descr-presentation-like-icon .img-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity .5s;
}
.product-promo-descr-presentation-like:hover .img {
  opacity: 0;
}
.product-promo-descr-presentation-like:hover .img-hover {
  opacity: 1;
}
.product-promo-descr-info {
  display: flex;
  background-color: #2b2a2a;
}
.product-promo-descr-info-setting {
  box-sizing: border-box;
  position: relative;
  width: 205px;
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
}
.product-promo-descr-info-setting-stock {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 10px 25px;
  background-image: url("../img/product/product-promo-descr-stock-bg.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  white-space: nowrap;
}
.product-promo-descr-info-setting-platform {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 175px;
  margin-bottom: 3px;
  padding: 10px;
  padding-top: 40px;
  background-color: #3a3a3a;
  border-bottom: 1px solid #555555;
}
.product-promo-descr-info-setting-platform-text {
  margin-bottom: 15px;
}
.product-promo-descr-info-setting-platform-icon {
  width: 34px;
}
.product-promo-descr-info-setting-platform-icon img {
  display: block;
  width: 100%;
}
.product-promo-descr-info-setting-system {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 175px;
  margin-bottom: 3px;
  padding: 10px;
  padding-top: 40px;
  background-color: #3a3a3a;
  border-bottom: 1px solid #555555;
}
.product-promo-descr-info-setting-system-text {
  margin-bottom: 15px;
}
.product-promo-descr-info-setting-system-icons {
  display: flex;
  align-items: center;
}
.product-promo-descr-info-setting-system-icon {
  flex-shrink: 0;
  margin-right: 12px;
}
.product-promo-descr-info-setting-system-icon:last-child {
  margin-right: 0;
}
.product-promo-descr-info-setting-system-icon img {
  display: block;
  width: 100%;
}
.product-promo-descr-info-price {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.product-promo-descr-info-price-rrp {
  font-size: 20px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
  text-decoration: line-through;
}
.product-promo-descr-info-price-our {
  font-size: 25px;
  font-weight: bold;
  color: #ed1c24;
  text-transform: uppercase;
  text-align: center;
}
.product-promo-descr-info-price-points {
  margin-bottom: 50px;
  font-size: 20px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
}
.product-promo-descr-info-price-btn-wrapper {
  display: flex;
  flex-direction: column;
  // align-items: center;
}
.product-promo-descr-info-price-btn-add-to-card {
  .btn();
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 35px;
  margin-bottom: 17px;
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}
.product-promo-descr-info-price-btn-add-to-card-text {
  margin-right: 5px;
}
.product-promo-descr-info-price-btn-add-to-card-icon {
  display: block;
  width: 40px;
}
.product-promo-descr-info-price-btn-add-to-card-icon img {
  display: block;
  width: 100%;
}
.product-promo-descr-info-price-btn-add-to-wishlist {
  .btn();
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}
.product-promo-descr-info-price-btn-add-to-wishlist-text {
  margin-right: 8px;
}
.product-promo-descr-info-price-btn-add-to-wishlist-icon {
  position: relative;
  width: 8px;
  height: 8px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background-color: #f4f3f3;
  }
  &::before {
    left: 0;
    top: 3px;
    width: 100%;
    height: 2px;
  }
  &::after {
    left: 3px;
    top: 0;
    width: 2px;
    height: 100%;
  }
}



@media @xxlarge-widths {
  .product-promo-gallery {
    width: ~"calc(100% - 570px)";
  }
  .product-promo-descr {
    width: 570px;
    padding-left: 30px;
  }
}



@media @xlarge-widths {
  .product-promo-gallery {
    width: ~"calc(100% - 440px)";
  }
  .product-promo-descr {
    width: 440px;
    padding-left: 20px;
  }
  .product-promo-descr-info-setting {
    width: 180px;
  }
}



@media @large-widths {
  .product-promo-gallery {
    width: ~"calc(100% - 400px)";
  }
  .product-promo-descr {
    width: 400px;
    padding-left: 20px;
  }
  .product-promo-descr-info-setting {
    width: 160px;
  }
  .product-promo-descr-info-setting-platform {
    min-height: 140px;
  }
  .product-promo-descr-info-setting-system {
    min-height: 140px;
  }
  .product-promo-descr-info-price-rrp {
    font-size: 18px;
  }
  .product-promo-descr-info-price-our {
    font-size: 22px;
  }
  .product-promo-descr-info-price-points {
    font-size: 18px;
  }
}



@media @medium-widths {
  .product-promo-gallery {
    width: ~"calc(100% - 350px)";
  }
  .product-promo-descr {
    width: 350px;
    padding-left: 20px;
  }
  .product-promo-descr-presentation {
    padding: 15px 20px;
  }
  .product-promo-descr-info-setting-platform {
    min-height: 130px;
  }
  .product-promo-descr-info-setting-system {
    min-height: 130px;
  }
  .product-promo-descr-info-price-rrp {
    font-size: 16px;
  }
  .product-promo-descr-info-price-our {
    font-size: 20px;
  }
  .product-promo-descr-info-price-points {
    font-size: 16px;
  }
}



@media @small-widths {
  .product-promo {
    flex-direction: column-reverse;
  }
  .product-promo-gallery {
    width: 100%;
  }
  .product-promo-descr {
    width: 100%;
    margin-bottom: 20px;
    padding-left: 0;
  }
  .product-promo-descr-inner-wrapper {
    display: flex;
    align-items: center;
  }
  .product-promo-descr-inner-wrapper-block1 {
    width: ~"calc((100% - 10px) / 2)";
    margin-right: 10px;
  }
  .product-promo-descr-inner-wrapper-block2 {
    width: ~"calc((100% - 10px) / 2)";
  }
}



@media @extraSmall-widths {
  .product-promo-descr {
    max-width: 350px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .product-promo-descr-inner-wrapper {
    display: block;
  }
  .product-promo-descr-inner-wrapper-block1 {
    width: 100%;
    margin-right: 0;
  }
  .product-promo-descr-inner-wrapper-block2 {
    width: 100%;
  }
}



@media @exxSmall-widths {
  .product-promo-descr-name {
    font-size: 24px;
  }
  .product-promo-descr-presentation {
    padding: 10px 12px;
    font-size: 10px;
  }
  .product-promo-descr-info-setting-stock {
    padding: 7px 20px;
    font-size: 10px;
  }
  .product-promo-descr-info-setting-system-icons {
    flex-wrap: wrap;
    justify-content: center;
  }
  .product-promo-descr-info-setting-system-icon {
    margin: 0 10px;
    margin-bottom: 7px;
  }
  .product-promo-descr-info-setting-system-icon:last-child {
    margin: 0 10px;
    margin-bottom: 7px;
  }
  .product-promo-descr-info-price-rrp {
    font-size: 14px;
  }
  .product-promo-descr-info-price-our {
    font-size: 16px;
  }
  .product-promo-descr-info-price-points {
    font-size: 14px;
  }
  .product-promo-descr-info-price-btn-add-to-card,
  .product-promo-descr-info-price-btn-add-to-wishlist {
    min-width: 140px;
    padding: 9px 10px;
  }
}
