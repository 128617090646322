.giveaways-single-reviews-wrapper {
  padding: 45px 0;
}
.giveaways-single-reviews {
  max-width: 1170px;
  margin: 0 auto;
}



@media @xxlarge-widths {

}



@media @xlarge-widths {

}



@media @large-widths {

}



@media @medium-widths {

}



@media @small-widths {
  .giveaways-single-reviews-wrapper {
    padding: 30px 0;
  }
}



@media @extraSmall-widths {

}



@media @exxSmall-widths {
  .giveaways-single-reviews-wrapper {
    padding: 20px 0;
  }
}
