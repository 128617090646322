.items-settings-wrapper {
  padding: 0 15px;
}
.items-settings {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  max-width: 1640px;
  margin: 0 auto;
  padding: 20px;
}
.items-settings-btn-wrapper {
  box-sizing: border-box;
  display: flex;
  width: 30%;
}
.items-settings-btn {
  .btn();
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 34px;
  border: 2px solid #f4f3f3;
  &:hover {
    background-color: #dc291e;
    border: 2px solid #dc291e;
  }
}
.items-settings-btn span {
  margin-right: 12px;
}
.items-settings-btn span img {
  display: block;
  width: 100%;
}
.items-settings-per-page {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  margin: 0 auto;
  padding: 0 20px;
  font-size: 16px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.items-settings-per-page-current {
  position: relative;
  padding-right: 30px;
  text-align: center;
  cursor: pointer;
  &::before,
  &::after {
    content: "";
    position: absolute;
    right: 7px;
    top: 9px;
    width: 2px;
    height: 5px;
    transform-origin: 50% .5px;
    background-color: #ed1c24;
    transition: top .5s, transform .5s;
  }
  &::before {
    transform: rotate(135deg);
  }
  &::after {
    transform: rotate(-135deg);
  }
}
.items-settings-per-page--opened .items-settings-per-page-current {
  &::before,
  &::after {
    top: 6px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}
.items-settings-per-page-items-wrapper {
  position: absolute;
  top: ~"calc(100% + 7px)";
  left: 50%;
  transform: translate(-50%, 0);
  height: 0;
  padding: 0;
  overflow: hidden;
}
.items-settings-per-page--opened .items-settings-per-page-items-wrapper {
  height: auto;
  padding: 1px;
}
.items-settings-per-page-items {
  box-sizing: border-box;
  width: 100%;
  transform: translate(0, -100%);
  padding: 5px 0;
  background-color: rgba(16, 15, 15, 0.88);
  border: 1px solid #dc291e;
  transition: transform .5s;
}
.items-settings-per-page--opened .items-settings-per-page-items {
  transform: translate(0, 0);
}
.items-settings-per-page-item {
  padding: 5px 10px;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    background-color: rgba(42, 40, 40, 0.88);
  }
}
.items-settings-per-page-item--active {
  background-color: rgba(42, 40, 40, 0.88);
}
.items-settings-info {
  box-sizing: border-box;
  width: 30%;
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: right;
}



@media @small-widths {
  .items-settings {
    flex-wrap: wrap;
  }
  .items-settings-btn-wrapper {
    width: auto;
    margin-right: auto;
  }
  .items-settings-per-page {
    width: 100%;
    margin-top: 15px;
    font-size: 14px;
    order: 1;
  }
  .items-settings-per-page-current {
    width: 200px;
  }
  .items-settings-info {
    width: auto;
  }
}



@media @exxSmall-widths {
  .items-settings {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .items-settings-btn-wrapper {
    width: auto;
    margin-right: 0;
    margin-bottom: 15px;
  }
}
