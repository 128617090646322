.quests-promo-wrapper {
  background-image: url("../img/quests/quests-promo-bg.jpg");
  background-position: center;
  background-size: cover;
}
.quests-promo {
  padding: 30px 0;
}
.quests-promo-header {
  position: relative;
  margin-bottom: 20px;
}
.quests-promo-header-title {
  font-size: 25px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
}
.quests-promo-header-points {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
}
.quests-promo-header-points-img {
  width: 50px;
  margin-right: 24px;
}
.quests-promo-header-points-img img {
  display: block;
  width: 100%;
}
.quests-promo-header-points-descr {
  margin-right: 12px;
}
.quests-promo-header-points-descr-text {
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.quests-promo-header-points-descr-num {
  font-size: 25px;
  font-weight: bold;
  color: #ed1c24;
  text-transform: uppercase;
}
.quests-promo-header-points-btn {
  width: 21px;
}
.quests-promo-header-points-btn img {
  display: block;
  width: 100%;
}
.quests-promo-content {
  display: flex;
}
.quests-promo-content-img1 {
  flex-shrink: 0;
  align-self: center;
  width: 230px;
}
.quests-promo-content-img1 img {
  display: block;
  width: 100%;
}
.quests-promo-content-text {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 75px;
  margin-right: 40px;
  padding: 35px;
  background-image: url("../img/quests/quests-promo-img-bg-say.jpg");
  background-position: center;
  background-size: cover;
  border: 4px solid #f9cb47;
  &::before {
    content: "";
    position: absolute;
    width: 55px;
    height: 42px;
    background-image: url("../img/quests/quests-promo-icon-say.png");
    background-position: center;
    background-repeat: no-repeat;
    left: 0px;
    top: 50%;
    transform: translate(-100%, -50%);
  }
}
.quests-promo-content-text-title {
  font-family: "Stadium 1946", sans-serif;
  font-size: 86px;
  font-weight: normal;
  color: #ed1c24;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0px 7px 0 #000000;
}
.quests-promo-content-text-descr {
  font-size: 45px;
  font-weight: bold;
  line-height: .95;
  color: #f4f3f3;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0px 6px 0 #000000;
}
.quests-promo-content-trove-wrapper {
  display: flex;
}
.quests-promo-content-trove {
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 290px;
  padding: 17px;
  background-image: url("../img/quests/quests-promo-img2.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 1px solid transparent;
  transition: border .5s;
  &:hover {
    border: 1px solid #ed1c24;
  }
}
.quests-promo-content-trove-descr {
  font-size: 25px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
}
.quests-promo-content-trove:hover {
  text-decoration: none;
}
.quests-promo-content-trove-btn-wrapper {
  margin-top: auto;
}
.quests-promo-content-trove-btn {
  .btn();
  border: 2px solid #100f0f;
  color: #100f0f;
  font-weight: bold;
}
.quests-promo-content-trove:hover .quests-promo-content-trove-btn {
  border: 2px solid #dc291e;
  background-color: #dc291e;
  color: #f4f3f3;

}



@media @xlarge-widths {
  .quests-promo-content-img1 {
    width: 200px;
  }
  .quests-promo-content-text-title {
    font-size: 65px;
  }
  .quests-promo-content-text-descr {
    font-size: 34px;
  }
  .quests-promo-content-trove-descr {
    font-size: 24px;
  }
}



@media @large-widths {
  .quests-promo-content-img1 {
    width: 180px;
  }
  .quests-promo-content-text-title {
    font-size: 56px;
  }
  .quests-promo-content-text-descr {
    font-size: 30px;
    line-height: 1;
  }
  .quests-promo-content-trove {
    width: 250px;
  }
  .quests-promo-content-trove-descr {
    font-size: 22px;
  }
}



@media @medium-widths {
  .quests-promo-content-img1 {
    width: 160px;
  }
  .quests-promo-content-text {
    padding: 25px;
  }
  .quests-promo-content-text-title {
    font-size: 44px;
  }
  .quests-promo-content-text-descr {
    font-size: 24px;
    line-height: 1.1;
  }
  .quests-promo-content-trove {
    width: 220px;
  }
  .quests-promo-content-trove-descr {
    font-size: 18px;
  }
}



@media @small-widths {
  .quests-promo-content {
    flex-wrap: wrap;
    justify-content: center;
  }
  .quests-promo-content-text {
    width: ~"calc(100% - 160px - 55px)";
    margin-right: 0;
    margin-left: 55px;
    &::before {
      width: 30px;
      height: 42px;
      background-image: url("../img/quests/quests-promo-icon-say.png");
      background-position: right center;
      background-repeat: no-repeat;
      background-size: 85% auto;
      left: 0px;
      top: 50%;
      transform: translate(-100%, -50%);
    }
  }
  .quests-promo-content-trove-wrapper {
    justify-content: center;
    width: 100%;
    order: -1;
  }
  .quests-promo-content-trove {
    min-height: 220px;
    margin-bottom: 20px;
  }
}



@media @extraSmall-widths {
  .quests-promo-header-title {
    margin-bottom: 20px;
  }
  .quests-promo-header-points {
    position: static;
    transform: translate(0, 0);
    justify-content: center;
  }
  .quests-promo-content-img1 {
    width: 100px;
  }
  .quests-promo-content-text {
    width: ~"calc(100% - 100px - 30px)";
    margin-left: 30px;
    padding: 20px;
  }
  .quests-promo-content-text-title {
    font-size: 36px;
  }
  .quests-promo-content-text-descr {
    font-size: 20px;
  }
}



@media @exxSmall-widths {
  .quests-promo-content-img1 {
    width: 80px;
  }
  .quests-promo-content-text {
    width: ~"calc(100% - 80px - 30px)";
    margin-left: 30px;
    padding: 15px;
  }
  .quests-promo-content-text-title {
    font-size: 28px;
  }
  .quests-promo-content-text-descr {
    font-size: 16px;
  }
}
