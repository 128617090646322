.about-history-wrapper {
  padding-top: 35px;
  padding-bottom: 50px;
  background-color: #100f0f;
  border: 2px solid #212121;
}
.about-history {

}
.about-history-logo {
  width: 162px;
  margin: 0  auto;
  margin-bottom: 25px;
}
.about-history-logo img {
  display: block;
  width: 100%;
}
.about-history .accordion-item-title-let {
  width: 75px;
}
.about-history .accordion-item-body-text {
  padding-left: 85px;
}
.about-history .accordion-item-body-text p {
  margin-bottom: 20px;
  text-align: justify;
  &:last-child {
    margin-bottom: 0;
  }
}
.about-history .accordion-item-body-text-title {
  margin-bottom: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: @color-text-hover;
  text-transform: uppercase;
  text-align: center;
}
.about-history .accordion-item-body-img {
  width: 110px;
  margin: 0 auto;
  margin-bottom: 25px;
}
.about-history .accordion-item-body-img img {
  display: block;
  width: 100%;
}



@media @extraSmall-widths {
  .about-history .accordion-item-title-let {
    width: 75px;
    font-size: 36px;
  }
  .about-history .accordion-item-title-text {
    font-size: 14px;
  }
  .about-history .accordion-item-body-text {
    padding-left: 60px;
  }
  .about-history .accordion-item-body-text-title {
    font-size: 13px;
  }
  .about-history .accordion-item-body-img {
    width: 90px;
    margin-bottom: 15px;
  }
}



@media @exxSmall-widths {
  .about-history .accordion-item-title-let {
    width: 50px;
    font-size: 26px;
  }
  .about-history .accordion-item-title-text {
    font-size: 12px;
  }
  .about-history .accordion-item-body-text {
    padding-left: 15px;
    padding-right: 15px;
  }
  .about-history .accordion-item-body-text {
    font-size: 12px;
  }
}
