.page-header__info {
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  width: 280px;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  text-transform: uppercase;
}
.page-header--fixed .page-header__info {
  position: relative;
  flex-wrap: nowrap;
  width: auto;
  padding-left: 25px;
  padding-right: 17px;
  font-size: 0;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
    width: 1px;
    height: 37px;
    background-color: #3e3e3e;
  }
}
.page-header__info-user-online {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 60%;
  height: 65px;
  padding: 10px 0;
  padding-left: 25px;
  padding-right: 20px;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
    width: 1px;
    height: 37px;
    background-color: #3e3e3e;
  }
}
.page-header--fixed .page-header__info-user-online {
  width: auto;
  padding: 0;
  font-size: 0;
  order: 2;
  &::before {
    display: none;
  }
}
.page-header__info-user-online span {
  margin-left: 3px;
  margin-right: 5px;
  color: #dc291e;
}
.page-header--fixed .page-header__info-user-online span {
  margin-left: 0;
  margin-right: 0;
}
.page-header__info-user-online-icon {
  width: 30px;
  height: 30px;
}
.page-header__info-user-online-icon svg {
  display: block;
  width: 100%;
  height: 100%;
  fill: #ffffff;
}
.page-header__info-servers {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 40%;
  height: 65px;
  padding: 10px 0;
  padding-right: 25px;
  padding-left: 22px;
}
.page-header--fixed .page-header__info-servers {
  width: auto;
  padding: 0;
  order: 3;
}
.page-header__info-servers-num {
  margin-right: 4px;
}
.page-header__info-servers-toggle svg {
  fill: #ffffff;
}
.page-header__info-podcast {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  height: 65px;
  padding: 10px 25px;
  border-top: 1px solid #292929;
  cursor: pointer;
}
.page-header--fixed .page-header__info-podcast {
  width: auto;
  padding: 0;
  border-top: none;
  font-size: 0;
  order: 1;
}
.page-header__info-podcast-toggle {
  margin-right: 3px;
  transition: color .5s;
}
.page-header__info-podcast:hover .page-header__info-podcast-toggle {
  color: #dc291e;
}
.page-header__info-podcast-toggle-icon {
  display: none;
  transition: fill .5s;
}
.page-header__info-podcast-toggle--opened svg,
.page-header__info-podcast:hover .page-header__info-podcast-toggle-icon {
  fill: #dc291e;
}
.page-header--fixed .page-header__info-podcast-toggle-icon {
  display: block;
  fill: #ffffff;
}
.page-header__info-podcast-icon {
  fill: #ffffff;
  transition: fill .5s;
}
.page-header__info-podcast:hover .page-header__info-podcast-icon {
  fill: #dc291e;
}
.page-header--fixed .page-header__info-podcast-icon {
  display: none;
}



@media @xxlarge-widths {
  .page-header__info {
    width: 260px;
  }
}



@media @xlarge-widths {
  .page-header__info {
    width: 240px;
  }
}



@media @large-widths {
  .page-header__info {
    width: 210px;
    font-size: 10px;
  }
}



@media @medium-widths {
  .page-header__info {
    width: 200px;
  }
}



@media @small-widths {
  .page-header__info {
    width: auto;
    font-size: 0;
  }
  .page-header--fixed .page-header__info {
    &::before {
      display: none;
    }
  }
  .page-header--fixed .page-header__info {
    padding: 0 5px;
  }
  .page-header__info-user-online {
    display: none;
  }
  .page-header__info-servers {
    display: none;
  }
  .page-header__info-podcast {
    border-top: none;
    padding: 0 5px;
  }
  .page-header__info-podcast-toggle-icon {
    display: block;
    fill: #ffffff;
  }
  .page-header__info-podcast-icon {
    display: none;
  }
}



// @media @extraSmall-widths {
//
// }
// @media @exxSmall-widths {
//
// }
