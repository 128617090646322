.page-footer {
  background-color: #100f0f;
}
.page-footer-nav-wrapper {

}
.page-footer-nav {
  display: flex;
  padding-top: 50px;
  padding-bottom: 25px;
  border-bottom: 1px solid #5b5a5a;
}
.page-footer-nav-general {
  box-sizing: border-box;
  width: ~"calc(100% / 6)";
  padding: 10px 0;
  padding-right: 30px;
  border-right: 1px solid #5b5a5a;
}
.page-footer-nav-general-title {
  margin-bottom: 35px;
  font-size: 15px;
  font-weight: normal;
  color: #c8261d;
  text-transform: uppercase;
}
.page-footer-nav-general-items {

}
.page-footer-nav-general-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-decoration: none;
  transition: color .5s;
  &:hover {
    color: #dc291e;
    text-decoration: none;
  }
  &:focus {
    color: #f4f3f3;
    text-decoration: none;
  }
}
.page-footer-nav-navigation {
  box-sizing: border-box;
  width: ~"calc(100% / 6 * 3 / 2)";
  padding: 10px 30px;
  border-right: 1px solid #5b5a5a;
}
.page-footer-nav-navigation-title {
  margin-bottom: 35px;
  font-size: 15px;
  font-weight: normal;
  color: #c8261d;
  text-transform: uppercase;
}
.page-footer-nav-navigation-items {
  display: flex;
  flex-wrap: wrap;
}
.page-footer-nav-navigation-item {
  box-sizing: border-box;
  width: 50%;
  margin-bottom: 10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-decoration: none;
  transition: color .5s;
  &:hover {
    color: #dc291e;
    text-decoration: none;
  }
  &:focus {
    color: #f4f3f3;
    text-decoration: none;
  }
}
.page-footer-nav-navigation-item:nth-child(2n) {
  padding-left: 30px;
}
.page-footer-nav-personal {
  box-sizing: border-box;
  width: ~"calc(100% / 6 * 3 / 2)";
  padding: 10px 30px;
  border-right: 1px solid #5b5a5a;
}
.page-footer-nav-personal-title {
  margin-bottom: 35px;
  font-size: 15px;
  font-weight: normal;
  color: #c8261d;
  text-transform: uppercase;
}
.page-footer-nav-personal-items {
  display: flex;
  flex-wrap: wrap;
}
.page-footer-nav-personal-item {
  box-sizing: border-box;
  width: 50%;
  margin-bottom: 10px;
  font-family: 'Open Sans', sans-serif;
  font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-decoration: none;
  transition: color .5s;
  &:hover {
    color: #dc291e;
    text-decoration: none;
  }
  &:focus {
    color: #f4f3f3;
    text-decoration: none;
  }
}
.page-footer-nav-personal-item:nth-child(2n) {
  padding-left: 30px;
}
.page-footer-nav-gaming {
  box-sizing: border-box;
  width: ~"calc(100% / 6)";
  padding: 10px 30px;
  border-right: 1px solid #5b5a5a;
}
.page-footer-nav-gaming-title {
  margin-bottom: 35px;
  font-size: 15px;
  font-weight: normal;
  color: #c8261d;
  text-transform: uppercase;
}
.page-footer-nav-gaming-items {
  display: flex;
  flex-direction: column;
}
.page-footer-nav-gaming-item {
  margin-bottom: 10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-decoration: none;
  transition: color .5s;
  &:hover {
    color: #dc291e;
    text-decoration: none;
  }
  &:focus {
    color: #f4f3f3;
    text-decoration: none;
  }
}
.page-footer-nav-pay {
  box-sizing: border-box;
  width: ~"calc(100% / 6)";
  padding: 10px 0;
  padding-left: 30px;
}
.page-footer-nav-pay-payment {
  margin-bottom: 18px;
  padding-bottom: 25px;
  border-bottom: 1px solid #5b5a5a;
}
.page-footer-nav-pay-payment-title {
  margin-bottom: 17px;
  font-size: 15px;
  font-weight: normal;
  color: #c8261d;
  text-transform: uppercase;
}
.page-footer-nav-pay-payment-img {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
}
.page-footer-nav-pay-payment-img img {
  display: block;
  width: 100%;
}
.page-footer-nav-pay-discord {

}
.page-footer-nav-pay-discord-title {
  margin-bottom: 17px;
  font-size: 15px;
  font-weight: normal;
  color: #c8261d;
  text-transform: uppercase;
}
.page-footer-nav-pay-discord-img {
  box-sizing: border-box;
  width: 100%;
}
.page-footer-nav-pay-discord-img img {
  display: block;
  width: 100%;
}
.page-footer-block-wrapper {

}
.page-footer-block {
  display: flex;
  align-items: center;
  padding: 12px 0;
}
.page-footer-block-copyright {
  width: 30%;
  margin-right: auto;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.page-footer-block-img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  margin: 0 auto;
}
.page-footer-block-logo {
  width: 78px;
  margin-right: 12px;
}
.page-footer-block-logo img {
  display: block;
  width: 100%;
}
.page-footer-block-ssl {
  width: 56px;
}
.page-footer-block-ssl img {
  display: block;
  width: 100%;
}
.page-footer-block-trustpilot {
  width: 100px;
  margin-left: 12px;
}
.page-footer-block-trustpilot img {
  display: block;
  width: 100%;
}
.page-footer-block-social-items {
  display: flex;
  align-items: center;
  width: 30%;
  margin-left: auto;
}
.page-footer-block-social-item {
  position: relative;
  margin-left: 17px;
}
.page-footer-block-social-item-fb {
  width: 14px;
  height: 16px;
}
.page-footer-block-social-item-tw {
  width: 18px;
  height: 16px;
}
.page-footer-block-social-item-youtube {
  width: 23px;
  height: 16px;
}
.page-footer-block-social-item-twitch {
  width: 14px;
  height: 16px;
}
.page-footer-block-social-item-d {
  width: 22px;
  height: 16px;
}
.page-footer-block-social-item-steam {
  width: 16px;
  height: 16px;
}
.page-footer-block-social-item:first-child {
  margin-left: auto;
}
.page-footer-block-social-item-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: opacity .5s;
}
.page-footer-block-social-item-img-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity .5s;
}
.page-footer-block-social-item:hover .page-footer-block-social-item-img {
  opacity: 0;
}
.page-footer-block-social-item:hover .page-footer-block-social-item-img-hover {
  opacity: 1;
}



@media @large-widths {
  .page-footer-nav-general {
    padding-right: 25px;
  }
  .page-footer-nav-navigation {
    padding: 10px 25px;
  }
  .page-footer-nav-personal {
    padding: 10px 25px;
  }
  .page-footer-nav-gaming {
    padding: 10px 25px;
  }
  .page-footer-nav-pay {
    padding-left: 25px;
  }
}



@media @medium-widths {
  .page-footer-nav {
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 35px;
    padding-bottom: 0;
  }
  .page-footer-nav-general {
    min-width: 190px;
    margin-bottom: 20px;
  }
  .page-footer-nav-navigation {
    min-width: 285px;
    margin-bottom: 20px;
  }
  .page-footer-nav-personal {
    min-width: 285px;
    margin-bottom: 20px;
    border-right: none;
  }
  .page-footer-nav-gaming {
    min-width: 190px;
    margin-bottom: 20px;
  }
  .page-footer-nav-pay {
    min-width: 190px;
    margin-bottom: 20px;
  }
}



@media @small-widths {
  .page-footer-nav-personal {
    border-right: 1px solid #5b5a5a;
  }
  .page-footer-nav-navigation {
    border-right: none;
  }
  .page-footer-block {
    flex-wrap: wrap;
    justify-content: center;
  }
  .page-footer-block-copyright {
    width: auto;
    margin-right: 0;
    font-size: 11px;
  }
  .page-footer-block-img-wrapper {
    width: 100%;
    margin-bottom: 10px;
    order: -1;
  }
  .page-footer-block-social-items {
    width: auto;
    margin-left: 35px;
  }
}



@media (max-width: 720px) {
  .page-footer-nav {
    flex-direction: column;
    align-items: center;
  }
  .page-footer-nav-block {
    padding: 0 15px;
  }
  .page-footer-nav-block-title {
    margin-bottom: 15px;
    text-align: center;
    cursor: pointer;
  }
  .page-footer-nav-block-items {
    display: none;
    padding: 0 15px;
  }
  .page-footer-nav-block-items a {
    display: block;
    width: 100%;
    text-align: center;
  }
  .page-footer-nav-block-items a:nth-child(2n) {
    padding-left: 0;
  }
  .page-footer-nav-general {
    border-right: none;
  }
  .page-footer-nav-personal {
    border-right: none;
  }
  .page-footer-nav-gaming {
    border-right: none;
  }
  .page-footer-nav-pay {
    padding: 0 15px;
  }
  .page-footer-nav-pay-payment-title {
    text-align: center;
  }
  .page-footer-nav-pay-payment-img1,
  .page-footer-nav-pay-payment-img2,
  .page-footer-nav-pay-discord-img {
    margin-left: auto;
    margin-right: auto;
  }
  .page-footer-nav-pay-discord-title {
    text-align: center;
  }
  .page-footer-block-copyright {
    margin-bottom: 10px;
  }
  .page-footer-block-social-items {
    margin-bottom: 10px;
  }
}



@media @exxSmall-widths {
  .page-footer-block-copyright {
    width: 100%;
    text-align: center;
  }
  .page-footer-block-social-items {
    justify-content: center;
    width: 100%;
    margin-left: 0;
    margin-bottom: 0;
  }
  .page-footer-block-social-item:first-child {
    margin-left: 0;
  }
}
