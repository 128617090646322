.giveaways-page-wrapper {
  background-color: #100f0f;
  border-bottom: 10px solid #212121;
  padding-bottom: 55px;
}
.giveaways-page-title {
  padding: 12px 15px;
  font-size: 25px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
}
.giveaways-page-promo {
  position: relative;
  padding-right: 190px;
}
.giveaways-page-promo-slider-wrapper {

}
.giveaways-page-promo-slider {

}
.giveaways-page-promo-slide {
  display: flex;
  align-items: center;
  padding: 9px 65px;
  background-position: center;
  background-size: cover;
}
.giveaways-page-promo-slide-img {
  flex-shrink: 0;
  width: 100%;
  max-width: 380px;
}
.giveaways-page-promo-slide-img img {
  display: block;
  width: 100%;
}
.giveaways-page-promo-slide-icons {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  padding: 15px 35px;
}
.giveaways-page-promo-slide-icon {

}
.giveaways-page-promo-slide-icon-steam {
  width: 55px;
  margin-bottom: 25px;
}
.giveaways-page-promo-slide-icon-for-all {
  width: 46px;
}
.giveaways-page-promo-slide-icon img {
  display: block;
  width: 100%;
}
.giveaways-page-promo-slide-descr {
  flex-grow: 1;
}
.giveaways-page-promo-slide-descr-title {
  font-size: 35px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
  text-shadow: 4px 5px 0 #000000;
}
.giveaways-page-promo-slide-descr-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: justify;
}
.giveaways-page-promo-slide-time-left {
  flex-shrink: 0;
  margin-left: 50px;
}
.giveaways-page-promo-slide-time-left-title {
  font-size: 12px;
  font-weight: bold;
  color: #f4f3f3;
  text-align: center;
  text-transform: uppercase;
}
.giveaways-page-promo-slide-time-left-inner-block {
  display: flex;
}
.giveaways-page-promo-slide-time-left-weeks {
  margin-right: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
}
.giveaways-page-promo-slide-time-left-weeks span {
  margin-right: 3px;
  font-family: "Stadium 1946", sans-serif;
  font-size: 50px;
  font-weight: normal;
  color: #dc291e;
}
.giveaways-page-promo-slide-time-left-days {
  font-size: 16px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
}
.giveaways-page-promo-slide-time-left-days span {
  margin-right: 3px;
  font-family: "Stadium 1946", sans-serif;
  font-size: 50px;
  font-weight: normal;
  color: #dc291e;
}
.giveaways-page-promo-slide-time-left-time {
  font-size: 35px;
  font-weight: bold;
  color: #f4f3f3;
  text-align: center;
}
.giveaways-page-promo-slider .owl-nav .owl-prev {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translate(0, -50%);
  width: 31px;
  height: 30px;
  font-size: 0;
  cursor: pointer;
  z-index: 100;
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 12px;
    top: 14px;
    width: 14px;
    height: 2px;
    transform-origin: 1px 50%;
    background-color: #ffffff;
  }
  &::after {
    transform: rotate(50deg);
  }
  &::before {
    transform: rotate(-50deg);
  }
}
.giveaways-page-promo-slider .owl-nav .owl-next {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0px, -50%);
  width: 31px;
  height: 30px;
  font-size: 0;
  cursor: pointer;
  z-index: 100;
  &::before,
  &::after {
    content: "";
    position: absolute;
    right: 12px;
    top: 14px;
    width: 14px;
    height: 2px;
    transform-origin: 13px 50%;
    background-color: #ffffff;
  }
  &::before {
    transform: rotate(50deg);
  }
  &::after {
    transform: rotate(-50deg);
  }
}
.giveaways-page-promo-img {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 157px;
}
.giveaways-page-promo-img img {
  display: block;
  width: 100%;
}



@media @xlarge-widths {
  .giveaways-page-promo-slide-img {
    max-width: 300px;
  }
  .giveaways-page-promo-slide-icons {
    padding: 10px 20px;
  }
  .giveaways-page-promo-slide-icon-steam {
    width: 50px;
  }
  .giveaways-page-promo-slide-icon-for-all {
    width: 42px;
  }
  .giveaways-page-promo-slide-descr-title {
    font-size: 32px;
  }
  .giveaways-page-promo-slide-time-left {
    margin-left: 25px;
  }
  .giveaways-page-promo-slide-time-left-time {
    font-size: 32px;
  }
}



@media @large-widths {
  .giveaways-page-promo-slide-img {
    max-width: 280px;
  }
  .giveaways-page-promo-slide-icons {
    padding: 10px 15px;
  }
  .giveaways-page-promo-slide-icon-steam {
    width: 40px;
  }
  .giveaways-page-promo-slide-icon-for-all {
    width: 34px;
  }
  .giveaways-page-promo-slide-descr-title {
    font-size: 28px;
  }
  .giveaways-page-promo-slide-time-left {
    margin-left: 20px;
  }
  .giveaways-page-promo-slide-time-left-weeks {
    font-size: 14px;
  }
  .giveaways-page-promo-slide-time-left-weeks span {
    font-size: 40px;
  }
  .giveaways-page-promo-slide-time-left-days {
    font-size: 14px;
  }
  .giveaways-page-promo-slide-time-left-days span {
    font-size: 40px;
  }
  .giveaways-page-promo-slide-time-left-time {
    font-size: 30px;
  }
}



@media @medium-widths {
  .giveaways-page-title {
    font-size: 22px;
  }
  .giveaways-page-promo-slide {
    flex-direction: column;
  }
  .giveaways-page-promo-slide-img {
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .giveaways-page-promo-slide-icons {
    flex-direction: row;
    margin-right: 0;
  }
  .giveaways-page-promo-slide-icon-steam {
    margin-bottom: 0;
    margin-right: 30px;
  }
  .giveaways-page-promo-slide-descr {
    width: 100%;
    margin-bottom: 20px;
  }
  .giveaways-page-promo-slide-descr-title {
    text-align: center;

  }
  .giveaways-page-promo-slide-time-left {
    margin-left: 0;
  }
  .giveaways-page-promo-slide-time-left {
    display: flex;
    align-items: center;
    margin: 0 auto;
  }
  .giveaways-page-promo-slide-time-left-inner-block {
    margin: 0 25px;
  }
  .giveaways-page-promo-slide-time-left-weeks {
    display: flex;
    align-items: center;
  }
  .giveaways-page-promo-slide-time-left-days {
    display: flex;
    align-items: center;
  }
}



@media @small-widths {
  .giveaways-page-promo {
    padding-right: 0;
  }
  .giveaways-page-promo-img {
    display: none;
  }
}



@media @extraSmall-widths {
  .giveaways-page-promo-slide {
    padding: 9px 45px;
  }
  .giveaways-page-promo-slide-descr-title {
    font-size: 26px;
  }
  .giveaways-page-promo-slide-descr-text {
    font-size: 13px;
  }
  .giveaways-page-promo-slide-time-left-time {
    font-size: 28px;
  }
  .giveaways-page-promo-slider .owl-nav .owl-prev {
    left: 0;
  }
  .giveaways-page-promo-slider .owl-nav .owl-next {
    right: 0;
  }
}



@media @exxSmall-widths {
  .giveaways-page-title {
    font-size: 20px;
  }
  .giveaways-page-promo-slide {
    padding: 9px 31px;
  }
  .giveaways-page-promo-slide-icon-steam {
    width: 30px;
  }
  .giveaways-page-promo-slide-icon-for-all {
    width: 26px;
  }
  .giveaways-page-promo-slide-descr-title {
    font-size: 22px;
  }
  .giveaways-page-promo-slide-descr-text {
    font-size: 12px;
  }
  .giveaways-page-promo-slide-time-left {
    flex-direction: column;
  }
  .giveaways-page-promo-slide-time-left-weeks {
    align-items: baseline;
  }
  .giveaways-page-promo-slide-time-left-days {
    align-items: baseline;
  }
}
