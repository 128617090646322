.page-header__nav-menu-wrapper {
  padding-left: 80px;
  padding-right: 25px;
}
.page-header--fixed .page-header__nav-menu-wrapper {
  flex-grow: 1;
}
.page-header__nav-menu-toggle {
  display: none;
}
.page-header__nav-menu {
  display: flex;
  height: 65px;
}
.main-nav {
  display: flex;
}
.page-header--fixed .main-nav {
  margin-left: auto;
}
.main-nav__toggle {
  display: none;
}
.main-nav__items {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}
.main-nav__items > li {
  display: flex;
  align-items: center;
  margin-left: 50px;
}
.main-nav__items li:first-child {
  margin-left: 0;
}
.main-nav__items > li > a {
  display: block;
  padding: 0 5px;
  font-size: 16px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  transition: color .5s;
}
.main-nav__items > li:hover > a {
  color: #dc291e;
}
.page-header__nav-menu-social {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: 10px;
}
.page-header__nav-menu-social-toggle {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: width .5s;
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform-origin: 0.5px 50%;
    width: 4px;
    height: 1px;
    background-color: #ffffff;
    transition: left .5s, transform .5s;
  }
  &::before {
    transform: translate(0, -50%) rotate(45deg);
  }
  &::after {
    transform: translate(0, -50%) rotate(-45deg);
  }
}
.page-header__nav-menu-social-toggle--opened {
  width: 0;
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 3px;
    top: 50%;
    transform-origin: 0.5px 50%;
    width: 4px;
    height: 1px;
    background-color: #ffffff;
  }
  &::before {
    transform: translate(0, -50%) rotate(135deg);
  }
  &::after {
    transform: translate(0, -50%) rotate(-135deg);
  }
}
.page-header__nav-menu-social-toggle img {
  display: block;
  width: 100%;
}
.page-header__nav-menu-social-items-wrapper {
  display: flex;
  width: 0;
  transition: width .5s;
  overflow: hidden;
}
.page-header__nav-menu-social-items {
  display: flex;
  align-items: center;
}
.page-header__nav-menu-social-item {
  position: relative;
  display: flex;
  align-items: center;
  width: 14px;
  height: 16px;
  margin-left: 17px;
}
.page-header__nav-menu-social-item-tw {
  width: 18px;
  height: 16px;
}
.page-header__nav-menu-social-item:first-child {
  margin-left: 0;
}
.page-header__nav-menu-social-item .img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: opacity .5s;
}
.page-header__nav-menu-social-item .img-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity .5s;
}
.page-header__nav-menu-social-item:hover .img {
  opacity: 0;
}
.page-header__nav-menu-social-item:hover .img-hover {
  opacity: 1;
}



@media @xxlarge-widths {
  .page-header__nav-menu-wrapper {
    padding-left: 50px;
  }
  .main-nav__items li {
    margin-left: 30px;
  }
  .main-nav__items > li > a {
    font-size: 15px;
  }
}



@media @xlarge-widths {
  .page-header__nav-menu-wrapper {
    padding-left: 20px;
  }
  .page-header--fixed .page-header__nav-menu-wrapper {
    padding-right: 10px;
  }
  .main-nav__items li {
    margin-left: 12px;
  }
  .main-nav__items > li > a {
    font-size: 14px;
  }
}



@media @large-widths {
  .main-nav__items li {
    margin-left: 10px;
  }
  .main-nav__items > li > a {
    font-size: 13px;
  }
  .page-header__nav-menu-social-item {
    margin-left: 12px;
  }
}



@media @medium-widths {
  .main-nav__items li {
    margin-left: 8px;
  }
  .main-nav__items > li > a {
    font-size: 12px;
  }
  .page-header--fixed .page-header__nav-menu-social-toggle--opened {
    width: 18px;
  }
  .page-header--fixed .page-header__nav-menu-social-items-wrapper {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    right: 0;
  }
  .page-header--fixed .page-header__nav-menu-social-items {
    width: auto;
  }
  .page-header--fixed .page-header__nav-menu-social-items {
    justify-content: center;
    padding: 5px 10px;
    background-color: #100f0f;
  }
}



@media @small-widths {
  .page-header__nav-menu-wrapper {
    padding-left: 5px;
    padding-right: 20px;
  }
  .page-header__nav-menu-toggle {
    position: relative;
    display: block;
    font-size: 0;
    width: 50px;
    height: 40px;
    cursor: pointer;
    z-index: 3000;
    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 10px;
      top: 19px;
      width: 30px;
      height: 2px;
      background-color: #ffffff;
      transition: transform .5s, box-shadow .5s;
    }
    &::before {
      transform: translate(0, -8px);
      box-shadow: 0 8px 0 0 #ffffff;
    }
    &::after {
      transform: translate(0, 8px);
    }
  }
  .page-header__nav-menu-toggle--opened {
    &::before {
      transform: rotate(45deg);
      box-shadow: none;
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
  .page-header__nav-menu {
    display: none;
    box-sizing: border-box;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    height: auto;
    padding: 15px;
    padding-top: 80px;
    background-color: rgba(16, 15, 15, .88);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .main-nav {
    display: block;
    margin-bottom: 15px;
  }
  .main-nav__items {
    flex-direction: column;
  }
  .main-nav__items > li {
    margin: 0 auto;
    margin-top: 5px;
  }
  .main-nav__items li:first-child {
    margin: 0 auto;
  }
  .main-nav__items > li > a {
    padding: 5px;
  }
  .page-header__nav-menu-social-items-wrapper {
    width: auto;
  }
  .page-header--fixed .page-header__nav-menu-social-items-wrapper {
    display: block;
    position: static;
  }
  .page-header__nav-menu-social {
    justify-content: center;
  }
  .page-header__nav-menu-social-toggle {
    display: none;
  }
  .page-header__nav-menu-social-item {
    margin-left: 20px;
  }
}
