.store-items-wrapper {
  padding: 0 15px;
  background-color: #2b2a2a;
}
.store-items {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  max-width: 1640px;
  margin: 0 auto;
  padding: 20px;
}
.store-item {
  box-sizing: border-box;
  width: ~"calc((100% - 90px) / 4)";
  margin-right: 30px;
  margin-bottom: 15px;
  border: 1px solid transparent;
  transform: scale(1, 1);
  cursor: pointer;
  transition: transform .5s, border .5s;
  &:hover {
    transform: scale(1.02, 1.02);
    border: 1px solid #ed1c24;
  }
}
@media (min-width: 1500px) {
  .store-item:nth-child(4n) {
    margin-right: 0;
  }
}
.store-item-header {
  display: flex;
  background-color: #100f0f;
  transition: background-color .5s;
}
.store-item:hover .store-item-header {
  background-color: #ed1c24;
}
.store-item-header-icon {
  flex-shrink: 0;
  width: 20px;
  margin: 9px 0;
  margin-left: 20px;
}
.store-item-header-icon img {
  display: block;
  width: 100%;
}
.store-item-header-name {
  align-self: center;
  padding: 12px 0;
  padding-left: 10px;
  padding-right: 20px;
  font-size: 12px;
  font-weight: normal;
  color: #ed1c24;
  text-transform: uppercase;
}
.store-item:hover .store-item-header-name {
  color: #f4f3f3;
}
.store-item-header-discount {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  padding: 5px 12px;
  background-color: #ed1c24;
  font-size: 19px;
  font-weight: bold;
  color: #f4f3f3;
}
.store-item-img-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 59%;
}
.store-item-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-size: cover;
}
.store-item-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, .7);
  transition: opacity .5s;
  opacity: 0;
}
.store-item:hover .store-item-hover {
  opacity: 1;
}
.store-item-hover-socials {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  padding-top: 15px;
}
.store-item-hover-socials-descr {
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.store-item-hover-socials-items {
  display: flex;
  align-items: center;
}
.store-item-hover-socials-item {
  position: relative;
  width: 14px;
  height: 16px;
  margin-left: 17px;
}
.store-item-hover-socials-item-tw {
  width: 18px;
}
.store-item-hover-socials-item .img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: opacity .5s;
}
.store-item-hover-socials-item .img-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity .5s;
}
.store-item-hover-socials-item:hover .img {
  opacity: 0;
}
.store-item-hover-socials-item:hover .img-hover {
  opacity: 1;
}
.store-item-hover-btn-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto 0;
  padding: 15px;
}
.store-item-hover-btn-more {
  .btn();
  margin-bottom: 10px;
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}
.store-item-hover-btn-wishlist {
  .btn();
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}
.store-item-hover-info {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  font-size: 10px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
}
.store-item-hover-release {
  margin-right: auto;
  padding-right: 10px;
}
.store-item-hover-watch {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.store-item-hover-watch img {
  margin-right: 5px;
}
.store-item-hover-like {
  display: flex;
  align-items: flex-end;
  line-height: 1;
  transition: color .5s;
  &:hover {
    color: #dc291e;
  }
}
.store-item-hover-like-icon {
  position: relative;
  width: 15px;
  height: 14px;
  margin-right: 5px;
}
.store-item-hover-like-icon img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  transition: opacity .5s;
}
.store-item-hover-like-icon .img {
  opacity: 1;
}
.store-item-hover-like-icon .img-hover {
  opacity: 0;
}
.store-item-hover-like:hover .img {
  opacity: 0;
}
.store-item-hover-like:hover .img-hover {
  opacity: 1;
}
.store-item-block {
  display: flex;
  flex-wrap: wrap;
  background-color: #100f0f;
}
.store-item-price {
  box-sizing: border-box;
  width: 50%;
  padding: 10px 15px;
  border-right: 1px solid #2c2b2b;
  border-bottom: 1px solid #2c2b2b;
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
}
.store-item-price-current {

}
.store-item-price-current span {
  font-size: 19px;
  font-weight: bold;
  color: #dc291e;
}
.store-item-price-prev {
  text-decoration: line-through;
}
.store-item-points {
  box-sizing: border-box;
  width: 50%;
  padding: 10px 15px;
  border-bottom: 1px solid #2c2b2b;
  font-size: 12px;
  font-weight: normal;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
}
.store-item-points span {
  font-size: 19px;
  font-weight: bold;
  color: #dc291e;
}
.store-item-systems {
  box-sizing: border-box;
  width: 50%;
  padding: 20px 15px;
  border-right: 1px solid #2c2b2b;
}
.store-item-systems-items {
  display: flex;
  align-items: center;
  justify-content: center;
}
.store-item-systems-item {
  margin-right: 12px;
}
.store-item-systems-item:last-child {
  margin-right: 0;
}
.store-item-systems-item img {
  display: block;
  width: 100%;
}
.store-item-btn-wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.store-item-btn {
  .btn();
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: ~"calc(100% - 20px)";
  min-width: 80%;
  height: 34px;
  margin: 0 auto;
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}
.store-item-btn-text {
  flex-shrink: 0;
}
.store-item-btn-icon {
  width: 35px;
  height: 35px;
}
.store-item-btn-icon svg {
  display: block;
  width: 100%;
  height: 100%;
  fill: #f4f3f3;
}



@media @xlarge-widths {
  .store-items {
    max-width: 1270px;
  }
  .store-item {
    width: ~"calc((100% - 60px) / 3)";
  }
}
@media (min-width: 992px) and (max-width: 1499px) {
  .store-item:nth-child(3n) {
    margin-right: 0;
  }
}



@media @large-widths {
  .store-item {
    width: ~"calc((100% - 40px) / 3)";
    margin-right: 20px;
  }
}



// @media @medium-widths {
//
// }



@media @small-widths {
  .store-items {
    max-width: 840px;
  }
  .store-item {
    width: ~"calc((100% - 20px) / 2)";
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .store-item:nth-child(2n) {
    margin-right: 0;
  }
}



@media @extraSmall-widths {
  .store-items {
    display: block;
    max-width: 390px;
  }
  .store-item {
    width: 100%;
    margin-right: 0;
  }
}



@media @exxSmall-widths {
  .store-items {
    padding-left: 0;
    padding-right: 0;
  }
}
