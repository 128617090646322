.article-single-block {
  box-sizing: border-box;
  width: ~"calc(100% - 420px)";
}
.article-single-block-inner-wrapper {
  margin-bottom: 25px;
  padding: 15px;
  background-color: #2b2a2a;
}
.article-single-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.article-single-header .articles-page-item-content-footer-info-date {
  margin-right: auto;
}
.article-single-header .product-nav-tabs-social {
  margin-bottom: 0;
}
.article-single-title {
  margin-bottom: 25px;
  font-size: 30px;
  font-weight: bold;
  color: #dc291e;
  text-transform: uppercase;
}
.article-single-img {
  margin-bottom: 15px;
}
.article-single-img img {
  display: block;
  width: 100%;
}
.article-single-content {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #f4f3f3;
  text-align: justify;
}
.article-single-content-img-small.float-left {
  float: left;
  width: 410px;
  margin: 20px 0;
  margin-right: 40px;
}
.article-single-content-img-small img {
  display: block;
  width: 100%;
}
.article-single-content-video-wrapper {
  box-sizing: border-box;
  width: 84%;
  margin: 30px auto;
}
.article-single-content-video {
  position: relative;
  width: 100%;
  padding-bottom: 56.2%;
}
.article-single-content-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.article-single-slider-wrapper {
  padding-top: 30px;
  padding-bottom: 50px;
}
.article-single-slider {
  box-sizing: border-box;
  width: 84%;
  margin: 0 auto;
}
.article-single-slide {

}
.article-single-slide img {
  display: block;
  width: 100%;
}
.article-single-slider .owl-nav .owl-prev {
  position: absolute;
  left: -40px;
  top: 50%;
  transform: translate(0, -50%);
  width: 31px;
  height: 30px;
  font-size: 0;
  cursor: pointer;
  z-index: 100;
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 12px;
    top: 14px;
    width: 14px;
    height: 2px;
    transform-origin: 1px 50%;
    background-color: #ffffff;
  }
  &::after {
    transform: rotate(50deg);
  }
  &::before {
    transform: rotate(-50deg);
  }
}
.article-single-slider .owl-nav .owl-next {
  position: absolute;
  right: -40px;
  top: 50%;
  transform: translate(0px, -50%);
  width: 31px;
  height: 30px;
  font-size: 0;
  cursor: pointer;
  z-index: 100;
  &::before,
  &::after {
    content: "";
    position: absolute;
    right: 12px;
    top: 14px;
    width: 14px;
    height: 2px;
    transform-origin: 13px 50%;
    background-color: #ffffff;
  }
  &::before {
    transform: rotate(50deg);
  }
  &::after {
    transform: rotate(-50deg);
  }
}
.article-single-slider .owl-dots {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 0;
}
.article-single-slider .owl-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 25px;
  background-color: #ffffff;
  border-radius: 50%;
}
.article-single-slider .owl-dot:last-child {
  margin-right: 0;
}
.article-single-slider .owl-dot.active {
  background-color: #dc291e;
}
.article-single-footer {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.article-single-footer .articles-page-item-content-footer-info-like {
  margin-right: auto;
}
.article-single-footer .product-nav-tabs-social {
  margin-bottom: 0;
}
.article-single-reviews {
  padding: 15px;
}



@media @xlarge-widths {
  .article-single-block {
    width: ~"calc(100% - 350px)";
  }
}



@media @large-widths {
  .article-single-block {
    width: ~"calc(100% - 300px)";
  }
  .article-single-content {
    font-size: 13px;
  }
}



@media @medium-widths {
  .article-single-content-img-small.float-left {
    width: 300px;
    margin: 15px 0;
    margin-right: 25px;
  }
}



@media @small-widths {
  .article-single-block {
    width: 100%;
  }
  .article-single-content {
    font-size: 12px;
  }
}



@media (max-width: 580px) {
  .article-single-content-img-small.float-left {
    float: none;
    max-width: 100%;
  }
}



@media @exxSmall-widths {
  .article-single-header .product-nav-tabs-social-item {
    margin-left: 12px;
  }
  .article-single-footer .product-nav-tabs-social-item {
    margin-left: 12px;
  }
  .article-single-slider {
    width: 100%;
  }
  .article-single-slider .owl-nav .owl-prev {
    display: none;
  }
  .article-single-slider .owl-nav .owl-next {
    display: none;
  }
}
