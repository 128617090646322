.terms-wrapper {
  position: relative;
  background-color: #2b2a2a;
  border-bottom: 5px solid #212121;
  z-index: 100;
}
.terms {

}
.terms .nav-tabs {
  display: flex;
  justify-content: center;
  border-bottom: none;
  transform: translate(0, -100%);
}
.terms .nav-tabs>li {
  box-sizing: border-box;
  width: 25%;
  max-width: 400px;
  margin-bottom: 0;
  margin-right: 7px;
  background-color: #2b2a2a;
  &:last-child {
    margin-right: 0;
  }
}
.terms .nav-tabs>li>a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  margin-right: 0;
  border: none;
  border-radius: 0;
  font-size: 18px;
  font-weight: normal;
  color: @color-text;
  text-transform: uppercase;
  text-align: center;
  transition: color .5s, background-color .5s;
}
.terms .nav>li>a:focus {
  background-color: #2b2a2a;
}
.nav>li>a:hover {
  background-color: #151414;
}
.terms .nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
  background-color: #151414;
  border: none;
  color: @color-text-hover;
  cursor: default;
}
.terms-blocks {
  padding-bottom: 50px;
}
.terms-block  {
  padding: 22px 28px;
  &:nth-child(2n-1) {
    background-color: #3a3a3a;
    border-bottom: 2px solid #686868;
  }
}
.terms-block-text {
  text-align: justify;

  p {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.terms-block-list {
  margin: 0;
  margin-top: 25px;
  padding: 0;
  list-style: none;
}
.terms-block-list li {
  position: relative;
  margin-bottom: 5px;
  padding-left: 24px;
  text-align: justify;
  &:last-child {
    margin-bottom: 0;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 4px;
    width: 8px;
    height: 8px;
    background-color: @color-text-hover;
    border-radius: 50%;
  }
}



@media @medium-widths {
  .terms .nav-tabs>li {
    width: ~"calc((100% - 14px) / 3)";
  }
  .terms .nav-tabs>li>a {
    font-size: 16px;
  }
}



@media @extraSmall-widths {
  .terms .nav-tabs>li>a {
    font-size: 14px;
  }
}



@media @exxSmall-widths {
  .terms .nav-tabs {
    flex-direction: column;
    transform: translate(0, 0);;
  }
  .terms .nav-tabs>li {
    width: 100%;
    margin-right: 0;
  }
  .terms .nav-tabs>li>a {
    height: 40px;
    font-size: 14px;
  }
  .terms .tab-content {
    padding-top: 30px;
  }
}
