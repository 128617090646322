.about-feedback-wrapper {
  padding: 30px 0;
  background-image: url("../img/about/about-feedback.jpg");
  background-position: center;
  background-size: cover;
}
.about-feedback {

}
.about-feedback-title {
  margin-bottom: 10px;
  font-family: "Stadium 1946", sans-serif;
  font-size: 35px;
  font-weight: normal;
  color: #dc291e;
  text-transform: uppercase;
  text-align: center;
}
.about-feedback-descr {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #f4f3f3;
  text-transform: uppercase;
  text-align: center;
}
.about-feedback-btn-wrapper {
  margin: 15px 0;
  text-align: center;
}
.about-feedback-btn {
  .btn();
  border: 2px solid #f4f3f3;
  &:hover {
    border: 2px solid #dc291e;
  }
}



@media @medium-widths {
  .about-feedback-title {
    font-size: 30px;
  }
  .about-feedback-descr {
    font-size: 15px;
  }
}



@media @small-widths {
  .about-feedback-title {
    font-size: 28px;
  }
  .about-feedback-descr {
    font-size: 14px;
  }
}



@media @exxSmall-widths {
  .about-feedback-title {
    font-size: 24px;
  }
  .about-feedback-descr {
    font-size: 13px;
  }
}
