.trading-cards-tab-pane {

}
.trading-cards-items {
  display: flex;
  flex-wrap: wrap;
}
.trading-cards-item {
  display: flex;
  flex-direction: column;
  width: ~"calc((100% - 18px) / 4)";
  margin-right: 6px;
  margin-bottom: 6px;
  padding: 30px 32px 35px 32px;
  background-color: #3a3a3a;
  border-bottom: 1px solid #757474;
}
@media (min-width: 1200px) {
  .trading-cards-item:nth-child(4n) {
    margin-right: 0;
  }
}
.trading-cards-item-name {
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: normal;
  color: #e4e4e4;
  text-transform: uppercase;
  text-align: center;
}
.trading-cards-item-img {
  margin-top: auto;
}
.trading-cards-item-img img {
  display: block;
  width: 100%;
}



// @media @xxlarge-widths {
//
// }



// @media @xlarge-widths {
//
// }



@media @large-widths {
  .trading-cards-item {
    padding: 20px;
  }
}



@media @medium-widths {
  .trading-cards-item {
    width: ~"calc((100% - 12px) / 3)";
  }
}
@media (min-width: 650px) and (max-width: 1199px) {
  .trading-cards-item:nth-child(3n) {
    margin-right: 0;
  }
}


@media @small-widths {
  .trading-cards-items {
    max-width: 700px;
    margin: 0 auto;
  }
  .trading-cards-item {
    padding: 15px;
  }
}



// @media @extraSmall-widths {
//
// }



@media (max-width: 649px) {
  .trading-cards-items {
    max-width: 460px;
  }
  .trading-cards-item {
    width: ~"calc((100% - 6px) / 2)";
  }
}
@media (min-width: 0px) and (max-width: 649px) {
  .trading-cards-item:nth-child(2n) {
    margin-right: 0;
  }
}



// @media @exxSmall-widths {
//
// }



@media (max-width: 459px) {
  .trading-cards-items {
    max-width: 240px;
  }
  .trading-cards-item {
    width: 100%;
    margin-right: 0;
  }
}
