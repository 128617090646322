.store-header-wrapper {
  background-image: url("../img/trove/trove-header-bg.jpg");
}
.trove-store-header-phrase {
  flex-shrink: 0;
  max-width: 215px;
  font-family: "Stadium 1946", sans-serif;
  font-size: 50px;
  font-weight: normal;
  line-height: .9;
  color: #dc291e;
  text-transform: uppercase;
  text-shadow: 4px 5px 0 #000000;
}
.trove-store-header-phrase span {
  display: flex;
  align-items: baseline;
  font-size: 60px;
}
.trove-store-header-phrase span img {
  flex-shrink: 0;
  width: 42px;
  margin-left: 7px;
}
.trove-store-header .store-header-info-small {
  font-size: 11px;
}



@media @extraSmall-widths {
  .trove-store-header-phrase {
    display: flex;
    align-items: baseline;
    justify-content: center;
    max-width: none;
    margin-bottom: 20px;
    font-size: 40px;
  }
  .trove-store-header-phrase span {
    margin-left: 10px;
    font-size: 48px;
  }
  .trove-store-header-phrase span img {
    width: 34px;
  }
}



@media @exxSmall-widths {
  .trove-store-header-phrase {
    flex-direction: column;
    align-items: center;
    font-size: 32px;
  }
  .trove-store-header-phrase span {
    margin-left: 0;
    font-size: 40px;
  }
}
